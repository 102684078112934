<template>
  <div>
    <section-definition :title="page.title"></section-definition>
    <v-container grid-list-md text-xs-center>
      <v-layout row>
        <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center"> </v-flex>
        <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
          <v-btn
            color="act"
            class="text--white bold btnfixed"
            round
            dark
            bold
            outline
            @click="gotoDetailEdit()"
            >Editar equipo</v-btn
          >
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-carousel :cycle="false" height="500" max="400">
            <v-carousel-item
              v-for="(item, i) in images"
              :key="i"
              :src="item.src"
              reverse-transition="fade"
              transition="fade"
              contain
            ></v-carousel-item>
          </v-carousel>

          <div style="margin:20px;">
            <span style="margin:20px; font-weight:bold;">Disponibilidad</span>
          </div>

          <v-flex xs12 sm6 class="my-3">
            <div class="subheading"></div>
            <v-date-picker
              v-model="date1"
              :events="blockeddays"
              event-color="red lighten-1"
              locale="es-es"
              :first-day-of-week="1"
              header-color="blue"
            ></v-date-picker>
          </v-flex>

          <v-flex sm2 xs6 class="text-sm-left text-xs-center"></v-flex>

          <v-flex sm6 md6 xs6 class="text-sm-right text-xs-right">
            <v-btn
              color="act"
              class="text--white bold btnfixed"
              round
              dark
              bold
              outline
              @click="gotoDetailEdit()"
              >Editar máquina</v-btn
            >
            <v-btn
              color="act"
              class="text--white bold btnfixed"
              round
              dark
              bold
              outline
              @click="gotoCreateAutoRent"
              v-if="$store.state.sessionData.idcompany !== 3"
              >Nuevo alquiler</v-btn
            >
            <v-btn
              color="act"
              class="text--white bold btnfixed"
              round
              dark
              bold
              outline
              @click="activateDeactivate"
              >{{ activateText }}</v-btn
            >
            <v-btn
              color="error"
              class="text--white bold btnfixed"
              round
              dark
              bold
              outline
              @click="isDeleteDialog = true"
              >Eliminar máquina</v-btn
            >
          </v-flex>
        </v-flex>

        <v-flex xs12 sm6>
          <v-card>
            <v-card-text>
              <div class="title">{{ macdata.machinename }}</div>
              <div class="title">{{ macdata.machinebrandname }}</div>
              <div class="subheading">{{ macdata.machinedescription }}</div>
              <v-divider></v-divider>
              <div class="text-sm-left text-xs-left">
                <div>
                  Precio:
                  <span class="font-weight-bold red--text"
                    >{{ macdata.machineprice }} €</span
                  >
                </div>
                <div>
                  <span class="font-italic font-weight-thin"
                    >Precio para alquileres de más de 2 meses:</span
                  >
                  <span class="font-weight-bold red--text"
                    >{{ macdata.machineminprice }} €</span
                  >
                </div>
                <div>
                  Fianza:
                  <span class="font-weight-bold red--text"
                    >{{ macdata.machinepriceexcess }} €</span
                  >
                </div>
                <div>
                  Potencia:
                  <span class="font-weight-bold red--text"
                    >{{ macdata.machinepower }} CV</span
                  >
                </div>
                <div>
                  Propietario:
                  <span class="font-italic font-weight-thin">{{
                    macdata.machineowneralias
                  }}</span>
                </div>
                <div>
                  Localización:
                  <span class="font-italic font-weight-thin">{{
                    macdata.machineplace
                  }}</span>
                </div>
                <!-- <div>Distancia: {{macdata.machinedistance}} Km</div> -->

                <div>
                  Año de fabricación:
                  <span class="font-italic font-weight-thin">{{
                    macdata.machineyear
                  }}</span>
                </div>
                <div>
                  Horas de uso:
                  <span class="font-italic font-weight-thin">{{
                    macdata.machinetacometer
                  }}</span>
                </div>
                <!-- <li v-for="(ingredient, i) in item.recipe.ingredientLines" :key="i">{{ingredient}}</li> -->
              </div>
              <v-checkbox
                class="text-sm-left text-xs-left custom-checkbox"
                label="Transporte disponible"
                :value="true"
                readonly="true"
                :input-value="hasTransport"
              ></v-checkbox>
              <v-checkbox
                class="text-sm-left text-xs-left custom-checkbox"
                label="Seguro disponible"
                :value="true"
                readonly="true"
                :input-value="hasInsurance"
              ></v-checkbox>
            </v-card-text>
            <v-card-title></v-card-title>
          </v-card>
          <v-card>
            <div class="custom-checkbox" v-for="(itemx, index) in attsType4">
              <v-checkbox
                class="text-sm-left text-xs-left custom-checkbox"
                style="font-size:12;"
                :label="itemx.labelx"
                :value="true"
                readonly="true"
                :input-value="itemx.valuebool"
              ></v-checkbox>
            </div>
            <div class="custom-machineatt"></div>
            <div
              class="text-sm-left text-xs-left custom-machineatt"
              v-for="(itemx, index) in attsType1"
              :key="index"
            >
              <span class="text-sm-left text-xs-left custom-check"
                >{{ itemx.label }} : {{ itemx.value }}</span
              >
            </div>

            <div
              class="text-sm-left text-xs-left custom-machineatt"
              v-for="(itemx, index) in attsType3"
              :key="index"
            >
              <span class="text-sm-left text-xs-left custom-check"
                >{{ itemx.label }} : {{ itemx.value }}</span
              >
            </div>
          </v-card>

          <google-map />
        </v-flex>
      </v-layout>
      <v-flex xs12 md12>
        <v-divider inset xs12 md12></v-divider>
      </v-flex>
      <v-flex xs12 md12>
        <v-label class="display-3">Alquileres</v-label>
      </v-flex>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Buscar.."
        single-line
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="rents"
        class="elevation-1"
        :search="search"
        :loading="isLoading"
        rows-per-page-text="Filas por página"
        :rows-per-page-items="[5, 25, 50, { text: 'todas', value: -1 }]"
      >
        <template slot="no-data">
          <v-alert :value="true" color="error" icon="warning">
            No hay datos
          </v-alert>
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.useralias }}</td>
          <td>{{ props.item.idrent }}</td>
          <td class="text-xs-right">{{ props.item.rentprice }}</td>
          <td class="text-xs-right">{{ props.item.rentfinalprice }}</td>
          <td class="text-xs-right">{{ props.item.rentpriceexcess }}</td>
          <td :class="props.item.stateclass">
            <template v-slot:props.item.rentstatuslabel="{ item }">
              <v-chip :color="getColor(props.item.rentstatus)" dark>{{
                props.item.rentstatuslabel
              }}</v-chip>
            </template>
          </td>
          <td :class="props.item.stateclass">
            {{ props.item.rentphaselabel }}
          </td>
          <td class="text-xs-right">{{ props.item.rentplace }}</td>
          <td class="text-xs-right">{{ props.item.transportNeededlabel }}</td>
          <td class="text-xs-right">{{ props.item.transportPrice }}</td>
          <td class="text-xs-right">{{ props.item.rentfrom }}</td>
          <td class="text-xs-right">{{ props.item.rentto }}</td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="isDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Eliminar máquina</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <span class="">La máquina se eliminará de forma permanente </span>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click.native="isDeleteDialog = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" @click.native="deleteMachine"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GoogleMap from "../../components/Maps/GoogleMap";
import { EventBus } from "../../eventBus";

//EventBus.$on('newAddress', clickCount => {
//  console.log(`Oh, that's nice. It's gotten ${clickCount} clicks! :)`)
//});

export default {
  name: "MachineDetail",
  props: ["id"],

  data() {
    return {
      images: [],
      uuid: "",
      macdata: {},
      machinePhotos: [],
      isLoading2: false,
      isDeleteDialog: false,
      hasTransport: false,
      hasInsurance: false,
      attsType1: [],
      attsType2: [],
      attsType3: [],
      attsType4: [],
      search: this.$store.state.machineDetailListSearch,
      caltype: "month",
      calstart: "2019-07-12",
      calend: "2020-01-06",
      blockeddays: ["2019-07-16", "2019-07-17"],
      currentMonth: 8,
      rents: [],
      activateText: "",
      date: null,
      headers: [
        { text: "Arrendatario", value: "useralias" },
        { text: "ID alquiler", value: "idrent" },
        { text: "Precio (€)", value: "rentprice" },
        { text: "Precio Final (€)", value: "rentfinalprice" },
        { text: "Fianza (€)", value: "rentpriceexcess" },
        { text: "Estado", value: "rentstatuslabel" },
        { text: "Fase", value: "rentphase" },
        { text: "Lugar de entrega", value: "rentplace" },
        { text: "Transporte?", value: "transportNeeded" },
        { text: "Precio transporte (€)", value: "transportPrice" },
        { text: "Fecha inicio ", value: "rentfrom" },
        { text: "Fecha fin ", value: "rentto" }
      ],
      page: {
        title: "Detalle de Equipo",
        headline: "Lista de alquileres",
        description: "Alquileres no cancelados"
      }
    };
  },
  components: {
    GoogleMap
  },
  created: function() {
    this.$on("newAddress", section => {
      console.log(section);
    });
  },
  watch: {
    search() {
      this.$store.commit("setMachineDetailListSearch", this.search);
    }
  },
  mounted: function() {
    //this.getop(this.$route.query.id);
    this.uuid = this.$route.query.id;
    this.getRents(this.$route.query.id);
    this.getop(this.$route.query.id);
  },
  methods: {
    onNewAddress(someValue) {
      console.log(someValue);
    },
    activateDeactivate() {
      var action = "enablemachine";
      if (this.macdata.machinestatusid == 1) {
        action = "disablemachine";
      }

      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI +
        "/" +
        action +
        "/" +
        this.macdata.machineuuid;

      self.$http.get(url).then(
        function() {
          self.getop(self.macdata.machineuuid);
        },
        function() {
          self.snacktext = "Error obteniendo cuentas del usuario";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    },
    deleteMachine() {
      var action = "enablemachine";
      if (this.macdata.machinestatusid == 1) {
        action = "disablemachine";
      }

      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI +
        "/deletemachine/" +
        this.macdata.machineuuid;

      self.$http.get(url).then(
        function() {
          self.$router.push("/pages/MachinesList");
        },
        function() {
          self.snacktext = "Error al eeliminar la máquina  ";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    },

    gotoDetailEdit: function() {
      this.$router.push({
        path: "/pages/machinedetailedittabbbed/:id",
        query: { id: this.uuid }
      });
    },
    gotoCreateAutoRent: function() {
      this.$router.push({
        name: "pages/CreateAutoRent",
        params: {
          currentmachine: this.macdata,
          currentuserid: this.macdata.iduser
        }
      });
    },
    gotoCreateAutoRent2: function() {
      this.$router.push({
        name: "pages/CreateAutoRent2",
        params: {
          currentmachine: this.macdata,
          currentuserid: this.macdata.iduser
        }
      });
    },
    getop: function(opuuid) {
      const _this = this;
      this.isLoading2 = true;
      const url = process.env.VUE_APP_BASE_URI + "/getmachinebyuuid/" + opuuid;
      _this.$http
        .get(url)
        .then(
          function(response) {
            _this.isLoading2 = false;
            _this.macdata = response.data.machinedata;
            if (_this.macdata.machinestatusid == 1) {
              _this.activateText = "Desactivar máquina";
            } else {
              _this.activateText = "Activar máquina";
            }
            if (_this.macdata.machineinsuranceavailable == 1)
              _this.hasInsurance = true;
            if (_this.macdata.machinetransportavailable == 1)
              _this.hasTransport = true;
            _this.macdata.machineattributes.forEach(element => {
              if (element.attributetype == 1) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                if (element.attributename == "Potencia") var aa = 0;
                else _this.attsType1.push(attnew);
              } else if (element.attributetype == 3) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                _this.attsType3.push(attnew);
              } else if (element.attributetype == 4) {
                var attnew = {};
                attnew.labelx = element.attributename;
                if (element.attributevalueyesno == 0) attnew.valuebool = false;
                else attnew.valuebool = true;
                _this.attsType4.push(attnew);
              }
            });
            if (_this.macdata.machinelocation != null) {
              EventBus.$emit("setMacAddress", _this.macdata.machinelocation);
            }

            _this.$http
              .get(
                process.env.VUE_APP_BASE_URI +
                  "/getphotosformachine/" +
                  response.data.machinedata.machineuuid
              )
              .then(
                function(response) {
                  this.machinePhotos = response.data;
                  for (var a = 0; a < this.machinePhotos.length; a++) {
                    var newimg = {};
                    newimg.src =
                      process.env.VUE_APP_BASE_URI +
                      "/getimage/" +
                      this.machinePhotos[a].imageuuid;
                    this.images.push(newimg);
                  }
                }.bind(this)
              )
              .catch(
                function(error) {
                  console.log(error);
                }.bind(this)
              );
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            this.isLoading2 = false;
          }.bind(this)
        );

      const url2 =
        process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + opuuid;
      _this.$http
        .get(url2)
        .then(
          function(response) {
            console.log("" + response);
            this.blockeddays = response.data.blockeddays;
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            // this.isLoading2 = false;
          }.bind(this)
        );
    },
    getRents(opuuid) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getrentsformachine/" + opuuid;
      self.isLoading = true;
      self.$http.get(url).then(
        function(success) {
          var paymentstemp = success.data.rents;
          var rentsfinal = [];
          self.isLoading = false;

          self._.forEach(paymentstemp, function(expense, index) {
            if (expense.rentstatus < 10) {
              paymentstemp[index].rentfrom = self
                .moment(expense.rentfrom)
                .format("DD-MM-YYYY")
                .split("T")[0];
              paymentstemp[index].rentto = self
                .moment(expense.rentto)
                .format("DD-MM-YYYY")
                .split("T")[0];
              if (expense.rentstatus === 1) {
                paymentstemp[index].rentstatuslabel = "Solicitado";
              }
              if (expense.rentstatus === 2) {
                paymentstemp[index].rentstatuslabel = "Aprobado";
              }
              if (expense.rentstatus >= 3 && expense.rentstatus < 6) {
                paymentstemp[index].rentstatuslabel = "Firmado";
              }
              if (expense.rentstatus === 6) {
                paymentstemp[index].rentstatuslabel = "Entrega";
              }
              if (expense.rentstatus === 7) {
                paymentstemp[index].rentstatuslabel = "Recogida";
              }
              if (expense.rentstatus === 8) {
                paymentstemp[index].rentstatuslabel = "Liquidación";
              }
              if (expense.rentstatus === 9) {
                paymentstemp[index].rentstatuslabel = "Finalizado";
              }

              if (expense.transportNeeded === 1) {
                paymentstemp[index].transportNeededlabel = "SI";
              } else {
                paymentstemp[index].transportNeededlabel = "NO";
              }

              if (expense.rentstatus >= 6 && expense.rentstatus < 9) {
                if (expense.rentphase === 1) {
                  paymentstemp[index].rentphaselabel = "Salida";
                }
                if (expense.rentphase === 2) {
                  paymentstemp[index].rentphaselabel = "Entrega";
                }
                if (expense.rentphase === 3) {
                  paymentstemp[index].rentphaselabel = "Recogida";
                }
                if (expense.rentphase === 4) {
                  paymentstemp[index].rentphaselabel = "Devolución";
                }
              } else {
                paymentstemp[index].rentphaselabel = " ";
              }

              if (expense.idpaymentstatus === 1) {
                paymentstemp[index].stateclass = "state1";
                paymentstemp[index].idpaymentstatus = "PENDIENTE";
              }
              if (expense.idpaymentstatus === 2) {
                paymentstemp[index].stateclass = "state2";
                paymentstemp[index].idpaymentstatus = "PAGADO";
              }
              if (expense.idpaymentstatus === 3) {
                paymentstemp[index].stateclass = "state3";
                paymentstemp[index].idpaymentstatus = "RECHAZADO";
              }
              rentsfinal.push(paymentstemp[index]);
            }
          });
          self.rents = rentsfinal;
        },
        function() {
          self.isLoading = false;

          self.snacktext = "Error al obtener lista de usuarios";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    getColor(status) {
      if (status > 7) return "red";
      else if (status > 2) return "orange";
      else return "green";
    }
  }
};
</script>
<style lang="stylus">
#example-custom-transition {
  .fade {
    &-enter-active, &-leave-active, &-leave-to {
      transition: 0.3s ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }
}

.custom-checkbox {
  height: 20px;
  font-size: 12;
  margin-left: 5px;
}

.custom-machineatt {
  height: 30px;
  font-size: 12;
  margin-left: 15px;
}

.v-label {
  font-size: 14px;
  font-color: black;
}
</style>

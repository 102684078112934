<template>
  <div>
    <section-definition :title="page.title"></section-definition>
    <v-layout child-flex>
      <v-card>
        <v-card-title>
          <v-flex xs6 md3>
            <download-excel
              :data="machines"
              :fields="json_fields"
              name="Máquinas.xls"
              worksheet="Maquinas"
            >
              <v-btn
                color="act"
                class="text--white green bold btnfixed"
                round
                dark
                bold
                outline
                >Descargar Excel</v-btn
              >
            </download-excel>
          </v-flex>
          <v-flex xs6 md3>
            <download-excel
              :data="machines"
              :fields="json_fields"
              name="Máquinas.csv"
              type="csv"
              worksheet="Máquinas"
            >
              <v-btn
                color="act"
                class="text--white green bold btnfixed"
                round
                dark
                bold
                outline
                >Descargar CSV</v-btn
              >
            </download-excel>
          </v-flex>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar.."
            single-line
            padding="1"
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="machines"
          :search="search"
          :loading="isLoading"
          rows-per-page-text="Filas por página"
          :rows-per-page-items="[15, 30, 50, { text: 'todas', value: -1 }]"
          class="elevation-1 fixed-header v-table__overflow"
          style="max-height: calc(60vh - 10px);backface-visibility: hidden;"
          dense
        >
          <v-progress-linear
            slot="progress"
            color="blue"
            indeterminate
          ></v-progress-linear>

          <template slot="items" slot-scope="props">
            <td>
              <img
                height="80"
                class="justify-center"
                :src="props.item.imgurl"
              />
            </td>
            <td>{{ props.item.machinename }}</td>
            <td>{{ props.item.machinebrandname }}</td>
            <td class="text-xs-center">
              {{ props.item.creationdate | utcToFormatDate }}
            </td>
            <td class="text-xs-center">{{ props.item.machinedescription }}</td>
            <td :class="props.item.stateclass">
              {{ props.item.machineprice }}
            </td>
            <td class="text-xs-center">{{ props.item.machinepower }}</td>
            <td class="text-xs-center">{{ props.item.machinelocation }}</td>
            <td class="text-xs-center">{{ props.item.machineplace }}</td>
            <td class="text-xs-center">{{ props.item.machinetypelabel }}</td>
            <td :class="props.item.machinestatus">
              <template v-slot:props.item.rentstatuslabel="{ item }">
                <v-chip :color="props.item.machinestatuscolor" dark>{{
                  props.item.machinestatuslabel
                }}</v-chip>
              </template>
            </td>
            <td>
              <v-icon
                class="mr-2"
                @click="
                  gotoDetail(props.item.machineuuid, props.item.machinetype)
                "
              >
                visibility
              </v-icon>
            </td>
          </template>
          <template slot="pageText" slot-scope="props">
            Filas {{ props.pageStart }} - {{ props.pageStop }} de
            {{ props.itemsLength }}
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            La búsqueda de "{{ search }}" no ha encontrado resultados.
          </v-alert>
        </v-data-table>
      </v-card>
    </v-layout>
    <!--
    <v-container grid-list-lg>
        <v-layout row wrap>
            <v-flex xs12 sm6 md6 lg4 v-for="(item, idx) in machines" :key="idx">
                <v-card hover=true height="450" @click="gotoDetail(item.machineuuid)">
                    <v-responsive  >
                        <v-layout class="justify-center">
                        <img  height="150" class="justify-center"  :src="item.imgurl" />
                        </v-layout>
                    </v-responsive>
                    <v-card-text>
                        <div class="subheading">{{item.machinename}}</div>
                        <div class="subheading">{{item.machinebrandname}}</div>
                        <div class="subheading"  >{{item.machinedescription}}</div>
                        <ul>
                            <li>Distancia: {{item.distance}} Km</li>
                            <li>Precio: <span class=" font-weight-bold blue--text"> {{item.machineprice}} € </span></li>
                            <li>Propietario: <span class="font-italic  font-weight-thin">{{item.machineowneralias}} </span> </li>
                        </ul>
                    <div class="text-xs-center">
                        <v-rating v-model="item.machinerate" dense  readonly ></v-rating>
                    </div>                    
                </v-card-text>
                                    
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
-->
    <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top="true"
    >
      {{ snacktext }}
      <v-btn dark flat @click="snackresult = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "MachinesList",
  data() {
    return {
      machines: null,
      search: this.$store.state.machineListSearch,
      headers: [
        { text: "", value: "", sortable: false, width: "10%" },
        { text: "Máquina", value: "machinename", width: "10%" },
        { text: "Marca", value: "machinebrandname", width: "10%" },
        { text: "Fecha de creación", value: "creationdate", width: "10%" },
        { text: "Descripción ", value: "machinedescription", width: "30%" },
        { text: "Precio", value: "machineprice", width: "5%" },
        { text: "Potencia", value: "machinepower", width: "5%" },
        { text: "Posición", value: "machinelocation", width: "5%" },
        { text: "Lugar", value: "machineplace", width: "10%" },
        { text: "Tipo", value: "machinetypelabel", width: "5%" },
        { text: "Estado", value: "machinestatus", width: "5%" },
        { text: "Acciones ", value: "", sortable: false, width: "5%" }
      ],
      currentMachine: {},
      snackbar: false,
      snackresult: false,
      snacktext: "",
      snackcolor: "success",
      isLoading: true,
      page: {
        title: "Máquinas",
        headline: "Lista de máquinas",
        description: "Máquinas activas"
      },
      breadcrumbs: [
        {
          text: "Paginas",
          disabled: false
        },
        {
          text: "Equipos",
          disabled: false
        }
      ],
      json_fields: {
        "Id. Máquina": "machineid",
        Máquina: "machinename",
        Marca: "machinebrandname",
        Módelo: "machinemodel",
        "Fecha de creación": {
          field: "creationdate",
          callback: value => {
            var stillUtc = moment.utc(value).toDate();
            return moment(stillUtc)
              .local()
              .format("DD-MM-YYYY HH:mm:ss");
          }
        },
        Descripción: "machinedescription",
        "Precio(€)": {
          field: "machineprice",
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0) {
              var newvalue = valstr.replace(".", ",");
              return newvalue;
            }
            return value;
          }
        },
        "Potencia(W)": "machinepower",
        Lugar: "machineplace",
        Tipo: "machinetypelabel",
        Estado: "machinestatuslabel"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  computed: {
    machines() {
      for (var a = 0; a < this.$store.state.machines.machines.length; a++) {
        this.$store.state.machines.machines[a].imgurl =
          "https://ploou-produccion.appspot.com/getimage/" +
          this.$store.state.machines.machines[a].imageuuid;
        if (this.$store.state.machines.machines[a].imageuuid === "") {
          this.$store.state.machines.machines[a].imgurl =
            "https://ploou-produccion.appspot.com/getimage/86964f76-fb15-4cc2-a57e-d8438eda5a22";
        }
        this.$store.state.machines.machines[a].machinerate = 5;
      }
      return this.$store.state.machines.machines;
    }
  },
  watch: {
    search() {
      this.$store.commit("setMachineListSearch", this.search);
    }
  },
  mounted: function() {
    this.loadAll();
    console.log("mounted: got here");
  },
  methods: {
    async loadAll() {
      await this.gettypes();
      await this.getbrands();
      await this.getmachines(1);
    },
    gotoDetail: function(uuid, id) {
      this.$store.commit("setMachineType", id);
      this.$router.push({
        path: "/pages/machinedetail/:id",
        query: { id: uuid }
      });

      // this.$router.push({ path: '/machinedetail'})
    },
    editItem: function(uuid) {
      this.$router.push({
        path: "/pages/machinedetailedit/:id",
        query: { id: uuid }
      });
    },
    async gettypes() {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getmachinetypes";
      self.$http.get(url).then(
        function(success) {
          self.machinetypes = success.data.types;
        },
        function() {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de tipos";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    async getbrands() {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getmachinebrandslist";
      self.$http.get(url).then(
        function(success) {
          self.brands = success.data.machinebrands;
        },
        function() {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de tipos";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    async getmachines(typemachine) {
      const self = this;
      //  let url = process.env.VUE_APP_BASE_URI + '/filtermachinesbyspecs'
      let uuid = this.$store.getters.uuid;
      let url = process.env.VUE_APP_BASE_URI + "/getmachinesforuser/" + uuid;
      var filtersx = {
        filters: {
          machinetype: typemachine
        }
      };
      this.isLoading = true;
      //   self.$http.post(url, filtersx).then(
      self.$http.get(url).then(
        function(success) {
          self.isLoading = false;
          self.machines = success.data.machines;
          for (var a = 0; a < self.machines.length; a++) {
            self.machines[a].imgurl =
              process.env.VUE_APP_BASE_URI +
              "/getimage/" +
              self.machines[a].imageuuid;
            if (self.machines[a].imageuuid === "") {
              self.machines[a].imgurl =
                process.env.VUE_APP_BASE_URI +
                "/getimage/86964f76-fb15-4cc2-a57e-d8438eda5a22";
            }
            var found = self.machinetypes.find(
              element => element.typeid == self.machines[a].machinetype
            );
            self.machines[a].machinetypelabel = found.typename;
            var found2 = self.brands.find(
              element => element.machinebrandid == self.machines[a].machinebrand
            );
            self.machines[a].machinebrandname = found2.machinebrandname;
            if (self.machines[a].machinelocation != null)
              self.machines[a].machinelocation = self.machines[
                a
              ].machinelocation.replace(",", ", ");
            self.machines[a].machinerate = 5;
            if (self.machines[a].machinestatus == 1) {
              self.machines[a].machinestatuslabel = "Activo";
              self.machines[a].machinestatuscolor = "green";
            } else {
              self.machines[a].machinestatuslabel = "NO Activo";
              self.machines[a].machinestatuscolor = "red";
            }
          }
        },
        function() {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de equipos";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    }
  }
};
</script>
<style lang="stylus">

@import '~vuetify-stylus-fixed-table-header';

$heights = {};

$heights['only screen'] = 50vh;
$heights['only screen and (max-width: 959px)'] = 70vh;


.fixed-header {
fixed-table-header($heights)

}

table.v-table thead th {
    font-size: 14px !important;
    color: white !important;
    background-color: darkgrey !important;
}

.v-content {
  padding: 0px;
}
</style>

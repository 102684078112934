<template>
  <div>
    <div xs12 md12 style="background-color: #e0e0e0" class="mt-3">
      <label style="">
        <gmap-autocomplete xs12 md12 sytle="width: 300px !important; text-align: center"
          @place_changed="setPlace">
        </gmap-autocomplete>

      </label>
      <br/>
        <v-btn xs12 md12 lg12 sm12 @click="addMarker" color="primary" round  dark bold outline  class="btnfixed" >  Buscar dirección</v-btn>

    </div>
    <br>
    <gmap-map
      :center="center"
      :zoom="12"
      style="width:100%;  height: 400px;" 
      mapTypeId= "terrain"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
'use strict'
import {EventBus} from '../../eventBus';

export const initLocation = ""



export default {
  name: "GoogleMap",
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 42.508, lng: -3.587 },
      markers: [],
      places: [],
      currentPlace: null,
      currentCoords: null
    };
  },
  
  mounted() {
    this.geolocate();
    EventBus.$on('setMacAddress', latLngStr => {
        var coordinates = latLngStr.split(',');
        var myLatlng = new google.maps.LatLng(parseFloat(coordinates[0]), parseFloat(coordinates[1]));        
        var marker = {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1])
        
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        if(  coordinates[0]=="0"){
          this.geolocate()
        }
        
    });

  },

  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        var locatStr = "" + this.currentPlace.geometry.location.lat()+","+this.currentPlace.geometry.location.lng();
        EventBus.$emit('newAddress', this.currentPlace.formatted_address, locatStr);
        EventBus.$emit('newLocation',  locatStr);

        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        if(this.markers.length >0 ) {
        //  alert( "Ya hay una direccion")
        }
        else{
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        }

      });
    }
  }
};
</script>
/*eslint-disable*/
<template>
  <div>
    <section-definition
      :title="pagetitle"
      :breadcrumbs="breadcrumbs"
    ></section-definition>

    <v-container fluid grid-list-xl page>
      <v-layout row wrap>
        <v-flex xs6 md3>
          <vuse-card-mini-widget
            title="Equipos"
            subheader="<strong>EQUIPOS</strong>"
            :headline="numequipos"
            height="100px"
            background="gray"
            icon="directions_bus"
            size="50px"
            iconColor="blue"
          >
          </vuse-card-mini-widget>
        </v-flex>

        <v-flex xs6 md3>
          <vuse-card-mini-widget
            subheader="<strong>ALQUILERES</strong>"
            :headline="numalquileres"
            height="100px"
            background="yellow"
            icon="vpn_key"
            size="50px"
            iconColor="red"
          >
          </vuse-card-mini-widget>
        </v-flex>

        <v-flex xs6 md3>
          <vuse-card-mini-widget
            subheader="<strong>VALORACIÓN</strong>"
            :headline="userData.rate"
            height="100px"
            background="yellow"
            icon="rate_review"
            size="50px"
            iconColor="green"
          >
          </vuse-card-mini-widget>
        </v-flex>
      </v-layout>

      <v-flex lg10 md10 sm10 xs12>
        <plain-table-order></plain-table-order>
        <v-flex xs12 md12>
          <v-divider role="presentation" xs12 md12></v-divider>
        </v-flex>

        <plain-table-machines></plain-table-machines>
      </v-flex>
    </v-container>

    <v-dialog v-model="isActivateUser" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Activar Usuario</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <span
                  >Es necesario activar el usuario. Para activar al usuario se
                  necesita el código enviado a su teléfono móvil</span
                >
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="activCode"
                  label="Código"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click.native="isActivateUser = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" @click.native="activateUser"
            >Activar</v-btn
          >
          <v-btn color="blue darken-1" @click.native="resendCode"
            >Reenviar código</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top="true"
    >
      {{ snacktext }}
      <v-btn dark flat @click="snackresult = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
/*
amountpayed: 900
idpaymentstatus: 2
idrent: 488
machinename: "Ter"
owneralias: "Marta12"
paymentamount: 900
paymentdata: "959999/018687897529827156149505050702"
paymentdate: "Mon, 05 Aug 2019 00:00:00 GMT"
paymenttype: 1
rentfrom: "Tue, 20 Aug 2019 00:00:00 GMT"
rentto: "Wed, 28 Aug 2019 00:00:00 GMT"
transactiondate: "Fri, 19 Jul 2019 11:48:26 GMT"
useralias: "Marta12"
*/
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import PlainTableOrder from "@/components/Widgets/list/ListTableRentsPremium";
import PlainTableMachines from "@/components/Widgets/list/ListTableMachinesPremium";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
import { EventBus } from "../../eventBus";

const PaymentsRepository = RepositoryFactory.get("payments");

export default {
  mixins: [validationMixin],

  data() {
    return {
      search: "",
      numequipos: 0,
      numalquileres: 0,
      headers: [
        { text: "Usuario", value: "useralias" },
        { text: "ID", value: "iduser" },
        { text: "NIF", value: "useridentitydocument" },
        { text: "Nombre", value: "username" },
        { text: "Apellido 1 ", value: "usersurname1" },
        { text: "Apellido 2", value: "usersurname2" },
        { text: "Valoracion", value: "userrateasowner" },
        { text: "Estado", value: "userstatus" },
        { text: "SEPA", value: "sepaallowed" },
        { text: "Movil", value: "userphone" },
        { text: "Fecha creación ", value: "usercreationdate" },
        { text: "Acciones ", value: "" }
      ],
      userslist: [],
      machines: [],
      rents: [],
      userData: {},
      pagetitle: "",
      currentUser: null,
      isLoading: false,
      isActivateUser: false,
      activCode: "",
      snackbar: false,
      page: {
        title: "Concesiones",
        headline: "Concesión "
      },
      breadcrumbs: [
        {
          text: "Detalle",
          disabled: false
        }
      ],
      snackresult: false,
      snacktext: "",
      snackcolor: "success"
    };
  },
  components: {
    PlainTableOrder,
    PlainTableMachines
  },
  mounted: function() {
    let uuid = this.$store.getters.uuid;

    //    this.loadData();
    this.getrents(uuid);
    this.getmachines(uuid);
    this.getUserData(this.$store.getters.userId);
  },
  methods: {
    getUserData(currentid) {
      const self = this;
      const uuid = self.currentuseruuid;
      let url = process.env.VUE_APP_BASE_URI + "/getuserbyid"; //+ uuid; // self.$store.getters.uuid
      const dataid = {
        iduser: currentid
      };
      self.$http.post(url, dataid).then(
        function(success) {
          let data = {};
          data.username = success.data.userdata.name;
          data.usersurname1 = success.data.userdata.surname1;
          data.usersurname2 = success.data.userdata.surname2;
          data.userphone = success.data.userdata.phone;
          data.useremail = success.data.userdata.email;
          data.useralias = success.data.userdata.alias;
          data.useridentitydocument = success.data.userdata.identitydocument;
          data.useraddress1 = success.data.userdata.address1;
          data.useraddress2 = success.data.userdata.address2;
          data.useraddressnumber = success.data.userdata.addressnumber;
          data.useraddressprovince = success.data.userdata.province;
          data.usercountry = success.data.userdata.country;
          data.rate = success.data.userdata.rate;
          data.type = success.data.userdata.type;
          if (data.type === 1) {
            data.typelabel = "Normal";
          } else if (data.type === 2) {
            data.typelabel = "Premium ";
          } else if (data.type === 3) {
            data.typelabel = "Transportista";
          } else if (data.type === 5) {
            data.typelabel = "Administrador";
          } else {
            data.typelabel = "---";
          }
          data.useruuid = uuid;
          self.pagetitle = "Concesión " + data.useralias;

          data.useruuid = self.currentuseruuid;
          data.iduser = self.currentuserid;
          self.currentUserType = data.type;

          self.userData = data;
          self.$store.commit("setUseralias", data.useralias);
        },
        function(error) {
          self.snacktext = "Error obteniendo datos del usuario  ";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    },

    loadData() {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getpremiumlist";
      self.isLoading = true;
      self.$http.get(url).then(
        function(success) {
          var userlisttemp = success.data.users;
          self._.forEach(userlisttemp, function(currentitem, index) {
            userlisttemp[index].usercreationdate = self
              .moment(currentitem.usercreationdate)
              .format("DD-MM-YYYY")
              .split("T")[0];

            if (currentitem.userstatus === 1) {
              userlisttemp[index].userstatuslabel = "En registro";
            } else if (currentitem.userstatus === 2) {
              userlisttemp[index].userstatuslabel = "Activo ";
            } else {
              userlisttemp[index].userstatuslabel = "---";
            }

            if (currentitem.sepaallowed === 1) {
              userlisttemp[index].sepaallowedlabel = "SI";
            } else {
              userlisttemp[index].sepaallowedlabel = "NO";
            }
          });
          self.userslist = userlisttemp;
          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;

          self.snacktext = "Error al obtener lista de usuarios";
          self.snackcolor = "error";
          self.snackresult = true;
          /*     self.$toast.open({
                        duration: 5000,
                        message: self.$t('myMachinesErrorGetMachines'),
                        type: 'is-danger'
                    })
                */
        }
      );
      /*     const _this=this;
      
        const { data } =  PaymentsRepository.listPayments().then(
                function(success) {
                    _this.$store.commit('', false)
                    //this.$router.push({path: '/myMachines'})
                    //his.posts = data;
                    _this.$router.push({
                        name: "dashboard/Dashboardv1"
                    });

                }).catch(function(error) {
                    _this.loginError = true
                }
            )
*/
    },

    getrents(useruuid) {
      const self = this;
      //  let url = process.env.VUE_APP_BASE_URI + '/filtermachinesbyspecs'
      let url =
        process.env.VUE_APP_BASE_URI + "/getrentsforuserasowner/" + useruuid;

      this.isLoading = true;
      //   self.$http.post(url, filtersx).then(
      self.$http.get(url).then(
        function(success) {
          self.isLoading = false;

          self.rents = success.data.rents;
          self.numalquileres = self.rents.length;
          for (var i = 0; i < self.numalquileres; i++) {
            self.rents[i].rentfromcalc = self.moment(self.rents[i].rentfrom);
            self.rents[i].renttocalc = self.moment(self.rents[i].rentto);

            self.rents[i].rentfrom = self
              .moment(self.rents[i].rentfrom)
              .format("DD-MM-YYYY")
              .split("T")[0];
            self.rents[i].rentto = self
              .moment(self.rents[i].rentto)
              .format("DD-MM-YYYY")
              .split("T")[0];
          }

          EventBus.$emit("setPremiumRents", self.rents);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de alquileres";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    getmachines(useruuid) {
      const self = this;
      //  let url = process.env.VUE_APP_BASE_URI + '/filtermachinesbyspecs'
      let url = process.env.VUE_APP_BASE_URI + "/getmachinesforuser/" + useruuid;
      this.isLoading = true;
      //   self.$http.post(url, filtersx).then(
      self.$http.get(url).then(
        function(success) {
          self.isLoading = false;

          self.machines = success.data.machines;
          self.numequipos = self.machines.length;
          for (var a = 0; a < self.numequipos; a++) {
            self.machines[a].imgurl =
              process.env.VUE_APP_BASE_URI +
              "/getimage/" +
              self.machines[a].imageuuid;
            if (self.machines[a].imageuuid === "") {
              self.machines[a].imgurl =
                process.env.VUE_APP_BASE_URI +
                "/getimage/86964f76-fb15-4cc2-a57e-d8438eda5a22";
            }
            if (self.machines[a].machinelocation != null)
              self.machines[a].machinelocation = self.machines[
                a
              ].machinelocation.replace(",", ", ");
            self.machines[a].machinerate = 5;
            if (self.machines[a].machinestatus == 1) {
              self.machines[a].machinestatuslabel = "Activo";
              self.machines[a].machinestatuscolor = "green";
            } else {
              self.machines[a].machinestatuslabel = "NO Activo";
              self.machines[a].machinestatuscolor = "red";
            }
          }

          EventBus.$emit("setPremiumMachines", self.machines);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de alquileres";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    activateUser() {
      if (this.activCode.length > 3) {
        const self = this;
        let url =
          process.env.VUE_APP_BASE_URI +
          "/getuserbyuuid/" +
          this.userData.useruuid; // self.$store.getters.uuid
        self.$http.get(url).then(function(success) {
          const email = success.data.userdata.email;
          let url = process.env.VUE_APP_BASE_URI + "/activateusersms";
          var bodyuser = {
            activationcode: self.activCode,
            useremail: email
          };
          self.$http.post(url, bodyuser).then(
            function(success) {
              self.isChangePassword = false;
              self.snacktext = "El usuario ha sido activado";
              self.snackcolor = "success";
              self.snackresult = true;
              self.isActivateUser = false;
              self.loadData();
            },
            function(error) {
              self.isChangePassword = false;

              self.snacktext = "Error. Los datos no son correctos";
              self.snackcolor = "warning";
              self.snackresult = true;
            }
          );
        });
      } else {
        //this.isChangePassword = false

        this.snacktext = "El código no tiene el formato correcto ";
        this.snackcolor = "red";
        this.snackresult = true;
      }
    },
    resendCode() {
      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI +
        "/getuserbyuuid/" +
        this.userData.useruuid; // self.$store.getters.uuid
      self.$http.get(url).then(function(success) {
        const email = success.data.userdata.email;
        let url = process.env.VUE_APP_BASE_URI + "/resendactivationcode";
        var bodyuser = {
          useremail: email
        };
        self.$http.post(url, bodyuser).then(
          function(success) {
            self.isChangePassword = false;
            self.snacktext = "El código se ha enviado al usuario ";
            self.snackcolor = "success";
            self.snackresult = true;
          },
          function(error) {
            self.isChangePassword = false;

            self.snacktext = "Error. No se ha podido enviar el código";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.section-def {
  margin-bottom: 50px;
}

.toobar-extension {
  margin-left: 5px !important;
}

.breadcrumb-section {
  margin-left: -25px !important;
}

.action-btn {
  margin-left: 5px !important;
}

.state1 {
  background-color: salmon;
  text-color: #ffffff;
}

.state2 {
  background-color: green;
  text-color: #ffffff;
}

.state3 {
  background-color: red;
  text-color: #ffffff;
}
</style>

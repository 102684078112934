<template>
  <v-container grid-list-md text-xs-center>
    <v-layout row>
      <h2>Nuevo Alquiler</h2>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm6 md6 class="my-3">
        <div class="subheading">Desde</div>
        <v-date-picker
          v-model="datefrom"
          :events="blockeddays"
          event-color="red lighten-1"
          locale="es-es"
          :first-day-of-week="1"
          header-color="blue"
        ></v-date-picker>
      </v-flex>

      <v-flex xs12 sm6 md6 class="my-3">
        <div class="subheading">Hasta</div>
        <v-date-picker
          v-model="dateto"
          :events="blockeddays"
          event-color="red lighten-1"
          locale="es-es"
          :first-day-of-week="1"
          header-color="blue"
        ></v-date-picker>
      </v-flex>

      <v-flex xs12 sm6 d-flex>
        <v-select
          :items="userslist"
          v-model="currentUserid"
          label="Usuario"
          item-text="userinfo"
          item-value="userid"
          outline
        ></v-select>
      </v-flex>

      <v-flex xs12 sm4>
        <v-checkbox
          class="text-sm-right text-xs-right custom-checkbox"
          label="Con Transporte"
          :value="true"
          :input-value="hasTransport"
        ></v-checkbox>
      </v-flex>

      <v-flex xs12 md12>
        <v-text-field
          v-model="rentplace"
          :rules="nameRules"
          :counter="50"
          label="Localización"
          readonly
          outline
        ></v-text-field>

        <google-map />
      </v-flex>

      <v-flex xs12 md12>
        <v-text-field
          v-model="rentconditions"
          :rules="nameRules"
          :counter="150"
          label="Tipo de trabajo"
          outline
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-dialog v-model="isCreateRent" persistent max-width="500px">
      <v-btn slot="activator" color="act" round  dark bold outline  class="bold btnfixed">Crear Alquiler</v-btn>
      <v-card>
        <v-card-title>
          <span class="headline">Crear Alquiler</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap> </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" round  dark bold outline  class="bold btnfixed" @click.native="isCreateRent = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" round  dark bold outline  class="bold btnfixed" @click.native="confirmSave"
            >Crear Alquiler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top="true"
    >
      {{ snacktext }}
      <v-btn dark round  bold outline  class="bold btnfixed" @click="snackresult = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import GoogleMap from "../../components/Maps/GoogleMap";
import { EventBus } from "../../eventBus";

export default {
  name: "CreateAutoRent",

  data() {
    return {
      macdata: {},
      userslist: [],
      isLoading2: false,
      isCreateRent: false,

      rentconditions: "",
      rentplace: "",
      rentlocation: "",
      currentUserid: 0,
      machineid: 0,
      datefrom: "", // new Date().toISOString().substr(0, 10),
      dateto: "", //new Date().toISOString().substr(0, 10),
      userid: 0,
      transportneeded: 0,
      insuranceneeded: 0,
      caltype: "month",
      calstart: "2019-07-12",
      calend: "2020-12-06",
      blockeddays: [],
      currentMonth: 8,
      date: null,
      machineplace: "",
      machinelocation: "",
      hasTransport: false
    };
  },
  components: {
    GoogleMap
  },
  created: function() {
    this.$on("newAddress", section => {
      console.log(section);
    });
  },
  mounted: function() {
    //this.getop(this.$route.query.id);

    this.macdata = this.$route.params.currentmachine;
    this.loadUsers();
    this.getop(this.macdata.machineuuid);

    const _this = this;
    EventBus.$on("newAddress", addressStr => {
      _this.rentplace = addressStr;
      console.log("Address: " + addressStr);
    });

    EventBus.$on("newLocation", locatStr => {
      _this.rentlocation = locatStr;
      console.log("Address: " + locatStr);
    });
  },
  computed: {},
  methods: {
    onNewAddress(someValue) {
      console.log(someValue);
    },
    getop: function(opuuid) {
      const _this = this;
      this.isLoading2 = true;
      const url = process.env.VUE_APP_BASE_URI + "/getmachinebyuuid/" + opuuid;
      _this.$http
        .get(url) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log(response);
            _this.isLoading2 = false;
            _this.macdata = response.data.machinedata;
            if (_this.macdata.machineinsuranceavailable == 1)
              _this.hasInsurance = true;
            if (_this.macdata.machinetransportavailable == 1)
              _this.hasTransport = true;
            _this.macdata.machineattributes.forEach(element => {
              if (element.attributetype == 1) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                if (element.attributename == "Potencia") var aa = 0;
                else _this.attsType1.push(attnew);
              } else if (element.attributetype == 3) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                _this.attsType3.push(attnew);
              } else if (element.attributetype == 4) {
                var attnew = {};
                attnew.labelx = element.attributename;
                if (element.attributevalueyesno == 0) attnew.valuebool = false;
                else attnew.valuebool = true;
                _this.attsType4.push(attnew);
              }
            });
            if (_this.macdata.machinelocation != null) {
              EventBus.$emit("setMacAddress", _this.macdata.machinelocation);
            }

            _this.$http
              .get(
                process.env.VUE_APP_BASE_URI +
                  "/getphotosformachine/" +
                  response.data.machinedata.machineuuid
              )
              .then(
                function(response) {
                  console.log("" + response);
                  //  this.isLoading2 = false;
                  this.machinePhotos = response.data;
                  for (var a = 0; a < this.machinePhotos.length; a++) {
                    var newimg = {};
                    newimg.src =
                      process.env.VUE_APP_BASE_URI +
                      this.machinePhotos[a].imageuuid;
                    this.images.push(newimg);
                  }
                }.bind(this)
              )
              .catch(
                function(error) {
                  console.log(error);
                  // this.isLoading2 = false;
                }.bind(this)
              );
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            this.isLoading2 = false;
          }.bind(this)
        );

      const url2 =
        process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + opuuid;
      _this.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
            this.blockeddays = response.data.blockeddays;
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            // this.isLoading2 = false;
          }.bind(this)
        );
    },
    loadUsers() {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getuserlistforpremium";
      self.isLoading = true;
      self.$http.get(url).then(
        function(success) {
          var userlisttemp = success.data.users;
          userlisttemp.sort((a, b) => (a.useralias > b.useralias) ? 1 : -1)
          var userlistinfo = [];
          self._.forEach(userlisttemp, function(currentitem, index) {
            if (currentitem.usertype === 1) {
              userlisttemp[index].usertypelabel = "Normal";
            } else if (currentitem.usertype === 2) {
              userlisttemp[index].usertypelabel = "Premium ";
            } else {
              return;
            }

            if (currentitem.userstatus != 2) {
              return;
            }

            if (currentitem.sepaallowed === 1) {
              userlisttemp[index].sepaallowedlabel = "SI";
            } else {
              userlisttemp[index].sepaallowedlabel = "NO";
            }
            var user = {
              userinfo:
                userlisttemp[index].useralias+
                " - " +
                userlisttemp[index].useridentitydocument,
              userid: userlisttemp[index].iduser
            };
            userlistinfo.push(user);
          });
          self.userslist = userlistinfo;
          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de usuarios";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },

    confirmSave() {
      if (true) {
        const self = this;
        let url = process.env.VUE_APP_BASE_URI + "/createrent";
        var transneeded = 0;
        if (this.hasTransport) transneeded = 1;
        const rentdata = {
          machineid: this.macdata.machineid,
          rentfrom: this.datefrom,
          rentto: this.dateto,
          userid: this.currentUserid,
          transportneeded: transneeded,
          insuranceneeded: 0,
          rentconditions: this.rentconditions,
          paymenttype: 1,
          rentplace: this.rentplace
        };
        self.$http.post(url, rentdata).then(
          function(success) {
            self.isCreateRent = false;
            self.snacktext = "El alquiler se ha creado correctamente";
            self.snackcolor = "success";
            self.snackresult = true;
            self.rentuuid = success.data.rentuuid;
            self.isActivateUser = true;
            //  this.$router.push({ path: '/pages/machinedetail/:id', query: { id: useruuid }})
          },
          function(error) {
            self.isCreateRent = false;

            self.snacktext = "Error. No se ha podido crear el alquiler";
            self.snackcolor = "red";
            self.snackresult = true;
          }
        );
      }
    }
  }
};
</script>
<style lang="stylus">
#example-custom-transition {
  .fade {
    &-enter-active, &-leave-active, &-leave-to {
      transition: 0.3s ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }
}

.custom-checkbox {
  height: 20px;
  font-size: 12;
  margin-left: 5px;
}

.custom-machineatt {
  height: 30px;
  font-size: 12;
  margin-left: 15px;
}

.v-label {
  font-size: 14px;
  font-color: black;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"myContainer mtop40"},[_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-6"},[_c('bar',{attrs:{"serviceUrl":_vm.serviceMachineRentsUrl,"chartData":'',"options":{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'bottom',
              labels: { fontColor: '#000080' }
            },
            title: { display: true, text: '' },
            scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
          }}})],1),_c('div',{staticClass:"column is-6"},[_c('bar',{attrs:{"serviceUrl":_vm.serviceMachineRentsDuration,"chartData":'',"options":{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'bottom',
              labels: { fontColor: '#000080' }
            },
            title: { display: true, text: '' },
            scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
          }}})],1)]),_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-6"},[_c('bar',{attrs:{"serviceUrl":_vm.serviceMachineInFavorites,"chartData":'',"options":{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'bottom',
              labels: { fontColor: '#000080' }
            },
            title: { display: true, text: '' },
            scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
          }}})],1),_c('div',{staticClass:"column is-6"},[_c('bar',{attrs:{"serviceUrl":_vm.serviceMachineMonthDistributed,"chartData":'',"options":{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'bottom',
              labels: { fontColor: '#000080' }
            },
            title: { display: true, text: '' },
            scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
          }}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
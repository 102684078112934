<template>
  <div>
    <v-container fluid grid-list-xl page>
      <v-layout row wrap>
        <v-flex xs12 sm8 md12>
          <section-header v-text="page.headline"></section-header>
          <div class="subheading justify" v-html="page.description"></div>
          <div class="my-5"></div>
        </v-flex>
      </v-layout>

      <v-flex xs12 md8>
        <v-card>
          <v-toolbar color="transparent" flat>
            <v-toolbar-title>Alquileres</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- <v-card-title primary-title class="title my-0">Visitors & Page views</v-card-title> -->
          <v-card-text>
            <line-chart
              :data="rentgraphdata"
              :options="rentsinfo.option"
              :height="300"
            ></line-chart>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
    <v-container fluid grid-list-xl page>
      <v-layout wrap>
        <v-flex xs6 md3>
          <download-excel
            :data="machinesrents"
            :fields="json_fields"
            name="resumenalquileres.xls"
            worksheet="Resumen Alquileres"
          >
            <v-btn color="act" class="text--white green bold btnfixed" round  dark bold outline >Descargar Excel</v-btn>
          </download-excel>
        </v-flex>
        <v-flex xs6 md3>
          <download-excel
            :data="machinesrents"
            :fields="json_fields"
            name="resumenalquileres.csv"
            type="csv"
            worksheet="Resumen Alquileres"
          >
            <v-btn color="act" class="text--white green bold btnfixed" round  dark bold outline >Descargar CSV</v-btn>
          </download-excel>
        </v-flex>

        <v-row>
          <v-col cols="12" sm="6">
            <v-layout row wrap>
              <v-flex xs12 lg6>
                <v-menu
                  ref="menu1"
                  :close-on-content-click="false"
                  v-model="menu1"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="dateFormatted"
                    label="Fecha inicio"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    prepend-icon="event"
                    @blur="date = parseDate(dateFormatted)"
                  ></v-text-field>
                  <v-date-picker
                    v-model="date"
                    no-title
                    locale="es-es"
                    :first-day-of-week="1"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 lg6>
                <v-menu
                  :close-on-content-click="false"
                  v-model="menu2"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                  readonly
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedDateFormatted2"
                    label="Fecha final"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="date2"
                    locale="es-es"
                    :first-day-of-week="1"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>

            <v-btn color="act" class="text--white green bold btnfixed" round  dark bold outline  @click="refresh()">Filtrar por fechas</v-btn>
          </v-col>
        </v-row>
        <v-card>
          <v-spacer></v-spacer>
          <v-text class="bold font-size-24">Total alquileres: {{ totalrents }} </v-text
          ><v-text  class="bold font-size-24">-------- Total ingresos:{{ totalamount }} €</v-text>
          <v-text-field
            sm6
            md6
            xs12
            v-model="search"
            append-icon="search"
            label="Buscar..."
            single-line
          ></v-text-field>
          <v-data-table
            :headers="headers2"
            :items="machinesrents"
            :search="search"
            class="elevation-1 fixed-header"
            rows-per-page-text="Filas por página"
            :rows-per-page-items="[15, 30, 50, { text: 'todas', value: -1 }]"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>

            <template slot="items" slot-scope="props">
              <td class="text-xs-right">{{ props.item.machinename }}</td>
              <td class="text-xs-right">{{ props.item.idmachine }}</td>
              <td>{{ props.item.owneralias }}</td>
              <td>{{ props.item.machineowner }}</td>
              <td class="text-xs-right">
                {{ props.item.machineserialnumber }}
              </td>
              <td class="text-xs-right">{{ props.item.machineplatenumber }}</td>
              <td class="text-xs-right">{{ props.item.numrents }}</td>
              <td class="text-xs-right">{{ props.item.totalincome }}</td>
              <td class="text-xs-right">{{ props.item.totalhours }}</td>
              <td class="text-xs-right">{{ props.item.totaldays }}</td>
              <td class="text-xs-right">{{ props.item.tacometer }}</td>
              <td class="text-xs-right">
                <v-chip :color="props.item.machinestatuscolor" dark>{{
                  props.item.machinestatuslabel
                }}</v-chip>
              </td>
              <td class="text-xs-right">{{ props.item.creationdate }}</td>
              <td class="text-xs-right">{{ props.item.deletiondate }}</td>
              <td>
                <v-icon
                  small
                  class="mr-2"
                  @click="gotoDetail(props.item.machineuuid)"
                >
                  visibility
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-layout>

      <v-snackbar
        v-model="snackresult"
        :color="snackcolor"
        :timeout="6000"
        top="true"
      >
        {{ snacktext }}
        <v-btn dark flat @click="snackresult = false">
          Cerrar
        </v-btn>
      </v-snackbar>
    </v-container>

    <!--   
  <v-flex xs12 sm6 md6 lg4 v-for="(item, idx) in chats" :key="idx">
    <ul>
  <li v-for="(value, key2) in item">{{ value.senderName }}: {{ value.messageText }} </li>
</ul>
    {{idx}}
  </v-flex>
-->
  </div>
</template>

<script>
/*
amountpayed: 900
idpaymentstatus: 2
idrent: 488
machinename: "Ter"
owneralias: "Marta12"
paymentamount: 900
paymentdata: "959999/018687897529827156149505050702"
paymentdate: "Mon, 05 Aug 2019 00:00:00 GMT"
paymenttype: 1
rentfrom: "Tue, 20 Aug 2019 00:00:00 GMT"
rentto: "Wed, 28 Aug 2019 00:00:00 GMT"
transactiondate: "Fri, 19 Jul 2019 11:48:26 GMT"
useralias: "Marta12"
*/
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import PlainTableOrder from "@/components/Widgets/list/PlainTableOrder";
import { debug } from "util";
import {
  lineChartJs as lineChartData,
  barChartJs as barChartData
} from "@/data/ChartWidget";
import LineChart from "@/components/ChartJs/LineChart";

export default {
  mixins: [validationMixin],

  data() {
    return {
      search: this.$store.state.machinesRentsSearch,
      headers2: [
        { text: "Equipo ", value: "machinename" },
        { text: "ID Equipo ", value: "idmachine" },
        { text: "Propietario", value: "owneralias" },
        { text: "ID Prop.", value: "machineowner" },
        { text: "N. Bastidor", value: "machineserialnumber" },
        { text: "Matricula", value: "machineplatenumber" },
        { text: "Alquileres", value: "numrents" },
        { text: "(€)", value: "totalincome" },
        { text: "Horas", value: "totalhours" },
        { text: "Días", value: "totaldays" },
        { text: "Tacometro", value: "tacometer" },
        { text: "Estado", value: "machinestatuslabel" },
        { text: "Fecha creación ", value: "creationdate" },
        { text: "Fecha eliminación ", value: "deletiondate" },
        { text: "Ver detalle ", value: "" }
      ],
      totalrents: 0,
      totalamount: 0,
      rents: [],
      machinesrents: [],
      machines: [],
      currentrentid: null,
      currentchat: null,
      chats: [],
      chatmessages: [],
      isLoading: false,
      isShowChatDialog: false,
      isCancelRentDialog: false,
      snackbar: false,
      snackresult: false,
      snacktext: "",
      snackcolor: "success",
      date: null,
      date2: null,
      dateFormatted: null,
      menu1: false,
      menu2: false,
      page: {
        title: "Alquileres por equipo",
        headline: "Alquileres por equipo",
        description: "Resumen de alquileres por equipo"
      },
      breadcrumbs: [
        {
          text: "Admin",
          disabled: false
        },
        {
          text: "Paginas",
          disabled: false
        },
        {
          text: "Alquileres por equipo",
          disabled: false
        }
      ],
      bymonth: {},
      bymachine: {},
      rentmonth: [],
      rentsinfo: {
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          ],
          datasets: [
            {
              label: "Alquileres por equipo",
              fill: false,
              data: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              borderColor: this.$vuetify.theme.primary,
              pointBorderColor: this.$vuetify.theme.primary,
              pointBackgroundColor: this.$vuetify.theme.primary,
              shadowOffsetX: 3,
              shadowOffsetY: 3,
              shadowBlur: 10,
              shadowColor: "rgba(98, 0, 234, 0.5)",
              pointRadius: 4,
              pointBevelWidth: 3,
              pointBevelHighlightColor: "rgba(255, 255, 255, 0.75)",
              pointBevelShadowColor: "rgba(98, 0, 234, 0.5)",
              pointHoverRadius: 6,
              pointHoverBevelWidth: 4.5,
              pointHoverInnerGlowWidth: 20,
              pointHoverInnerGlowColor: `rgba(98, 0, 234, 0.5)`,
              pointHoverOuterGlowWidth: 20,
              pointHoverOuterGlowColor: this.$vuetify.theme.secondary,
              tension: 0.15
            }
          ]
        },
        option: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: "index",
            intersect: false
          },
          hover: {
            mode: "nearest",
            intersect: true
          },
          legend: {
            display: false
          },
          spanGaps: true,
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Month"
                }
              }
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value"
                }
              }
            ]
          }
        },
        gradient: true,
        gradientSet: ["#80b6f4", "#f49080"]
      },

      json_fields: {
        Propietario: "owneralias",
        Equipo: "machinename",
        Bastidor: "machineserialnumber",
        Matricula: "machineplatenumber",
        Bastidor: "machineserialnumber",
        "Ingresos(€)": {
          field: "totalincome",
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0) {
              var newvalue = valstr.replace(".", ",");
              return newvalue;
            }
            return value;
          }
        },
        Alquileres: "numrents",
        "Total horas": "totalhours",
        "Total días": "totaldays",
        Tacometro: "tacometer",

        Estado: "machinestatuslabel",

        "Fecha creación": "creationdate",
        "Fecha eliminación": "deletiondate"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  components: {
    PlainTableOrder,
    LineChart
  },
  computed: {
    rentgraphdata() {
      return this.rentsinfo.data;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.date2);
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2() {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
    search() {
      this.$store.commit('setMachinesRentsSearch', this.search);
    }
  },
  mounted: function() {
    this.getmachines();
    this.loadRents();
    console.log("mounted: got here");
  },
  methods: {
    gotoDetail: function(uuid) {
      this.$router.push({
        path: "/pages/machinedetail/:id",
        query: { id: uuid }
      });

      // this.$router.push({ path: '/machinedetail'})
    },
    loadData() {
      const self = this;
      self.isLoading = true;
      let uuid = this.$store.getters.uuid;

      let url = process.env.VUE_APP_BASE_URI + "/getrentsforuser/" + uuid;
      self.$http.get(url).then(
        function(success) {
          var transporterstemp = success.data.transporters;
          var id1 = transporterstemp[0].iduser;
          self.isLoading = false;
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.$toast.open({
            duration: 5000,
            message: self.$t("myMachinesErrorGetMachines"),
            type: "is-danger"
          });
        }
      );
    },

    getColor(status) {
      if (status > 7) return "red";
      else if (status > 2) return "orange";
      else return "green";
    },

    loadRents() {
      const self = this;
      let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI + "/getrentsforuserasowner/" + uuid;
      self.$http.get(url).then(
        function(success) {
          var paymentstemp = success.data.rents;
          var rentsfinal = [];
          self._.forEach(paymentstemp, function(expense, index) {
            if (expense.rentstatus < 10) {
              paymentstemp[index].rentfromcalc = self.moment(expense.rentfrom);
              paymentstemp[index].renttocalc = self.moment(expense.rentto);

              paymentstemp[index].rentfromf = self
                .moment(expense.rentfrom)
                .format("DD-MM-YYYY")
                .split("T")[0];
              paymentstemp[index].renttof = self
                .moment(expense.rentto)
                .format("DD-MM-YYYY")
                .split("T")[0];

              if (expense.idpaymentstatus === 1) {
                paymentstemp[index].stateclass = "state1";
                paymentstemp[index].idpaymentstatus = "PENDIENTE";
              }
              if (expense.idpaymentstatus === 2) {
                paymentstemp[index].stateclass = "state2";
                paymentstemp[index].idpaymentstatus = "PAGADO";
              }
              if (expense.idpaymentstatus === 3) {
                paymentstemp[index].stateclass = "state3";
                paymentstemp[index].idpaymentstatus = "RECHAZADO";
              }
              rentsfinal.push(paymentstemp[index]);
            }
          });
          self.rents = rentsfinal;
          var machinesfinal = rentsfinal;
          machinesfinal.map(self.groupmonth);
          rentsfinal.map(self.groupmachine);
          var myArray = Object.values(self.bymachine);
          self.machinesrents = myArray.filter(function(obj) {
            return  typeof obj.idmachine !== 'undefined';
          });
          var a = rentsfinal;
          self.rentsinfo.data.datasets[0].data[0] =
            self.bymonth[0] == null ? 0 : self.bymonth[0].length;
          self.rentsinfo.data.datasets[0].data[1] =
            self.bymonth[1] == null ? 0 : self.bymonth[1].length;
          self.rentsinfo.data.datasets[0].data[2] =
            self.bymonth[2] == null ? 0 : self.bymonth[2].length;
          self.rentsinfo.data.datasets[0].data[3] =
            self.bymonth[3] == null ? 0 : self.bymonth[3].length;
          self.rentsinfo.data.datasets[0].data[4] =
            self.bymonth[4] == null ? 0 : self.bymonth[4].length;
          self.rentsinfo.data.datasets[0].data[5] =
            self.bymonth[5] == null ? 0 : self.bymonth[5].length;
          self.rentsinfo.data.datasets[0].data[6] =
            self.bymonth[6] == null ? 0 : self.bymonth[6].length;
          self.rentsinfo.data.datasets[0].data[7] = self.bymonth[7].length;
          self.rentsinfo.data.datasets[0].data[8] = self.bymonth[8].length;
          self.rentsinfo.data.datasets[0].data[9] = self.bymonth[9].length;
          self.rentsinfo.data.datasets[0].data[10] = self.bymonth[10].length;
          self.rentsinfo.data.datasets[0].data[11] = self.bymonth[11].length;
          EventBus.$emit("refreshgraph", "some value");
        },
        function(error) {
          self.$toast.open({
            duration: 5000,
            message: self.$t("myMachinesErrorGetMachines"),
            type: "is-danger"
          });
        }
      );
    },
    groupmonth(value, index, array) {
      var d = new Date(value["rentfrom"]);
      // d = (d.getFullYear()-1970)*12 + d.getMonth();
      d = d.getMonth();
      this.bymonth[d] = this.bymonth[d] || [];
      this.bymonth[d].push(value);
    },
    groupmachine(value, index, array) {
      var d = "" + value.idmachine;
      this.bymachine[d] = this.bymachine[d] || [];
      this.bymachine[d].push(value);
      if (this.bymachine[d].totalincome == null)
        this.bymachine[d].totalincome = 0;
      if (value.rentstatus < 5 || value.rentstatus == 10) {
        return;
      }

//      this.bymachine[d].totalincome =
 //       this.bymachine[d].totalincome + value.rentprice;
   //   this.totalamount = this.totalamount + value.rentprice;
      if (this.bymachine[d].totalhours == null) {
        this.bymachine[d].totalhours = 0;
        this.bymachine[d].totaldays = 0;
        this.bymachine[d].numrents = 0;

        this.bymachine[d].owneralias = value.owneralias;
        this.bymachine[d].machinename = value.machinename;
        this.bymachine[d].idmachine = value.idmachine;
        var result = this.machines.find(obj => {
          return obj.machineid === value.idmachine;
        });
        if (result != null) {
          this.bymachine[d].machineuuid = result.machineuuid;

          this.bymachine[d].machineserialnumber = result.machineserialnumber;
          this.bymachine[d].machineplatenumber = result.machineplatenumber;
          this.bymachine[d].machinestatus = result.machinestatus;
          this.bymachine[d].tacometer = result.machinetacometer;
          if (result.deletiondate != null) this.bymachine[d].machinestatus = 3;
          if (this.bymachine[d].machinestatus == 1) {
            this.bymachine[d].machinestatuslabel = "Activo";
            this.bymachine[d].machinestatuscolor = "green";
          } else if (this.bymachine[d].machinestatus == 2) {
            this.bymachine[d].machinestatuslabel = "No Activo";
            this.bymachine[d].machinestatuscolor = "orange";
          } else if (this.bymachine[d].machinestatus == 3) {
            this.bymachine[d].machinestatuslabel = "Eliminado";
            this.bymachine[d].machinestatuscolor = "red";
          } else {
            this.bymachine[d].machinestatuslabel = "Eliminado";
            this.bymachine[d].machinestatuscolor = "red";
          }
          var datecalc = this.moment(result.creationdate);
          var deletioncalc = this.moment(result.deletiondate);

          this.bymachine[d].creationdate = this.moment(datecalc)
            .format("DD-MM-YYYY")
            .split("T")[0];

          if (result.deletiondate != null) {
            this.bymachine[d].deletiondate = this.moment(deletioncalc)
              .format("DD-MM-YYYY")
              .split("T")[0];
          }
        }
      }
      if (this.moment(this.date) > this.moment(value.rentcreationdate)) return;
      if (this.moment(this.date2) <= this.moment(value.rentcreationdate))
        return;
      var days = value.renttocalc.diff(value.rentfromcalc, "days");
      var hours = days * 9;
      this.bymachine[d].totalhours = this.bymachine[d].totalhours + hours;
      this.bymachine[d].totaldays = this.bymachine[d].totaldays + days;
      this.bymachine[d].numrents++;
      this.totalrents++;
      this.bymachine[d].totalincome =
        this.bymachine[d].totalincome + value.rentprice;
            this.totalamount = this.totalamount + value.rentprice;

    },
    getmachines: function(typemachine) {
      const self = this;
      let uuid = this.$store.getters.uuid;
      //  let url = process.env.VUE_APP_BASE_URI + '/filtermachinesbyspecs'
      let url = process.env.VUE_APP_BASE_URI + "/getmachinesforuser/" + uuid;
      var filtersx = {
        filters: {
          machinetype: typemachine
        }
      };
      this.isLoading = true;
      //   self.$http.post(url, filtersx).then(
      self.$http.get(url).then(
        function(success) {
          self.isLoading = false;
          self.machines = success.data.machines;

          for (var a = 0; a < self.machines.length; a++) {
            self.machines[a].imgurl =
              process.env.VUE_APP_BASE_URI +
              "/getimage/" +
              self.machines[a].imageuuid;
            if (self.machines[a].imageuuid === "") {
              self.machines[a].imgurl =
                process.env.VUE_APP_BASE_URI +
                "/getimage/86964f76-fb15-4cc2-a57e-d8438eda5a22";
            }
            if (self.machines[a].machinelocation != null)
              self.machines[a].machinelocation = self.machines[
                a
              ].machinelocation.replace(",", ", ");
            self.machines[a].machinerate = 5;
            if (self.machines[a].machinestatus == 1) {
              self.machines[a].machinestatuslabel = "Activo";
              self.machines[a].machinestatuscolor = "green";
            } else if (self.machines[a].machinestatus == 2) {
              self.machines[a].machinestatuslabel = "No Activo";
              self.machines[a].machinestatuscolor = "orange";
            } else if (self.machines[a].machinestatus == 3) {
              self.machines[a].machinestatuslabel = "Eliminado";
              self.machines[a].machinestatuscolor = "red";
            } else {
              self.machines[a].machinestatuslabel = "Eliminado";
              self.machines[a].machinestatuscolor = "red";
            }
            if (self.machines[a].machinetype == 1) {
              self.machines[a].machinetypelabel = "Tractor";
            } else if (self.machines[a].machinetype == 2) {
              self.machines[a].machinetypelabel = "Remolcada";
            } else {
              self.machines[a].machinetypelabel = "E. Verdes";
            }
            var strdesc = self.machines[a].machinedescription;
            if (strdesc !== undefined) {
              var first = strdesc.split(" ");
              if (
                self.machines[a].machinedescription.length > 20 &&
                first.length < 2
              )
                self.machines[a].machinedescription = "xx";
            }

            var result = self.$store.getters.premiumusers.filter(obj => {
              return obj.iduser === self.machines[a].machineowner;
            });
            self.machines[a].owneralias = result[0].useralias;
            self.initgroup(self.machines[a]);
          }
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de equipos";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    refresh() {
      this.machinesrents = [];
      this.bymachine = [];
      this.totalrents = 0;
      this.totalamount = 0;
      // this.getmachines();
      this.loadRents();
    },
    initgroup(value) {
      var d = "" + value.machineid;

      this.bymachine[d] = this.bymachine[d] || [];
      this.bymachine[d].push(value);
      this.bymachine[d].totalincome = 0;

      this.bymachine[d].totalhours = 0;
      this.bymachine[d].totaldays = 0;
      this.bymachine[d].numrents = 0;

      this.bymachine[d].owneralias = value.owneralias;
      this.bymachine[d].machineowner = value.machineowner;
      this.bymachine[d].machinename = value.machinename;
      this.bymachine[d].idmachine = value.idmachine;
      this.bymachine[d].machineuuid = value.machineuuid;

      this.bymachine[d].machineserialnumber = value.machineserialnumber;
      this.bymachine[d].machineplatenumber = value.machineplatenumber;
      this.bymachine[d].machinestatus = value.machinestatus;
      this.bymachine[d].tacometer = value.machinetacometer;
      if (value.deletiondate != null) this.bymachine[d].machinestatus = 3;
      if (this.bymachine[d].machinestatus == 1) {
        this.bymachine[d].machinestatuslabel = "Activo";
        this.bymachine[d].machinestatuscolor = "green";
      } else if (this.bymachine[d].machinestatus == 2) {
        this.bymachine[d].machinestatuslabel = "No Activo";
        this.bymachine[d].machinestatuscolor = "orange";
      } else if (this.bymachine[d].machinestatus == 3) {
        this.bymachine[d].machinestatuslabel = "Eliminado";
        this.bymachine[d].machinestatuscolor = "red";
      } else {
        this.bymachine[d].machinestatuslabel = "Eliminado";
        this.bymachine[d].machinestatuscolor = "red";
      }

      this.bymachine[d].idmachine = value.machineid;
      this.bymachine[d].ownername = value.ownername;
      var datecalc = this.moment(value.creationdate);
      var deletioncalc = this.moment(value.deletiondate);

      this.bymachine[d].creationdate = this.moment(datecalc)
        .format("DD-MM-YYYY")
        .split("T")[0];

      if (value.deletiondate != null) {
        this.bymachine[d].deletiondate = this.moment(deletioncalc)
          .format("DD-MM-YYYY")
          .split("T")[0];
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>

<style lang="stylus" scoped>
.section-def
  margin-bottom: 50px
.toobar-extension
  margin-left: 5px !important
.breadcrumb-section
  margin-left: -25px !important
.action-btn
  margin-left: 5px !important

.state1{
  background-color :salmon;
  text-color : #ffffff;

}

.state2{
  background-color :green;
  text-color : #ffffff;

}

.state3{
  background-color :red;
  text-color : #ffffff;

}
</style>

<template>
    <div>
      <v-container fluid grid-list-xl class="pb-0">
        <v-toolbar flat class="transparent section-definition-toolbar mb-3">
          <v-avatar class="box-glow" tile>
            <v-icon dark>message</v-icon>
          </v-avatar>
          <v-toolbar-title class="primary--text hidden-xs-only">{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            color="white"
            v-model="search"
            label="Search User"
            solo
            append-icon="search"
            class="max-width-300x"
          ></v-text-field>
          <!-- <v-list dense class="text-xs-right max-width-250x">
            <v-list-tile>
              <v-list-tile-avatar>
                <v-avatar
                  size="40px"
                  color="grey lighten-4"
                  class="ml-2"
                >
                  <img :src="authUser.avatar" alt="avatar">
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="authUser.name" class="subheading"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list> -->
        </v-toolbar>
        <v-navigation-drawer
          hide-overlay
          v-model="drawerinternal"
          :permanent="$vuetify.breakpoint.mdAndUp"
          enable-resize-watcher
          :mini-variant="$vuetify.breakpoint.smAndDown"
          :temporary="$vuetify.breakpoint.smAndDown"
          class="chat-nav-drawer"
          floating
        >
          <v-list two-line class="pt-0 inner-sidebar v-menu-trans-list-npd">
            <template v-for="(group, index) in chats">
              <v-list-tile
                @click="switchGroup(group)" :key="group.useralias"
                :class="[ group.id === activeGroupId ? 'box-glow' : '']"
              >
                <v-list-tile-avatar>
                  <v-badge overlap >
                    <v-avatar
                      size="40px"
                    >
                      <img :src="group.avatar" alt="avatar">
                    </v-avatar>
                  </v-badge>
                  <img :src="group.avatar" >
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ group.useralias }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ group.last_message }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action class="compact-action">
                  <v-btn color="primary" fab class="btn-round-xs" v-if="group.unread > 0">{{ group.unread }}</v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider v-if="index + 1 < groups.length" :key="index"></v-divider>
            </template>
          </v-list>
        </v-navigation-drawer>
        <!-- Messaging Section -->
        <v-layout row  class="chat-section">
          <v-flex xs12 pr-0 pt-0 v-bind:pl-0="$vuetify.breakpoint.smAndDown">
            <v-toolbar card>
              <v-toolbar-side-icon @click.stop="drawerinternal = !drawerinternal" class="hidden-md-and-up"></v-toolbar-side-icon>
              <v-avatar
                size="40px"
                color="grey lighten-4"
                class="ml-2"
              >
                <img :src="activeGroup.avatar" alt="avatar">
              </v-avatar>
              <v-toolbar-title class="">{{ activeGroup.useralias}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- Chat theme -->
  
              <!-- User infor -->
              <v-menu
                offset-y
                left
                :close-on-content-click=false
                min-width="260"
                max-width="260"
              >
                <v-tooltip bottom slot="activator" class="mx-0" color="tooltipcolor">
                  <v-btn
                    icon
                    class="mx-0"
                    slot="activator"
                  >
                    <v-icon class="bold-weight">more_vert</v-icon>
                  </v-btn>
                  <span v-text="$t('labels.contactInfo')"></span>
                </v-tooltip>
                <v-card flat>
                  <v-toolbar color="primary" dense flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="an-15 white--text medium-text" v-text="$t('labels.contactInfo')"></v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-container fluid pa-0>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-card flat>
                          <v-img
                            src="/static/doc-images/cards/sunshine.jpg"
                            height="200px"
                          >
                          </v-img>
                          <v-card-title primary-title>
                            <div>
                              <div class="headline">{{ chats[0].name }}</div>
                              <span class="grey--text">{{ activeGroup }}</span>
                            </div>
                          </v-card-title>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-menu>
            </v-toolbar>
            <v-divider></v-divider>
                <div class="chat">
      <ul >
        <li v-for="message in messages" :key="message['.key']" >{{ message.Message }} -</li>
      </ul>
    </div>
            <!-- Chat Background Container -->
            <!-- Chat Background Container -->

  
                  <v-layout row wrap>
     <div class="chat">
      <ul class="reptileList">
        <li v-for="message in messages" :key="message['.key']" >{{ message.Message }} -</li>
      </ul>
    </div>
                  </v-layout>

            <v-layout row wrap class="no-mrpd">
              <v-flex xs12 class="no-mrpd">
                <div class="pos-relative vuse-chat-message-container">
                  <chat-editable
                    @update="chatMessageEditor = $event"
                    class="chat-message-editor"
                    type="innerHTML"
                    @onEnter="sendMessage"
                    :placeholder="$t('labels.typeYourMessage')"
                  ></chat-editable>
                  <v-btn
                    color="secondary"
                    class="ma-0 send-message-btn pa-0"
                    @click="sendMessage"
                    :disabled="chatMessageEditor === null || chatMessageEditor === ''"
                  >
                    <v-icon color="white">fa-paper-plane</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
</template>
<script>
import moment from "moment";
import { groupBy, find } from "lodash";
import ChatEditable from "@/components/Editable/ChatEditable";
import { rgba as Gradients } from "@/data/gradients";
import { groups, conversation, authUser } from "@/data/dummyData";
import { db } from "../../firebase";

export default {
  data() {
    return {
      chatMessageEditor: null,
      drawerinternal: true,
      title: this.$t("labels.chat"),
      gradientsList: Gradients,
      gradient: Gradients[9],
      chatGradient: Gradients[5],
      backgroundImg: "/static/doc-images/chatbackground.jpg",
      activeGroupId: 1,
      authUser,
      // Chat is always a group of members. For personal chat, it's restricted members (Only 2 members)
      // For personal chat, we just fetch other user's information to display in the list
      groups,
      // Get All conversation where auth user is member of the group
      // Not, conversation needs to be group by GroupID that's is.
      // For new, message push message on conversation object
      // It will automatically, manage the rest of the things.
      conversation,
      search: "",
      chats: [],
      messages: [],
    };
  },
  components: {
    ChatEditable
  },
  firestore() {
    return {
      chats: db.collection("users/adminuser/userrooms")
    };
  },
  beforeDestroy() {
    this.$eventBus.$off("resetChatEditor");
  },
  methods: {
      showconversation: function(currentchat) {
      this.$binding(
        "messages",
        db.collection("chats/" + currentchat[".key"] + "/messages")
      ).then(messages => {
        console.log(messages); // => __ob__: Observer
      });
    },
    switchGroup(group) {
      this.activeGroupId = group.id;

      this.$binding(
        "messages",
        db.collection("chats/" + group[".key"] + "/messages")
      ).then(messages => {
        console.log(messages); // => __ob__: Observer
      });
    },  
    changeChatBackground(gradient) {
      this.chatGradient = gradient;
    },
    sendMessage() {
      const activeGroup = this.activeGroup;
      const authUser = this.authUser;
      this.conversation.push({
        group_id: activeGroup.id,
        user_id: authUser.id,
        user: authUser,
        message: this.chatMessageEditor,
        created_at: moment().unix()
      });
      this.chatMessageEditor = null;
      this.$eventBus.$emit("resetChatEditor");
      this.$nextTick(() => {
        const container = document.querySelector("#chatscrollstatesetter");
        container.scrollTop = container.scrollHeight;
      });
    }
  },
  computed: {
    conversationGroup() {
      return groupBy(this.conversation, "group_id");
    },
    activeGroup() {
      return find(this.groups, { id: this.activeGroupId });
    },
    chat() {
      const group = this.activeGroupId;
      return this.conversationGroup[group];
    },
    filteredGroup() {
      return this.groups.filter(member =>
        member.user.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  }
};
</script>
<style scoped>
.toobar-extension {
  margin-left: 0 !important;
  min-width: 300px;
}
</style>

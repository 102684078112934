<template>
  <div>

    <br>
    <gmap-map
        ref="goomap"
      :center="center"
      :zoom="12"
      style="width:100%;  height: 400px;" 
      mapTypeId= "terrain"
      :bounds="currentBounds"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
'use strict'
import {EventBus} from '../../eventBus';

export const initLocation = ""



export default {
  name: "GoogleMap",
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 42.508, lng: -3.587 },
      markers: [],
      places: [],
      currentPlace: null,
      currentCoords: null,
      currentBounds: null
    };
  },
  
  mounted() {
  //  this.geolocate();
    EventBus.$on('setMacAddress', latLngStr => {
        var coordinates = latLngStr.split(',');
        var myLatlng = new google.maps.LatLng(parseFloat(coordinates[0]), parseFloat(coordinates[1]));        
        var marker = {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1])
        
        };
        var marker2 = {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1])
        
        };
        this.markers.push({ position: marker });
        this.markers.push({ position: marker2 });
        this.places.push(this.currentPlace);
        this.center = marker;
        if(  coordinates[0]=="0"){
          this.geolocate()
        }
        
    });

    EventBus.$on('setAdditionalAddress', address => {
        var geocoder = new google.maps.Geocoder()
        const self = this
        geocoder.geocode({'address': address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var loc = results[0].geometry.location
                self.markers[1].position =loc
              //  alert("Set bounds " + address)
                self.generateBounds()

            } 
            else {
                alert("Error: " + address + " no se puede encontrar en Google Maps.");
            }
        });

 
        
    });


  },

  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    generateBounds() {
      this.currentBounds = new google.maps.LatLngBounds()
      for (let m of this.markers) {
        this.currentBounds.extend(m.position)
      }
      const self = this;
      this.$refs.goomap.$mapPromise.then((map) => {
        map.fitBounds(self.currentBounds);
      });
    },    
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        var locatStr = "" + this.currentPlace.geometry.location.lat()+","+this.currentPlace.geometry.location.lng();
    //    EventBus.$emit('newAddress', this.currentPlace.formatted_address, locatStr);
    //    EventBus.$emit('newLocation',  locatStr);

        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        if(this.markers.length >0 ) {
         // alert( "Ya hay una direccion")
        }
        else{
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        }

      });
    }
  }
};
</script>
export const rgba = [
  {
    gradient:
      "to right top, rgba(255, 248, 0, 0.7), rgba(255, 19, 97, 0.7), rgba(68, 16, 122, 0.7), rgba(35, 21, 87, 0.7)",
    isDark: true
  },
  {
    gradient:
      "to left top, rgba(28, 26, 39, 0.7), rgba(61, 22, 53, 0.7), rgba(128, 19, 87, 0.7), rgba(184, 35, 90, 0.7), rgba(231, 98, 125, 0.7)",
    isDark: true
  },
  {
    gradient:
      "to right top, rgba(255, 0, 102, 0.7), rgba(212, 24, 114, 0.7), rgba(164, 69, 178, 0.7)",
    isDark: true
  },
  {
    gradient: "45deg , rgba(253, 85, 144, 0.7), rgba(247, 112, 98, 0.7)",
    isDark: true
  },
  {
    gradient: "40deg , rgba(252, 96, 118, 0.7), rgba(255, 153, 68, 0.7)",
    isDark: true
  },
  {
    gradient:
      "to right top , rgba(255, 154, 158, 0.7), rgba(250, 208, 196, 0.7)",
    isDark: true
  },
  {
    gradient: "-45deg , rgba(251, 171, 126, 0.7), rgba(247, 206, 104, 0.7)",
    isDark: true
  },
  {
    gradient: "-45deg , rgba(226, 209, 195, 0.7), rgba(253, 252, 251, 0.7)",
    isDark: true
  },
  {
    gradient:
      "-40deg , rgba(77, 35, 174, 0.7), rgba(100, 67, 217, 0.7), rgba(83, 135, 253, 0.7)",
    isDark: true
  },
  {
    gradient:
      "to right top, rgba(72, 1, 255, 0.7), rgba(121, 24, 242, 0.7), rgba(172, 50, 228, 0.7)",
    isDark: true
  },
  {
    gradient:
      "-40deg, rgba(80, 82, 133, 0.7), rgba(88, 94, 146, 0.7), rgba(101, 104, 159, 0.7), rgba(116, 116, 176, 0.7), rgba(126, 126, 187, 0.7), rgba(131, 137, 199, 0.7), rgba(151, 149, 212, 0.7), rgba(162, 161, 220, 0.7), rgba(181, 174, 228, 0.7)",
    isDark: true
  },
  {
    gradient: "to left top, rgba(68, 129, 235, 0.7), rgba(4, 190, 254, 0.7)",
    isDark: true
  },
  {
    gradient: "to left top, rgba(194, 233, 251, 0.7), rgba(161, 196, 253, 0.7)",
    isDark: true
  },
  {
    gradient: "-35deg, rgba(36, 57, 73, 0.7), rgba(81, 127, 164, 0.7)",
    isDark: true
  },
  {
    gradient: "to top, rgba(31, 59, 114, 0.7), rgba(75, 119, 206, 0.7)",
    isDark: true
  },
  {
    gradient: "-65deg, rgba(11, 163, 96, 0.7), rgba(60, 186, 146, 0.7)",
    isDark: true
  },
  {
    gradient: "to left top, rgba(0, 227, 174, 0.7), rgba(155, 225, 93, 0.7)",
    isDark: true
  },
  {
    gradient: "-40deg, rgba(143, 211, 244, 0.7), rgba(132, 250, 176, 0.7)",
    isDark: true
  },
  {
    gradient: "-50deg, rgba(171, 236, 214, 0.7), rgba(251, 237, 150, 0.7)",
    isDark: true
  },
  {
    gradient: "to left top, rgba(193, 193, 97, 0.7), rgba(212, 212, 177, 0.7)",
    isDark: true
  },
  {
    gradient: "to left top, rgba(89, 97, 100, 0.7), rgba(134, 143, 150, 0.7)",
    isDark: true
  },
  {
    gradient: "to left top, rgba(195, 207, 226, 0.7), rgba(245, 247, 250, 0.7)",
    isDark: true
  },
  {
    gradient:
      "to right top, rgba(215, 255, 254, 0.7), rgba(255, 254, 255, 0.7)",
    isDark: false
  }
];
export const rgb = [
  {
    gradient:
      "to right top, rgb(255, 248, 0), rgb(255, 19, 97), rgb(68, 16, 122), rgb(35, 21, 87)",
    isDark: true
  },
  {
    gradient:
      "to left top, rgb(28, 26, 39), rgb(61, 22, 53), rgb(128, 19, 87), rgb(184, 35, 90), rgb(231, 98, 125)",
    isDark: true
  },
  {
    gradient:
      "to right top, rgb(255, 0, 102), rgb(212, 24, 114), rgb(164, 69, 178)",
    isDark: true
  },
  { gradient: "45deg , rgb(253, 85, 144), rgb(247, 112, 98)", isDark: true },
  { gradient: "40deg , rgb(252, 96, 118), rgb(255, 153, 68)", isDark: true },
  {
    gradient: "to right top , rgb(255, 154, 158), rgb(250, 208, 196)",
    isDark: true
  },
  { gradient: "-45deg , rgb(251, 171, 126), rgb(247, 206, 104)", isDark: true },
  { gradient: "-45deg , rgb(226, 209, 195), rgb(253, 252, 251)", isDark: true },
  {
    gradient: "-40deg , rgb(77, 35, 174), rgb(100, 67, 217), rgb(83, 135, 253)",
    isDark: true
  },
  {
    gradient:
      "to right top, rgb(72, 1, 255), rgb(121, 24, 242), rgb(172, 50, 228)",
    isDark: true
  },
  {
    gradient:
      "-40deg, rgb(80, 82, 133), rgb(88, 94, 146), rgb(101, 104, 159), rgb(116, 116, 176), rgb(126, 126, 187), rgb(131, 137, 199), rgb(151, 149, 212), rgb(162, 161, 220), rgb(181, 174, 228)",
    isDark: true
  },
  {
    gradient: "to left top, rgb(68, 129, 235), rgb(4, 190, 254)",
    isDark: true
  },
  {
    gradient: "to left top, rgb(194, 233, 251), rgb(161, 196, 253)",
    isDark: true
  },
  { gradient: "-35deg, rgb(36, 57, 73), rgb(81, 127, 164)", isDark: true },
  { gradient: "to top, rgb(31, 59, 114), rgb(75, 119, 206)", isDark: true },
  { gradient: "-65deg, rgb(11, 163, 96), rgb(60, 186, 146)", isDark: true },
  {
    gradient: "to left top, rgb(0, 227, 174), rgb(155, 225, 93)",
    isDark: true
  },
  { gradient: "-40deg, rgb(143, 211, 244), rgb(132, 250, 176)", isDark: true },
  { gradient: "-50deg, rgb(171, 236, 214), rgb(251, 237, 150)", isDark: true },
  {
    gradient: "to left top, rgb(193, 193, 97), rgb(212, 212, 177)",
    isDark: true
  },
  {
    gradient: "to left top, rgb(89, 97, 100), rgb(134, 143, 150)",
    isDark: true
  },
  {
    gradient: "to left top, rgb(195, 207, 226), rgb(245, 247, 250)",
    isDark: true
  },
  {
    gradient: "to right top, rgb(215, 255, 254), rgb(255, 254, 255)",
    isDark: false
  }
];

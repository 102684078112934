import Repository from "./Repository";

const resource = "/payments";
export default {
  get() {
    return Repository.get(`${resource}`);
  },

  getPost(postId) {
    return Repository.get(`${resource}/${postId}`);
  },

  async listPayments() {
    const pathres = "getpaymentslist";
    var tok = await Repository.get(`${pathres}`);
    return tok;
  }
};
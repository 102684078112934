<template>
  <div>
    <section-definition :title="page.title"></section-definition>
    <v-container grid-list-md text-xs-center>
      <v-layout row>
        <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center"> </v-flex>
        <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
          <v-btn  color="act"  class="text--white green bold btnfixed" round  dark bold outline @click="saveData()">
            Guardar equipo
          </v-btn>
        </v-flex>
      </v-layout>

      <v-toolbar color="light-gray"  tabs elevation-12 padding="5px">
        <v-tabs
          v-model="currentItem"
          color="transparent"
          fixed-tabs
          slider-color="green"
        >
          <v-tab v-for="item in items2" :href="'#tab-' + item" :key="item">{{
            item
          }}</v-tab>
        </v-tabs>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-tabs-items v-model="currentItem">
        <v-tab-item value="tab-Imágenes">
          <v-layout row class="py-2">
            <v-flex xs6 sm6>
              <v-carousel
                :cycle="false"
                height="500"
                max="400"
                v-model="carouselIndex"
              >
                <v-carousel-item
                  v-for="(item, i) in images"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade"
                  transition="fade"
                ></v-carousel-item>
              </v-carousel>
            </v-flex>

            <v-flex sm6 md6 xs6  style="padding:30">
              <v-flex sm6 md6 xs6  class="text-sm-center text-xs-center">
                <v-field :label="Foto"></v-field>
                <upload-btn  color="success" round  dark bold outline  class="btnfixed"
                  @file-update="update"
                  title="Añadir Imagen"
                  noTitleUpdate="true"
                ></upload-btn>
              </v-flex>
              <v-flex sm6 md6 xs6   class="text-sm-center text-xs-center">
                <v-btn color="error" class="text--white red bold btnfixed" round  dark bold outline @click="deletePhoto()">
                  Borrar imagen
                </v-btn>
              </v-flex>

              <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
                <v-btn
                  color="warning"
                    class="text--white warning bold btnfixed" round  dark bold outline
                  title="Marcar como  principal"
                  @click="mainPhoto()"
                >
                  <v-icon
                    small
                    color="white"
                    class="mr-2"
                    title="Marcar como  principal"
                  >
                    star
                  </v-icon>
                  Marcar como principal
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item value="tab-Calendario">
          <v-flex xs12 sm12>
            <div class="display-1 grey--text text--darken-1">Disponibilidad </div>
            
          </v-flex>
          <v-flex xs12 sm12 class="my-3">
            <div class="subheading"></div>

            <v-row>
              <v-col cols="6" sm="6">
                <v-date-picker
                  v-model="clickeddate"
                  :events="blockeddays"
                  event-color="red lighten-1"
                  locale="es-es"
                  :first-day-of-week="1"
                  header-color="blue"
                ></v-date-picker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-layout row wrap>
                  <v-flex xs12 lg6>
                    <v-menu
                      ref="menu1"
                      :close-on-content-click="false"
                      v-model="menu1"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-text-field
                        slot="activator"
                        v-model="dateFormatted"
                        label="Fecha inicio"
                        hint="DD/MM/YYYY"
                        persistent-hint
                        prepend-icon="event"
                        @blur="date = parseDate(dateFormatted)"
                      ></v-text-field>
                      <v-date-picker
                        v-model="date"
                        no-title
                        locale="es-es"
                        :first-day-of-week="1"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 lg6>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="menu2"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                      readonly
                    >
                      <v-text-field
                        slot="activator"
                        v-model="computedDateFormatted2"
                        label="Fecha final"
                        hint="DD/MM/YYYY"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                      ></v-text-field>
                      <v-date-picker
                        v-model="date2"
                         locale="es-es"
                        :first-day-of-week="1"
                       no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>

                  </v-flex>
                </v-layout>
                <v-flex sm5 xs6 class="text-sm-center text-xs-center">
                    <v-text-field
                      v-model="motivoblock"
                      :counter="100"
                      label="Motivo del bloqueo"
                    ></v-text-field>
                </v-flex>

                <v-flex sm5 xs6 class="text-sm-center text-xs-center">
                  <v-btn color="error"  class="text--white red bold btnfixed" round  dark bold outline @click="blockDates()">
                    Bloquear fechas
                  </v-btn>
                </v-flex>
                <v-flex sm5 xs6 class="text-sm-center text-xs-center">
                  <v-btn
                     color="act"  class="text--white green bold btnfixed" round  dark bold outline
                    @click="unblockDates()"
                  >
                    Desbloquear fechas
                  </v-btn>
                </v-flex>
              </v-col>
            </v-row>
            <!--     <vc-date-picker
              mode="range"
              :value="null"
              :attributes="attrs2"
              color="red"
              is-inline
            /> -->
          </v-flex>
        </v-tab-item>

        <v-tab-item value="tab-Descripción">
          <v-layout row wrap class=" py-2 px-12" >
            <v-flex xs12 sm12>
              <v-card>
                <v-card-text>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="macdata.machinename"
                      :rules="nameRules"
                      :counter="50"
                      label="Nombre"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-select
                    v-model.number="macdata.machinebrandid"
                    type="number"
                    :items="brands"
                    item-text="brandname"
                    item-value="brandid"
                    label="Marca"
                    v-on:change="changeBrand"
                  ></v-select>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="macdata.machinedescription"
                      :rules="nameRules"
                      :counter="100"
                      label="Descripción"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="macdata.machinemodel"
                      :rules="nameRules"
                      :counter="30"
                      label="Modelo"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-divider></v-divider>
                  <div class="text-sm-left text-xs-left">
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model.number="macdata.machineprice"
                        type="number"
                        :rules="nameRules"
                        :counter="10"
                        :step="0.1"
                        label="Precio (€)"
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model.number="macdata.machinepriceexcess"
                        type="number"
                        :rules="nameRules"
                        :counter="10"
                        :step="0.1"
                        label="Fianza (€)"
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model.number="macdata.machinepower"
                        type="number"
                        :rules="nameRules"
                        :counter="10"
                        label="Potencia (CV)"
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md12>
                      <p class="mb-0">Los campos <span class="font-weight-bold">localización</span> y <span class="font-weight-bold">coordenadas</span> no pueden ser modificados a mano son de solo lectura</p>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="macdata.machineplace"
                        :rules="nameRules"
                        :counter="50"
                        label="Localización"
                        required
                        readonly
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="macdata.machinelocation"
                        :rules="nameRules"
                        :counter="50"
                        label="Coordenadas"
                        required
                        readonly
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="macdata.machineyear"
                        :rules="nameRules"
                        :counter="50"
                        label="Año de fabricación"
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model.number="macdata.machinetacometer"
                        type="number"
                        :rules="nameRules"
                        :counter="50"
                        label="Horas de uso "
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="macdata.machineserialnumber"
                        :rules="nameRules"
                        :counter="50"
                        label="Número de bastidor "
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="macdata.machineplatenumber"
                        :rules="nameRules"
                        :counter="50"
                        label="Matrícula "
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md10>
                      <v-slider
                        v-model.number="macdata.machinediscount1"
                        type="number"
                        :rules="rules"
                        :label="firstLabel"
                        step="1"
                        :max="40"
                        :min="0"
                        ticks
                        thumb-label="always"
                      ></v-slider>
                    </v-flex>

                    <v-flex xs12 md10>
                      <v-slider
                        v-model.number="macdata.machinediscount2"
                        type="number"
                        :rules="rules"
                        :label="secondLabel"
                        step="1"
                        :max="40"
                        :min="0"
                        ticks
                        thumb-label="always"
                      ></v-slider>
                    </v-flex>

                    <v-flex xs12 md10>
                      <v-slider
                        v-model.number="macdata.machinediscount3"
                        type="number"
                        :rules="rules"
                        :label="thirdLabel"
                        step="1"
                        :max="40"
                        :min="0"
                        ticks
                        thumb-label="always"
                      ></v-slider>
                    </v-flex>

                    <!-- <li v-for="(ingredient, i) in item.recipe.ingredientLines" :key="i">{{ingredient}}</li> -->
                  </div>
                  <v-checkbox
                    class="text-sm-left text-xs-left custom-checkbox"
                    label="Transporte disponible"
                    v-model="hasTransport"
                  ></v-checkbox>
                  <!-- <v-checkbox
                    class="text-sm-left text-xs-left custom-checkbox"
                    label="Seguro disponible"
                    v-model="hasInsurance"
                  ></v-checkbox> -->
                  <v-flex xs12 sm8 md4 lg2 pt-4>
                    <v-select 
                      v-if="hasInsurance"
                      :items="machineSecure"
                      item-text="insurancecompanyname"
                      item-value="idinsurancecompany"
                      v-model="selectedMachineSecure"
                      label="Seleccione seguro"
                    ></v-select>
                    <v-text-field
                      @wheel="$event.target.blur()"
                      v-if="selectedMachineSecure > 0"
                      v-model.number="macdata.machineinsuredvalue"
                      value="macdata.machineinsuredvalue"
                      type="number"
                      min="0"
                      :step="0.1"
                      label="Valor del equipo"
                      required
                    ></v-text-field>
                    <v-text-field
                      @wheel="$event.target.blur()"
                      v-if="selectedMachineSecure > 0"
                      v-model.number="macdata.machineinsuranceexcess"
                      value="macdata.machineinsuranceexcess"
                      type="number"
                      min="0"
                      :step="1"
                      label="Franquicia"
                      required
                    ></v-text-field>
                  </v-flex>                  
                </v-card-text>
                <v-card-title></v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-tab-item>
        
        <v-tab-item value="tab-Técnico">
          <v-layout row wrap>
            <v-flex xs12 sm12>
              <v-card class="py-8 my-8">
                <div class="custom-checkbox mb-8" v-for="(itemx, index) in attsType4" :key="index">
                  <v-checkbox
                    class="text-sm-left text-xs-left custom-checkbox"
                    style="font-size:12;"
                    :label="itemx.labelx"
                    v-model="itemx.valuebool"
                  ></v-checkbox>
                </div>
                <span> -</span>
              </v-card>

              <v-card>
                <div class="text-sm-left text-xs-left custom-machineatt" v-for="(itemx, index) in attsType3" :key="index">
                  <v-layout row wrap>
                    <v-flex d-flex xs6>
                    <v-select 
                      v-model="itemx.value"
                      :items = attributeslist[itemx.idx] 
                      item-text="attributelistvalue"
                      item-value="idattributelist"
                      :label="itemx.label">
                    </v-select>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card>

              <v-card class="my-8  py-8">
                <div class="custom-machineatt"></div>
                <div class="text-sm-left text-xs-left custom-machineatt" v-for="(itemx, index) in attsType1" :key="index">
                  <v-flex xs12 md6>
                    <v-text-field
                      class="py-n4"
                      filled
                      v-model="itemx.value"
                      :label="itemx.label"
                    ></v-text-field>
                  </v-flex>
                  <!--   <span class="text-sm-left text-xs-left custom-check">{{itemx.label}} : {{itemx.value}}</span> -->
                </div>
                <span> -</span><br />
                <span>- </span>
              </v-card>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item value="tab-Localización">
          <google-map />
        </v-tab-item>


      </v-tabs-items>

      <v-snackbar
        v-model="snackresult"
        :color="snackcolor"
        :timeout="6000"
        top="true"
      >
        {{ snacktext }}
        <v-btn dark flat @click="snackresult = false">Cerrar</v-btn>
      </v-snackbar>
    </v-container>

      <v-dialog v-model="dialog3" max-width="500px">
        <v-card>
          <v-card-title>
            <span>Motivo del bloqueo</span>
            <v-spacer></v-spacer>
          <v-text-field readonly v-model="motivo" ></v-text-field>
          </v-card-title>
          <v-card-actions>
            <v-btn color="primary" flat @click="dialog3=false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


  </div>
</template>

<script>
import GoogleMap from "../../components/Maps/GoogleMap";
import { EventBus } from "../../eventBus";
import upload from "../../components/upload.vue";
import password from "../../components/modals/password.vue";
import saveUserConfirm from "../../components/modals/saveUserConfirm.vue";
import UploadButton from "vuetify-upload-button";

export default {
  props: ["id"],

  data() {
    return {
      images: [],
      uuid: "",
      useruuid: "",
      macdata: {},
      machinePhotos: [],
      isLoading2: false,
      currentIdFile: 0,
      hasTransport: false,
      hasInsurance: false,
      attsType1: [],
      attsType2: [],
      attsType3: [],
      attsType4: [],
      selectedBrand: null,
      caltype: "month",
      calstart: "2019-07-12",
      calend: "2020-11-06",
      selecteddates: [],
      blockeddays: [],
      blockeddaysevents: [],
      currentMonth: 7,
      brands: [],
      attributes: [],
      snacktext: "",
      snackcolor: "",
      snackresult: "",
      page: {
        title: "Editar Equipo",
        headline: "Lista de alquileres",
        description: "Alquileres no cancelados"
      },
      carouselIndex: 0,
      items2: [
        "Imágenes",
        "Calendario",
        "Descripción",
        // "Técnico",
        "Localización"
      ],
      currentItem: "tab-Imágenes",
      attrs3: [
        {
          key: "today",
          dot: {
            color: "red",
            class: "my-dot-class"
          },
          dates: [
            new Date(2020, 1, 4), // Jan 4th
            new Date(2020, 1, 10), // Jan 10th
            new Date(2020, 1, 15) // Jan 15th
          ]
        }
      ],
      date: null,
      date2: null,
      dateFormatted: null,
      menu1: false,
      menu2: false,
      motivoblock: "",
      clickeddate: null,
      dialog3: false,
      motivo: "",
      attributeslist: [],
      firstLabel: undefined,
      secondLabel: undefined,
      thirdLabel: undefined,
      selectedMachineSecure: 0,
      machineInsuredValue: 0,
      machineInsuranceExcess: 0
    };
  },
  mounted: function() {
    //this.getop(this.$route.query.id);
    this.uuid = this.$route.query.id;
    this.getop(this.$route.query.id);
    this.getMachineSecure()
    this.useruuid = this.$store.getters.uuid;
    const _this = this;
    EventBus.$on("newAddress", addressStr => {
      _this.macdata.machineplace = addressStr;
      console.log("Address: " + addressStr);
    });

    EventBus.$on("newLocation", locatStr => {
      _this.macdata.machinelocation = locatStr;
      console.log("Address: " + locatStr);
    });
    this.getDiscountSteps();
    //  this.attrs.dates = this.blockeddays;
  },
  components: {
    GoogleMap,
    upload,
    "upload-btn": UploadButton
  },
  computed: {

    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.date2);
    },
    attrs2() {
      var cs = [];
      for (var i = 0; i < this.attributes.length; i++) {
        cs.push(this.attributes[i]);
      }
      var atts = {
        key: "today",
        dot: {
          color: "red",
          class: "my-dot-class"
        },
        dates: [
          new Date(2020, 1, 4), // Jan 4th
          new Date(2020, 1, 10), // Jan 10th
          new Date(2020, 1, 15) // Jan 15th
        ]
      };
      return atts;
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2() {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
    clickeddate(){
      const found = this.blockeddaysevents.find(element => element.blockedday === this.clickeddate);
      this.motivo = found.comment
      this.dialog3 = true
     // alert("Motivo: " + found.comment)
    },
  },
  methods: {
    getDiscountSteps() {
      this.$http.get(`${process.env.VUE_APP_BASE_URI}${'/getdiscountrangebymachinetype/'}${this.$store.state.machineType}`).then(
        (response) => {
          this.firstLabel = `Descuento ${response.data.discountrange[0].days.toString()}-${response.data.discountrange[1].days.toString()} días`
          this.secondLabel = `Descuento ${response.data.discountrange[1].days.toString()}-${response.data.discountrange[2].days.toString()} días`
          this.thirdLabel = `Descuento más de ${response.data.discountrange[2].days.toString()} días`
        },
        (error) => {
          this.isLoading2 = false;
          this.snacktext = "Error al conseguir los rangos de los descuentos";
          this.snackcolor = "error";
          this.snackresult = true;
        }
      )
    },
    saveData: function() {
      const _this = this;
      this.isLoading2 = true;

     // delete dataToSend.machineattributes;
      if (this.hasInsurance) {
        this.macdata.machineinsuranceavailable = 1;
      } else {
        this.macdata.machineinsuranceavailable = 0;
      }
      if (this.hasTransport == true) {
        this.macdata.machinetransportavailable = 1;
      } else {
        this.macdata.machinetransportavailable = 0;
      }
      for( var i = 0; i < this.attsType4.length; i++){
        for( var j = 0; j < this.macdata.machineattributes.length; j++){
          if( this.macdata.machineattributes[j].idattribute == this.attsType4[i].idx){
            if( this.attsType4[i].valuebool){
              this.macdata.machineattributes[j].attributevalueyesno = 1;
            }
            else{ 
              this.macdata.machineattributes[j].attributevalueyesno = 0;
            }
            this.macdata.machineattributes[j].attributeid= this.macdata.machineattributes[j].idattribute
          }
        }

      }
      for( var i = 0; i < this.attsType1.length; i++){
        for( var j = 0; j < this.macdata.machineattributes.length; j++){
          if( this.macdata.machineattributes[j].idattribute == this.attsType1[i].idx){
              this.macdata.machineattributes[j].attributevalue = this.attsType1[i].value ;
              this.macdata.machineattributes[j].attributevaluenum = this.attsType1[i].value ;

            this.macdata.machineattributes[j].attributeid= this.macdata.machineattributes[j].idattribute
          }
        }

      }
      for( var i = 0; i < this.attsType3.length; i++){
        for( var j = 0; j < this.macdata.machineattributes.length; j++){
          if( this.macdata.machineattributes[j].idattribute == this.attsType3[i].idx){
              this.macdata.machineattributes[j].attributelistvalue = this.attsType3[i].value ;

            this.macdata.machineattributes[j].attributeid= this.macdata.machineattributes[j].idattribute
          }
        }

      }
      var dataToSend = this.macdata;
      delete dataToSend.cultivations;
      const url = process.env.VUE_APP_BASE_URI + "/updatemachine/" + this.uuid;

      if (this.selectedMachineSecure === 0) {
        dataToSend.machineinsuranceavailable = 0
        dataToSend.insurancecompany = 0
        dataToSend.machineinsuredvalue = 0
        dataToSend.machineinsuranceexcess = 0
      } else {
        dataToSend.machineinsuranceavailable = 1
        dataToSend.insurancecompany = this.selectedMachineSecure
        dataToSend.machineinsuredvalue = this.macdata.machineinsuredvalue
        dataToSend.machineinsuranceexcess = this.macdata.machineinsuranceexcess
      } 
      _this.$http
        .post(url, dataToSend) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(function(response) {
          console.log(response);
          _this.isLoading2 = false;
          _this.snacktext = "Los datos del equipo  se han modificado";
          _this.snackcolor = "success";
          _this.snackresult = true;
        })
        .catch(function(error) {
          console.log(error);
          _this.isLoading2 = false;
          _this.snacktext = "Error al modificar los datos del equipo ";
          _this.snackcolor = "error";
          _this.snackresult = true;
        });

      var dataToSend2={
        machineid: _this.macdata.machineid,
        machineuuid: _this.uuid,
        attributelist: _this.macdata.machineattributes

      }
      const url2 = process.env.VUE_APP_BASE_URI + "/setattributestomachine" ;
      _this.$http
        .post(url2, dataToSend2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(function(response) {
          console.log(response);
          _this.isLoading2 = false;

          _this.snacktext = "Los datos del equipo  se han modificado";
          _this.snackcolor = "success";
          _this.snackresult = true;
        })
        .catch(function(error) {
          console.log(error);
          _this.isLoading2 = false;
          _this.snacktext = "Error al modificar los datos del equipo ";
          _this.snackcolor = "error";
          _this.snackresult = true;
        });


    },
    changeBrand(b) {
      let obj = this.brands.find(obj => obj.brandid == b);
      this.macdata.machinebrandname = obj.brandname;
      // alert(this.macdata.machinebrand)
    },
    getop: function(opuuid) {
      const _this = this;
      this.isLoading2 = true;
      const url = process.env.VUE_APP_BASE_URI + "/getmachinebyuuid/" + opuuid;
      _this.$http
        .get(url) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            // console.log(response);
            let needsTecnico = false
            this.isLoading2 = false;
            this.macdata = response.data.machinedata;
            if (this.macdata.machineinsuranceavailable == 1)
              this.hasInsurance = true;
            if (this.macdata.machinetransportavailable == 1)
              this.hasTransport = true;
            this.selectedMachineSecure = this.macdata.insurancecompany === null ? 0 : this.macdata.insurancecompany
            this.macdata.machineattributes.forEach(element => {
              if (element.attributetype == 1) {
                var attnew = element;
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                attnew.idx = element.idattribute;
                if (element.attributename == "Potencia") var aa = 0;
                else this.attsType1.push(attnew);
              } else if (element.attributetype == 3) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributelistvalue;
                attnew.idx = element.idattribute;
                attnew.idattributelist = element.idattributelist;
                this.attsType3.push(attnew);
                this.loadOptions(element.idattribute)
              } else if (element.attributetype == 4) {
                needsTecnico = true
                var attnew = {};
                attnew.labelx = element.attributename;
                if (element.attributevalueyesno == 0) attnew.valuebool = false;
                else attnew.valuebool = true;
                attnew.idx = element.idattribute;
                _this.attsType4.push(attnew);
              }
            });
            if (needsTecnico) {
              this.items2.splice(3, 0, 'Técnico')
            }
            if (_this.macdata.machinelocation != null) {
              EventBus.$emit("setMacAddress", _this.macdata.machinelocation);
            }

            const url2 =
              process.env.VUE_APP_BASE_URI +
              "/getbrandsbytype/" +
              _this.macdata.machinetype; //+ _this.macdata.machinetypeid;
            _this.$http
              .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
              .then(function(response) {
                console.log("" + response);
                _this.brands = response.data.brands;
              })
              .catch(function(error) {
                console.log(error);
                // this.isLoading2 = false;
              });

            _this.$http
              .get(
                process.env.VUE_APP_BASE_URI +
                  "/getphotosformachine/" +
                  response.data.machinedata.machineuuid
              )
              .then(
                function(response) {
                  console.log("" + response);
                  //  this.isLoading2 = false;
                  this.machinePhotos = response.data;
                  for (var a = 0; a < this.machinePhotos.length; a++) {
                    var newimg = {};
                    newimg.src =
                      process.env.VUE_APP_BASE_URI +
                      "/getimage/" +
                      this.machinePhotos[a].imageuuid;
                    this.images.push(newimg);
                  }
                }.bind(this)
              )
              .catch(
                function(error) {
                  console.log(error);
                  // this.isLoading2 = false;
                }.bind(this)
              );
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            this.isLoading2 = false;
          }.bind(this)
        );

      const url2 =
        process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + opuuid;
      _this.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
            this.blockeddays = response.data.blockeddays;
            var d1 = [];
            for (var i = 0; i < this.blockeddays.length; i++) {
              var d = new Date(this.blockeddays[i] + "T04:00:00");
              d1.push(d);
            }
            this.attributes = d1;

          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            // this.isLoading2 = false;
          }.bind(this)
        );

      const self = this;
      const url3 =
        process.env.VUE_APP_BASE_URI + "/getblockeddaysbyreason/" + opuuid;
      _this.$http
        .get(url3) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
            self.blockeddaysevents = response.data.blockeddays;

          }
        )
        .catch(
          function(error) {
            console.log(error);
          }.bind(this)
        );

    },
    getMachineSecure() {
      this.$http.get(process.env.VUE_APP_BASE_URI + '/getinsurancecompanies').then(
        (response) => {
          var secure = {}
          secure.idinsurancecompany = 0
          secure.insurancecompanyname = 'NINGUNO'
          response.data.insurancecompanies.splice(0, 0, secure)
          this.machineSecure = response.data.insurancecompanies
        },
        (error) => {
          this.isLoading2 = false;
          this.snacktext = "Error al conseguir los seguros de la máquina";
          this.snackcolor = "error";
          this.snackresult = true;
        }
      )
    },
    update(file) {
      var fb64 = this.getBase64(file);
    },

    getBase64(file) {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        const fb64 = reader.result;
        if (fb64 != null) {
          var imgsrc = fb64.split(",")[1];
          let url = process.env.VUE_APP_BASE_URI + "/uploadimage";

          const upinfo = {
            machineuuid: self.uuid,
            machineid: self.macdata.machineid,
            useruuid: self.useruuid,
            base64image: imgsrc
          };
          self.$http.post(url, upinfo).then(
            function(success) {
              self.isWaiting = false;
              self.snacktext = " La imagen se ha guardado  ";
              self.snackcolor = "success";
              self.snackresult = true;
              self.refreshPhotos();
            },
            function(error) {
              self.isWaiting = false;
              self.snacktext = "Error. La imagen no se ha podido guardar";
              self.snackcolor = "warning";
              self.snackresult = true;
            }
          );
        }
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
        return null;
      };
    },
    refreshPhotos() {
      const self = this;
      self.images = [];
      self.$http
        .get(process.env.VUE_APP_BASE_URI + "/getphotosformachine/" + self.uuid)
        .then(function(response) {
          console.log("" + response);
          //  this.isLoading2 = false;
          self.machinePhotos = response.data;
          for (var a = 0; a < self.machinePhotos.length; a++) {
            var newimg = {};
            newimg.src =
              process.env.VUE_APP_BASE_URI +
              "/getimage/" +
              self.machinePhotos[a].imageuuid;
            self.images.push(newimg);
          }
        })
        .catch(function(error) {
          console.log(error);
          // this.isLoading2 = false;
        });
    },
    deletePhoto() {
      const self = this;
      self.$http
        .get(
          process.env.VUE_APP_BASE_URI +
            "/deleteimagebyuuid/" +
            self.machinePhotos[self.carouselIndex].imageuuid
        )
        .then(function(response) {
          console.log("" + response);
          //  this.isLoading2 = false;
          self.isWaiting = false;
          self.snacktext = " La imagen   se ha eliminado  ";
          self.snackcolor = "success";
          self.snackresult = true;
          self.refreshPhotos();
        })
        .catch(function(error) {
          console.log(error);
          self.isWaiting = false;
          self.snacktext = "Error. La imagen  no se ha podido  eliminar";
          self.snackcolor = "warning";
          self.snackresult = true;
        });
    },
    mainPhoto() {
      const self = this;
      self.$http
        .get(
          process.env.VUE_APP_BASE_URI +
            "/setfrontimage/" +
            self.machinePhotos[self.carouselIndex].imageuuid
        )
        .then(function(response) {
          console.log("" + response);
          //  this.isLoading2 = false;
          self.isWaiting = false;
          self.snacktext = " La imagen se ha marcado como principal  ";
          self.snackcolor = "success";
          self.snackresult = true;
          self.refreshPhotos();
        })
        .catch(function(error) {
          console.log(error);
          self.isWaiting = false;
          self.snacktext =
            "Error. La imagen  no se ha podido marcar como principal";
          self.snackcolor = "warning";
          self.snackresult = true;
        });
    },

    blockDates() {
      const self = this;
      self.images = [];
      const upinfo = {
        machineuuid: self.uuid,
        dayfrom: self.date,
        dayto: self.date2,
        comment: self.motivoblock
      };

      self.$http
        .post(process.env.VUE_APP_BASE_URI + "/blockdays", upinfo)
        .then(function(response) {
          console.log("" + response);
          self.snacktext = " Las fechas se han bloqueado  ";
          self.snackcolor = "success";
          self.snackresult = true;
          const url2 =
            process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + self.uuid;
          self.$http
            .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
            .then(function(response) {
              console.log("" + response);
              //  this.isLoading2 = false;
              self.blockeddays = response.data.blockeddays;
            })
            .catch(function(error) {
              console.log(error);
              // this.isLoading2 = false;
            });
        })
        .catch(function(error) {
          console.log(error);
          self.snacktext = "Error. No se han podido bloquear las fechas";
          self.snackcolor = "warning";
          self.snackresult = true;
        });
    },
    unblockDates() {
      const self = this;
      self.images = [];
      const upinfo = {
        machineuuid: self.uuid,
        dayfrom: self.date,
        dayto: self.date2
      };

      self.$http
        .post(process.env.VUE_APP_BASE_URI + "/unblockdays", upinfo)
        .then(function(response) {
          console.log("" + response);
          self.snacktext = " Las fechas se han desbloqueado  ";
          self.snackcolor = "success";
          self.snackresult = true;
          const url2 =
            process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + self.uuid;
          self.$http
            .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
            .then(function(response) {
              console.log("" + response);
              //  this.isLoading2 = false;
              self.blockeddays = response.data.blockeddays;
            })
            .catch(function(error) {
              console.log(error);
              // this.isLoading2 = false;
            });
        })
        .catch(function(error) {
          console.log(error);
          self.snacktext = "Error. No se han podido bdesloquear las fechas";
          self.snackcolor = "warning";
          self.snackresult = true;
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

   loadOptions( idattribute) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getoptionlistvalues/" + idattribute;
      self.$http.get(url).then(
        function(success) {
          self.attributeslist[idattribute] = success.data.optionvalues;

        },
        function(error) {
          self.isLoading = false;

          self.snacktext = "Error al obtener lista de usuarios";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },   
     
  }
};
</script>
<style lang="stylus">
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#example-custom-transition {
  .fade {
    &-enter-active, &-leave-active, &-leave-to {
      transition: 0.3s ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }
}

.custom-checkbox {
  height: 20px;
  font-size: 12;
  margin-left: 5px;
}

.custom-machineatt {
  height: 60px;
  font-size: 12;
  margin-left: 15px;
}

.v-label {
  font-size: 14px;
  font-color: black;
}
</style>

import { render, staticRenderFns } from "./SectionDef.vue?vue&type=template&id=0be6b27e&scoped=true&"
import script from "./SectionDef.vue?vue&type=script&lang=js&"
export * from "./SectionDef.vue?vue&type=script&lang=js&"
import style0 from "./SectionDef.vue?vue&type=style&index=0&id=0be6b27e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be6b27e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VContainer,VIcon,VImg,VSpacer,VToolbar,VToolbarTitle})

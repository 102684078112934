<template>
  <v-container
    id="input-usage"
    grid-list-xl
    fluid
  >

        <section-definition :title="page.title" :breadcrumbs="breadcrumbs"></section-definition>

    <v-layout wrap>


       <v-flex xs12>
            <v-select
                :items="userslist"
                label="Seleccionar concesión"
                item-text="useralias"
                item-value="iduser"
                ></v-select>
            </v-flex>

        <v-flex
          xs12
          md12
        >
          <v-text-field
            v-model="amount"
            :rules="nameRules"
            type="number"
            :counter="50"
            label="Importe"
            required
          ></v-text-field>
        </v-flex>




        <v-flex
          xs12
          md12
        >
          <v-text-field
            v-model="concepto"
            :rules="nameRules"
            :counter="100"
            label="Concepto"
            required
          ></v-text-field>
        </v-flex> 

 


  

+



 

   <v-dialog v-model="isSaveUser" persistent max-width="500px">
      <v-btn slot="activator" color="act" round  dark bold outline  class="bold btnfixed">Emitir cobro</v-btn>
      <v-card>
        <v-card-title>
          <span class="headline">Confirmar cobro</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

 
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" round  dark bold outline  class="bold btnfixed" @click.native="isSaveUser = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" round  dark bold outline  class="bold btnfixed" @click.native="confirmSave">Emitir Cobro</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-flex>

   
    </v-layout>

   <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top=true
    >
      {{ snacktext }}
      <v-btn
        round  dark bold outline  class="bold btnfixed"
        @click="snackresult = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
   </v-container>     


</template>

<script>
import upload from '../../components/upload.vue'
import password from '../../components/modals/password.vue'
import saveUserConfirm from '../../components/modals/saveUserConfirm.vue'
import { EventBus } from '../../eventBus.js'
import pdf from 'vue-pdf'

export default {
  name: 'user',
  components: {
    upload,
    password,
    saveUserConfirm,
    pdf
  },
  data () {
    return {
      userData: {},
      endPointImages: process.env.VUE_APP_BASE_URI + '/getimagebypathbase64/',
      endPointAvatar: String,
      endPointIdFront: String,
      endPointIdBack: String,
      endPointDriverLicenseFront: String,
      endPointDriverLicenseBack: String,
      avatarImg: false,
      idFrontImg: false,
      idBackImg: false,
      driverLicenseFrontImg: false,
      driverLicenseBackImg: false,
      isChangePassword: false,
      isSaveUser: false,
      labels: { message: this.$t('modalConfirmSaveUserText'), success: this.$t('userSuccessEdit'), error: this.$t('userErrorEdit') ,
                leftButton: this.$t('passwordExit'), rigthButton: this.$t('modalConfirm') },
      endPoint: process.env.VUE_APP_BASE_URI + '/modifyuser/' + this.$store.getters.uuid,
      imagetype: String,
      userslist: [],
      pagecounts: [],
      amount: null,
      concepto: null,
      passw1: "",
      passw2: "",
      snackresult: false,
      snacktext: "",
      snackcolor: "success",
      page: {
        title: "Emitir cobro",
        headline: "Emitir cobro por adeudo",
        description: `.`
      },
      breadcrumbs: [
        {
          text: "Admin",
          disabled: false
        },
        {
          text: "Paginas",
          disabled: false
        },
        {
          text: "Emitir cobro",
          disabled: false
        }
      ] 
    }
  },
  mounted () {
    EventBus.$on('upload', imageObject => {
        this.checkImageType(imageObject.imagetype, imageObject.image)
    });
    this.getUserPremiumList();
  },
  methods: {
    getUserPremiumList() {
      const self = this
      let url = process.env.VUE_APP_BASE_URI + '/getuserlist'
      self.isLoading=true
      self.$http.get(url).then(
          function(success) {
            var userlisttemp = success.data.users
            self._.forEach(userlisttemp, function(currentitem, index) {
              userlisttemp[index].usercreationdate = self.moment(currentitem.usercreationdate).format('DD-MM-YYYY').split('T')[0];
              if (currentitem.sepaallowed === 1) {
                userlisttemp[index].sepaallowedlabel="SI";
              } else {
                userlisttemp[index].sepaallowedlabel="NO";
              }
              if( currentitem.usertype === 1){
                userlisttemp[index].usertypelabel="Normal";
              }
              if( currentitem.usertype === 2){
                userlisttemp[index].usertypelabel="Premium ";
                self.userslist.push(currentitem);
              } else if(currentitem.usertype === 3) {
                userlisttemp[index].usertypelabel="Transportista";
              } else if (currentitem.usertype === 5) {
                userlisttemp[index].usertypelabel="Administrador";
              } else {
                userlisttemp[index].usertypelabel="---";
              }
            }
          );
          self.isLoading = false
        },
        function() {
          self.isLoading = false
          self.$toast.open({
            duration: 5000,
            message: self.$t('myMachinesErrorGetMachines'),
            type: 'is-danger'
          })
        }
      )
    },
    getSepadocs(uuid) {
      const self = this
      let url = process.env.VUE_APP_BASE_URI + '/getsepadocumentbyuseruuid/' + uuid
        self.$http.get(url).then(
          function(response) {
          var docs = response.data.info.documents
          for( var i = 0; i < docs.length; i++){
            if( docs[i].documenttype == 1){
              docs[i].documenttypelabel="ANVERSO DNI"
            } else if( docs[i].documenttype == 2){
              docs[i].documenttypelabel="REVERSO DNI"
            } else if( docs[i].documenttype == 3){
              docs[i].documenttypelabel="CERIFICADO CUENTA"
            } else if( docs[i].documenttype == 5){
              docs[i].documenttypelabel="RECIBO AUTONOMOS"
            } else if( docs[i].documenttype == 11){
              docs[i].documenttypelabel="CIF"
            } else if (docs[i].documenttype == 12){
              docs[i].documenttypelabel="ESCRITURAS"
            } else { 
              docs[i].documenttypelabel="OTRO"
            }
            if (docs[i].documentpath.indexOf('.pdf') > 0 ){
              docs[i].isImage=false
            } else {
              docs[i].isImage = true
            }
            self.downloadImage( docs[i].documentpath, i)  
            docs[i].b64img="XXXXXX"
            self.sepadocs.push( docs[i]) 
          }
        },
        function(error) {
          console.log(error)
        }
      ) 
    },        
    editUser () {
      this.isSaveUser = true
    },
    changePassw () {
      if (this.passw1.length > 5 && this.passw1 == this.passw2) {
        const self = this
        let url = process.env.VUE_APP_BASE_URI + '/changepassword/' + this.userData.useruuid
        var bodyuser = { "newpassword": this.passw1 }
        self.$http.post(url, bodyuser).then(
          function() {
            self.isChangePassword = false
            self.snacktext="La contraseña se ha modificado";
            self.snackcolor="success";
            self.snackresult =true;
          },
          function() {
            self.isChangePassword = false
            self.snacktext="Error. La contraseña  no se ha modificado";
            self.snackcolor="warning";
            self.snackresult =true;
          }
        )
      } else {
        this.snacktext="Las contraseñas  no coinciden o son demasiado cortas ";
        this.snackcolor="red";
        this.snackresult =true;
      }
    },
    downloadImage (imagepath, cont) {
      const self = this
      let url = process.env.VUE_APP_BASE_URI + '/downloadimagefrompath' 
      var bodyuser = { "imagepath": imagepath }
      self.$http.post(url, bodyuser,{
      responseType: 'arraybuffer'
      }).then(
        function(success) {
          var b64 = new Buffer(success.data, 'binary').toString('base64')
          var imgsrc = "data:image/jpeg;base64," + b64;
          self.sepadocs[cont].b64img = imgsrc
        },
        function() {
          self.snacktext="Error. La imagen  no se ha podido  recuperar";
          self.snackcolor="warning";
          self.snackresult =true;
        }
      )
    },
    confirmSave () {
      const self = this
      let url = process.env.VUE_APP_BASE_URI + '/modifyuser/' + this.userData.useruuid
      self.$http.post(url, self.userData).then(
        function() {
          self.isSaveUser = false
          self.snacktext="Los datos de usuario se han modificado modificado";
          self.snackcolor="success";
          self.snackresult =true;
        },
        function() {
          self.isSaveUser = false
          self.snacktext="Error. Los datos  no se han modificado";
          self.snackcolor="red";
          self.snackresult =true;
        }
      )
    }
  }
}
</script>

<style scoped>
.mtop31 {
    margin-top: 31px;
}
.mtop40 {
    margin-top: 40px;
}
.mtop-30 {
    margin-top: -30px;
}
.icon-size {
    font-size: 18pt;
}
.float-right {
    float: right
}
</style>

<template>
    <section>
        <div class="modal-card">
            <div class="modal-card-body">
                <p class="title has-text-centered">{{ $t('passwordChangePassText') }}</p>
                <div class="columns">
                    <div class="column">
                        <b-field :label="$t('passwordNewPass')" :type="errors.has('password') ? 'is-danger' : 'is-success'">
                            <b-input id="password" type="password" v-validate.initial="'required|samepass'" @keyup.native.delete="samePass" name="password" v-model="newPass" password-reveal></b-input>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field :label="$t('passwordRepeatNewPass')" :type="errors.has('newpassword') ? 'is-danger' : 'is-success'">
                            <b-input id="newpassword" type="password" v-validate.initial="'required|samepass'" @keyup.native.delete="samePass" name="newpassword" v-model="repeatedNewPass" password-reveal></b-input>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <button id="changepass" class="button is-primary float-right" type="button" @click.once="changePassword" disabled="true">{{ $t('passwordChange') }}</button>
                        <button class="button is-accent float-right mRight10" @click="$parent.close()">{{ $t('passwordExit') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'password',
    props: ['userData'],
    data () {
        return {
            newPass: '',
            repeatedNewPass: '',
            canChangePassword: false
        }
    },
    created() {
        this.$validator.extend('samepass', {
            validate: (value) => {
                this.doValidation()
            }
        });
    },
    methods : {
        doValidation () {
            if (this.newPass !== '' && this.repeatedNewPass !== '' && this.newPass.localeCompare(this.repeatedNewPass) === 0) {
                document.getElementById("password").classList.remove('is-danger')
                document.getElementById("newpassword").classList.remove('is-danger')
                document.getElementById('password').classList.add('is-success')
                document.getElementById('newpassword').classList.add('is-success')
                document.getElementById('changepass').disabled = false
                return true;
            } else {
                document.getElementById("password").classList.remove('is-success')
                document.getElementById("newpassword").classList.remove('is-success')
                document.getElementById('password').classList.add('is-danger')
                document.getElementById('newpassword').classList.add('is-danger')
                document.getElementById('changepass').disabled = true
                return false;
            }
        },
        samePass () {
            this.doValidation()
        },
        changePassword () {
            const self = this
            let url = process.env.VUE_APP_BASE_URI + '/changepassword/' + this.$store.getters.uuid
            self.$http.post(url, { newpassword: self.repeatedNewPass }).then(
                function(response) {
                    self.$toast.open({
                        duration: 5000,
                        message: self.$t('passwordChangeSuccess'),
                        type: 'is-success'
                    })
                    self.$parent.close()
                },
                function(error) {
                    self.$toast.open({
                        duration: 5000,
                        message: self.$t('passwordChangeError'),
                        type: 'is-danger'
                    })
                }
            )
        }
    }
}
</script>


<style>
.float-right {
    float: right;
}
.mRight10 {
    margin-right: 10px;
}
.title {
    margin-bottom: 30px;
    font-size: 14pt;
    font-weight: bold;
}
.modal-card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>

<template>
  <div>
    <section-definition
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    ></section-definition>

    <v-card>
      <v-card-title>
         <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
          <v-btn color="act" class="text--white green bold btnfixed" round dark bold outline @click="gotoNewTicket()">
            Reportar Incidencia
          </v-btn>
        </v-flex>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar.."
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tickets2"
        :search="search"
        :loading="isLoading"
        rows-per-page-text="Filas por página"
        :rows-per-page-items="[15, 30, 50, { text: 'todas', value: -1 }]"
        class="elevation-1"
        dense
      >
        <v-progress-linear
          slot="progress"
          color="blue"
          indeterminate
        ></v-progress-linear>

        <template slot="items" slot-scope="props">
          <td class="text-xs-center">{{ props.item.tickettype }}</td>
          <td class="text-xs-center">{{ props.item.ticketcreationdate }}</td>
          <td :class="props.item.statusclass">{{ props.item.statuslabel }}</td>
          <td class="text-xs-center">{{ props.item.ticketname }}</td>
          <td class="text-xs-center">{{ props.item.ticketdescription }}</td>
          <td class="text-xs-center">{{ props.item.id }}</td>
          <td>
            <v-icon class="mr-2" @click="gotoDetail(props.item.id)">
              visibility
            </v-icon>
          </td>
        </template>
        <template slot="pageText" slot-scope="props">
          Filas {{ props.pageStart }} - {{ props.pageStop }} de
          {{ props.itemsLength }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          La búsqueda de "{{ search }}" no ha encontrado resultados.
        </v-alert>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top="true"
    >
      {{ snacktext }}
      <v-btn dark flat @click="snackresult = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { rgba as Gradients } from "@/data/gradients";
import moment from "moment";

export default {
  name: "app",
  data() {
    return {
      chatMessageEditor: null,
      tickets: [],
      messages: [],
      newReptile: "",
      currentGroup: {},
      title: "Mis Incidencias",
      gradientsList: Gradients,
      gradient: Gradients[9],
      chatGradient: Gradients[5],
      backgroundImg: "/static/doc-images/HexesisMaterial03.png",
      activeGroupId: 1,
      curreentAvatar: "",
      ownerAvatar: "",
      ownerUuid: "",
      currentChatroom: "",
      owneralias: "",
      search: this.$store.state.ticketListSearch,
      headers: [
        { text: "Tipo", value: "tickettype" },
        { text: "Fecha", value: "ticketcreationdate" },
        { text: "Estado ", value: "statuslabel" },
        { text: "Asunto", value: "ticketname" },
        { text: "Descripción", value: "ticketdescription" },
        { text: "TicketID", value: "id" },
        { text: "Acciones ", value: "" }
      ],
      page: {
        title: "Incidencias",
        headline: "Lista de equipos",
        description: "Equipos activos"
      }
    };
  },
  firestore() {
    return {
      //      tickets: db.collection(
      //        "usertickets/" + this.$store.getters.uuid + "/tickets"
      //      ).where('creatoruuid', '==', this.$store.getters.uuid).orderBy("id")
      tickets: db
        .collection("tickets")
        .where("creatoruuid", "==", this.$store.getters.uuid)
        .orderBy("id")
    };
  },
  watch: {
    search() {
      this.$store.commit('setTicketListSearch', this.search);
    }
  },
  mounted: function() {
    this.ownerUuid = this.$store.getters.uuid;
    this.owneralias = this.$store.getters.useralias;
    this.ownerAvatar = process.env.VUE_APP_BASE_URI + "/getavatar/" + this.ownerUuid;
    //this.chats = db.collection("users/"+ this.ownerUuid+"/userrooms")
  },
  components: {},
  computed: {
    tickets2() {
      var cs = [];
      for (var i = 0; i < this.tickets.length; i++) {
        cs.push(this.tickets[i]);

        if (cs[i].ticketstatus === 1) {
          cs[i].statuslabel = "PENDIENTE";
        }
        if (cs[i].ticketstatus == 2) cs[i].statuslabel = "ATENDIDO";
        if (cs[i].ticketstatus == 3) cs[i].statuslabel = "RESUELTO";
      }
      return cs;
    }
  },
  methods: {
    showconversation: function(currentchat) {
      var hh = currentchat[".key"];
      this.currentChatroom = hh;
      hh = hh.replace(this.ownerUuid, "");
      hh = hh.replace("_", "");
      this.currentGroup = {};
      this.currentGroup.name = currentchat.useralias;
      this.currentGroup.uuid = hh;
      this.currentAvatar = process.env.VUE_APP_BASE_URI + "/getavatar/" + hh;
      this.$binding(
        "messages",
        db.collection("chats/" + currentchat[".key"] + "/messages")
      ).then(messages => {
        console.log(messages); // => __ob__: Observer
      });
    },
    switchGroup: function(currentchat) {
      var hh = currentchat[".key"];
      this.currentChatroom = hh;
      hh = hh.replace(this.ownerUuid, "");
      hh = hh.replace("_", "");
      this.currentGroup = {};
      this.currentGroup.name = currentchat.useralias;
      this.currentGroup.uuid = hh;
      this.currentAvatar = process.env.VUE_APP_BASE_URI + "/getavatar/" + hh;
      this.$binding(
        "messages",
        db.collection("chats/" + currentchat[".key"] + "/messages")
      ).then(messages => {
        console.log(messages); // => __ob__: Observer
      });
    },
    gotoDetail(uuid) {
      this.$store.commit('setTicketUuid', uuid)
      this.$router.push({ name: "pages/TicketManagePage" });
    },
    gotoNewTicket: function() {
      this.$router.push({
        name: "pages/CreateTicketPage"
      
      });
    },    
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.reptileList {
  list-style: none;
}
.toobar-extension {
  margin-left: 0 !important;
  min-width: 300px;
}
.state1 {
  background-color: salmon;
  color: #ffffff;
}

.state2 {
  background-color: green;
  color: #ffffff;
}

.state3 {
  background-color: red;
  color: #ffffff;
}
</style>

<template>
  <v-img :src="backgroundImg" class="page-vimg">
    <v-container fill-height pa-0>
      <v-layout align-center>
        <v-flex xs12>
          <v-card
            class="text-xs-center margin-auto border-radius6 box-white-500-glow elevation-10 auth-box"
          >
            <v-layout align-center justify-center row fill-height wrap>
              <v-flex xs12 class="text-xs-center mt-3">
                <img
                  src="/static/ploou_icon.9.png"
                  alt="Vuse"
                  class="text-xs-center"
                  height="100"
                />
                <div class="headline">Accede a tu cuenta</div>
                <v-form
                  @submit.prevent="$v.$invalid ? null : submit()"
                  ref="form"
                >
                  <v-layout wrap row pa-4>
                    <v-flex xs12 pa-0>
                      <v-text-field
                        color="primary"
                        label="Email"
                        v-model="form.email"
                        required
                        :error-messages="fieldErrors('form.email')"
                        @blur="$v.form.email.$touch()"
                      ></v-text-field>

                      <v-text-field
                        color="primary"
                        label="Contraseña"
                        v-model="form.password"
                        type="password"
                        required
                        :error-messages="fieldErrors('form.password')"
                        @blur="$v.form.password.$touch()"
                      ></v-text-field>
                      <div class="width-150x margin-horiz-center">
                        <v-checkbox
                          color="primary"
                          v-model="form.remeberme"
                          required
                        >
                          <div slot="label" @click.stop="() => {}">
                            Recuerdame
                          </div>
                        </v-checkbox>
                      </div>
                    </v-flex>

                    <v-flex xs12>
                      <v-layout row wrap text-xs-center>
                        <!-- Login form submit -->
                        <v-flex xs12 class="no-mrpd">
                          <v-btn
                            color="act"
                            type="submit"
                            :disabled="$v.$invalid"
                            block dark
                            :class="$v.$invalid ? '' : 'white--text'"
                            >Entrar</v-btn
                          >
                        </v-flex>
                        <!-- Forgot password -->
                        <v-flex xs12>
                          <router-link
                            :to="{ name: 'pages/authentication/login' }"
                            tag="div"
                            class="grey--text cursor-pointer"
                          >
                            <strong>Has olvidado la contraseña ?</strong>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar v-model="snackbar" absolute top right :color="snackbarcolor">
      <span>{{ snackbartext }}</span>
      <v-icon dark>check_circle</v-icon>
    </v-snackbar>
  </v-img>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("userservices");

const defaultForm = {
  email: "",
  password: "",
  remeberme: false
};
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email },
      password: { required, minLength: minLength(1) }
    }
  },
  validationMessages: {
    form: {
      email: {
        required: "Por favor pon tu email",
        email: "Email debe ser válido"
      },
      password: {
        required: "Por favor, pon tu conatraseña ",
        minLength: "La contaseña debe tener  3 caracteres"
      }
    }
  },
  data() {
    return {
      form: Object.assign({}, defaultForm),
      snackbar: false,
      snackbarcolor: "success",
      snackbartext: "",
      backgroundImg: "/static/doc-images/HexesisMaterial01.png"
    };
  },
  components: {},
  methods: {
    submit() {
      var payload = {};
      payload.userName = this.form.email;
      payload.password = this.form.password;
      const _this = this;

      const { data } = UserRepository.login(payload)
        .then(function(success) {
          _this.$store.commit("isLogin", false);
          _this.$store.commit("setToken", success.data);
          if (_this.$store.getters.role != 2 ) {
            _this.loginError = true;
            _this.$store.commit("setValiduser", "");
            _this.$store.commit("setValidpassword", "");
            _this.snackbar = true;
            _this.snackbarcolor = "error";
            _this.snackbartext =
              "Es necesario ser usuario Premium para acceder al portal";
            return;
          }
          _this.$store.commit("setValiduser", _this.form.email);
          _this.$store.commit("setValidpassword", _this.form.validpassword);
          //this.$router.push({path: '/myMachines'})
          //his.posts = data;
          _this.$router.push({
            name: "pages/PremiumDetail"
          });
          _this.snackbar = true;
          _this.resetForm();
          _this.$v.$reset();
        })
        .catch(function(error) {
          _this.loginError = true;
          _this.$store.commit("setValiduser", "");
          _this.$store.commit("setValidpassword", "");
          _this.snackbar = true;
          _this.snackbarcolor = "error";
          _this.snackbartext = "Usuario o contraseña incorrectos";
        });
    },
    resetForm() {
      this.form = Object.assign({}, defaultForm);
      this.$refs.form.reset();
    },
    initAxios() {}
  }
};
</script>

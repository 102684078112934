export const TOGGLE_CLIPPED = "TOGGLE_CLIPPED";
export const TOOLBAR_LEFT_CLIPPED = "TOOLBAR_LEFT_CLIPPED";
export const TOGGLE_DRAWER_VISIBILITY = "TOGGLE_DRAWER_VISIBILITY";
export const TOGGLE_MINI_VARIANT = "TOGGLE_MINI_VARIANT";
export const TOGGLE_FIXED_FOOTER = "TOGGLE_FIXED_FOOTER";
export const SIDENAV_DRAWER_POSITION = "SIDENAV_DRAWER_POSITION";
export const TOGGLE_FIXED_TOOLBAR = "TOGGLE_FIXED_TOOLBAR";
export const TOGGLE_TOOLBAR = "TOGGLE_TOOLBAR";
export const TOGGLE_FOOTER = "TOGGLE_FOOTER";
export const NAV_DRAWER_ALPHA_MOD = "NAV_DRAWER_ALPHA_MOD";
export const TOGGLE_MINIVARIENT_MODE = "TOGGLE_MINIVARIENT_MODE";
export const SET_DRAWER_ALPHA_IMG = "SET_DRAWER_ALPHA_IMG";

export const NAVIGATION_DRAWER_SCHEME = "NAVIGATION_DRAWER_SCHEME";
export const NAVIGATION_TOOLBAR_SCHEME = "NAVIGATION_TOOLBAR_SCHEME";
export const FOOTER_SCHEME = "FOOTER_SCHEME";
export const APP_COLOR_SCHEME = "APP_COLOR_SCHEME";
export const NAV_DRAWER_VARIANT_MOD = "NAV_DRAWER_VARIANT_MOD";

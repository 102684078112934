import colors from "vuetify/es5/util/colors";
export default [
  {
    name: "Default-light",
    dark: false,
    scheme: "md-deep-purple-a700-scheme",
    primary: colors.deepPurple.base
  },
  {
    name: "Blue-light",
    dark: false,
    scheme: "md-blue-500-scheme",
    primary: colors.blue.base
  },
  {
    name: "Yellow-light",
    dark: false,
    scheme: "md-amber-a700-scheme",
    primary: colors.amber.darken1
  },
  {
    name: "Blue-Grey-dark",
    dark: true,
    scheme: "md-blue-grey-500-scheme",
    primary: colors.blueGrey.base
  },
  {
    name: "Pink-dark",
    dark: true,
    scheme: "md-pink-500-scheme",
    primary: colors.pink.base
  },
  {
    name: "Pink-dark",
    dark: false,
    scheme: "md-cyan-a700-scheme",
    primary: colors.cyan.base
  }
];

import Vuse from "./vuse";
import validation from "./validation";
import messages from "./messages";
import labels from "./labels";
export default {
  Vuse,
  validation,
  messages,
  labels
};

<template>
  <v-card class="elevation-4 border-radius6">
    <v-toolbar card dense color="transparent">
      <v-toolbar-title><h4>Alquileres</h4></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <template>
        <v-data-table style="max-height: 300px"
          :headers="headers"
          :items="items"
          hide-actions
          class="scroll-y elevation-0 table-striped border-radius6 plain-order-table"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.idrent }}</td>
            <td class="text-xs-left">{{ props.item.rentfinalprice }}</td>
            <td class="text-xs-left">{{ props.item.rentfrom }}</td>
            <td class="text-xs-left">{{ props.item.rentto }}</td>
            <td class="text-xs-left"><v-chip label small :class="props.item.rentstatuscolor" text-color="white" >{{ props.item.rentstatuslabel }}</v-chip></td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import items from "@/data/widgets/order";
import {EventBus} from '../../../eventBus';

export default {
  data() {
    return {
      headers: [
        {
          ID: "#",
          align: "left",
          sortable: true,
          value: "idrent"
        },
        { text: "Precio(€)", value: "rentprice" },
        { text: "Desde", value: "rentfrom" },
        { text: "Hasta", value: "rentto" },
        { text: "Estado", value: "rentstatuslabel" }
      ],
      items: null,
      statuscolors:["box-blue-500-glow","box-blue-500-glow","box-blue-500-glow", "box-green-500-glow", 
              "box-green-500-glow","box-green-500-glow","box-yellow-500-glow","box-orange-500-glow","box-green-500-glow","box-green-500-glow",
              "box-gray-500-glow", "box-red-500-glow"],
      statuslabels:["Solicitado", "Solicitado","Aprobado","Firmado", "Firmado","Firmado","Entrega", "Recogida","Liquidación", "Finalizado","Cancelado"],


      colors: {
        processing: "box-blue-500-glow",
        sent: "box-red-500-glow",
        "in-transit": "box-orange-500-glow",
        delivered: "box-green-500-glow"
      }
    };
  },
  mounted(){
        const self = this;
        EventBus.$on('setPremiumRents', rents => {

          
          for( var i = 0; i < rents.length; i++){
            if( rents[i].rentstatus != null ){
              rents[i].rentstatuslabel = self.statuslabels[rents[i].rentstatus]
              rents[i].rentstatuscolor = self.statuscolors[rents[i].rentstatus]
            }
          }
          this.items=rents;
       });

  },
  methods: {
    getColorByStatus(status) {
      return this.colors[status];
    }
  }
};
</script>
<style>
.plain-order-table .v-table thead tr {
  height: 48px;
}
</style>

import Repository from "./Repository";

const resource = "/posts";
export default {
  get() {
    return Repository.get(`${resource}`);
  },

  getPost(postId) {
    return Repository.get(`${resource}/${postId}`);
  },

  login(payload) {
    const pathres ='login';
    return Repository.post(`${pathres}`, payload);
  }
};
<template>
  <div>
    <section-definition :title="page.title" :breadcrumbs="breadcrumbs"></section-definition>
    <v-container fluid grid-list-xl page>
      <v-layout row wrap>

      </v-layout>
      <!-- Your contet -->
    </v-container>
    <v-container fluid grid-list-xl page>
      <v-layout row wrap>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Buscar.." single-line></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="userslist"
            :search="search"
            :loading="isLoading"
            class="elevation-1 fixed-header"
            rows-per-page-text="Filas por página"
            :rows-per-page-items="[15,30,50,{text:'todas', value:-1}]"
          >
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

            <template slot="items" slot-scope="props">
              <td>{{ props.item.useralias }}</td>
              <td>{{ props.item.iduser }}</td>
              <td class="text-xs-right">{{ props.item.useridentitydocument }}</td>
              <td class="text-xs-right">{{ props.item.username }}</td>
              <td class="text-xs-right">{{ props.item.usersurname1 }}</td>
              <td class="text-xs-right">{{ props.item.usersurname2 }}</td>
              <td class="text-xs-right">{{ props.item.usertypelabel }}</td>
              <td :class="props.item.stateclass">{{ props.item.userstatuslabel }}</td>
              <td :class="props.item.stateclass">{{ props.item.sepaallowedlabel }}</td>
              <td class="text-xs-right">{{ props.item.userphone }}</td>
              <td class="text-xs-right">{{ props.item.usercreationdate }}</td>
              <td>
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(props.item.useruuid, props.item.userstatus, props.item)"
                >edit</v-icon>
              </td>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >La búsqueda de "{{ search }}" no ha encontrado resultados.</v-alert>
          </v-data-table>
        </v-card>
      </v-layout>
    </v-container>

    <v-dialog v-model="isActivateUser" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Activar Usuario</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <span>Es necesario activar el usuario. Para activar al usuario se necesita el código enviado a su teléfono móvil</span>
              </v-flex>

              <v-flex xs12>
                <v-text-field v-model="activCode" label="Código" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click.native="isActivateUser = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" @click.native="activateUser">Activar</v-btn>
          <v-btn color="blue darken-1" @click.native="resendCode">Reenviar código</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackresult" :color="snackcolor" :timeout="6000" top="true">
      {{ snacktext }}
      <v-btn dark flat @click="snackresult = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
/*
amountpayed: 900
idpaymentstatus: 2
idrent: 488
machinename: "Ter"
owneralias: "Marta12"
paymentamount: 900
paymentdata: "959999/018687897529827156149505050702"
paymentdate: "Mon, 05 Aug 2019 00:00:00 GMT"
paymenttype: 1
rentfrom: "Tue, 20 Aug 2019 00:00:00 GMT"
rentto: "Wed, 28 Aug 2019 00:00:00 GMT"
transactiondate: "Fri, 19 Jul 2019 11:48:26 GMT"
useralias: "Marta12"
*/
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import PlainTableOrder from "@/components/Widgets/list/PlainTableOrder";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const PaymentsRepository = RepositoryFactory.get("payments");

export default {
  mixins: [validationMixin],

  data() {
    return {
      search: this.$store.state.userListSearch,
      headers: [
        { text: "Usuario", value: "useralias" },
        { text: "ID", value: "iduser" },
        { text: "NIF", value: "useridentitydocument" },
        { text: "Nombre", value: "username" },
        { text: "Apellido 1 ", value: "usersurname1" },
        { text: "Apellido 2", value: "usersurname2" },
        { text: "Tipo", value: "usertype" },
        { text: "Estado", value: "userstatus" },
        { text: "SEPA", value: "sepaallowed" },
        { text: "Movil", value: "userphone" },
        { text: "Fecha creación", value: "usercreationdate" },
        { text: "Acciones ", value: "" }
      ],
      userslist: [],
      currentUser: null,
      isLoading: false,
      isActivateUser: false,
      activCode: "",
      snackbar: false,
      page: {
        title: "Mis Clientes",
        headline: "Listado de Usuarios",
        description: `Usuarios registrados en el sistema.`
      },
      breadcrumbs: [

        {
          text: "Clientes registrados",
          disabled: false
        }
      ],
      snackresult: false,
      snacktext: "",
      snackcolor: "success"
    };
  },
  components: {
    PlainTableOrder
  },
  watch: {
    search() {
      this.$store.commit('setUserListSearch', this.search);
    }
  },
  mounted: function() {
    this.loadData();
    console.log("mounted: got here");
  },
  methods: {
    editItem(useruuid, userstatus, currentItem) {
      this.userData = currentItem
      if (userstatus != 2) {
        this.isActivateUser = true
      } else {
        this.$store.commit('setUserUuid', useruuid)
        this.$store.commit('setUserId', currentItem.iduser)
        this.$router.push({ name: "pages/User" })
      }
    },
    loadData() {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getuserlistforpremium";
      self.isLoading = true;
      self.$http.get(url).then(
        function(success) {
          var userlisttemp = success.data.users;
          self._.forEach(userlisttemp, function(currentitem, index) {
            userlisttemp[index].usercreationdate = self
              .moment(currentitem.usercreationdate)
              .format("DD-MM-YYYY")
              .split("T")[0];
            if (currentitem.usertype === 1) {
              userlisttemp[index].usertypelabel = "Normal";
            } else if (currentitem.usertype === 2) {
              userlisttemp[index].usertypelabel = "Premium ";
            } else if (currentitem.usertype === 3) {
              userlisttemp[index].usertypelabel = "Transportista";
            } else if (currentitem.usertype === 5) {
              userlisttemp[index].usertypelabel = "Administrador";
            } else {
              userlisttemp[index].usertypelabel = "---";
            }

            if (currentitem.userstatus === 1) {
              userlisttemp[index].userstatuslabel = "En registro";
            } else if (currentitem.userstatus === 2) {
              userlisttemp[index].userstatuslabel = "Activo ";
            } else {
              userlisttemp[index].userstatuslabel = "---";
            }

            if (currentitem.sepaallowed === 1) {
              userlisttemp[index].sepaallowedlabel = "SI";
            } else {
              userlisttemp[index].sepaallowedlabel = "NO";
            }
          });
          self.userslist = userlisttemp;
          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;

          self.snacktext = "Error al obtener lista de usuarios";
          self.snackcolor = "error";
          self.snackresult = true;
          /*     self.$toast.open({
                        duration: 5000,
                        message: self.$t('myMachinesErrorGetMachines'),
                        type: 'is-danger'
                    })
                */
        }
      );
      /*     const _this=this;
      
        const { data } =  PaymentsRepository.listPayments().then(
                function(success) {
                    _this.$store.commit('', false)
                    //this.$router.push({path: '/myMachines'})
                    //his.posts = data;
                    _this.$router.push({
                        name: "dashboard/Dashboardv1"
                    });

                }).catch(function(error) {
                    _this.loginError = true
                }
            )
*/
    },
    activateUser() {
      if (this.activCode.length > 3) {
        const self = this;
        let url =
          process.env.VUE_APP_BASE_URI +
          "/getuserbyuuid/" +
          this.userData.useruuid; // self.$store.getters.uuid
        self.$http.get(url).then(function(success) {
          const email = success.data.userdata.email;
          let url = process.env.VUE_APP_BASE_URI + "/activateusersms";
          var bodyuser = {
            activationcode: self.activCode,
            useremail: email
          };
          self.$http.post(url, bodyuser).then(
            function(success) {
              self.isChangePassword = false;
              self.snacktext = "El usuario ha sido activado";
              self.snackcolor = "success";
              self.snackresult = true;
              self.isActivateUser = false
              self.loadData()
            },
            function(error) {
              self.isChangePassword = false;

              self.snacktext = "Error. Los datos no son correctos";
              self.snackcolor = "warning";
              self.snackresult = true;
            }
          );
        });
      } else {
        //this.isChangePassword = false

        this.snacktext = "El código no tiene el formato correcto ";
        this.snackcolor = "red";
        this.snackresult = true;
      }
    },
    resendCode() {
      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI +
        "/getuserbyuuid/" +
        this.userData.useruuid; // self.$store.getters.uuid
      self.$http.get(url).then(function(success) {
        const email = success.data.userdata.email;
        let url = process.env.VUE_APP_BASE_URI + "/resendactivationcode";
        var bodyuser = {
          useremail: email
        };
        self.$http.post(url, bodyuser).then(
          function(success) {
            self.isChangePassword = false;
            self.snacktext = "El código se ha enviado al usuario ";
            self.snackcolor = "success";
            self.snackresult = true;
          },
          function(error) {
            self.isChangePassword = false;

            self.snacktext = "Error. No se ha podido enviar el código";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.section-def {
  margin-bottom: 50px;
}

.toobar-extension {
  margin-left: 5px !important;
}

.breadcrumb-section {
  margin-left: -25px !important;
}

.action-btn {
  margin-left: 5px !important;
}

.state1 {
  background-color: salmon;
  text-color: #ffffff;
}

.state2 {
  background-color: green;
  text-color: #ffffff;
}

.state3 {
  background-color: red;
  text-color: #ffffff;
}
</style>

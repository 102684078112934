<template>
  <div>
   <v-container >
    <section-definition :title="page.title" :breadcrumbs="breadcrumbs"></section-definition>


      <v-layout row wrap>

   <v-card>
    <v-card-title>
    
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Buscar.."
        rows-per-page-items=[10,25,50,100]
        single-line
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="payments"
     :search="search"
     :loading="isLoading"
    class="elevation-1"
  >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

    <template slot="items" slot-scope="props">
      <td>{{ props.item.owneralias }}</td>
      <td>{{ props.item.useralias }}</td>
      <td>{{ props.item.idrent }}</td>
      <td class="text-xs-right">{{ props.item.machinename }}</td>
      <td :class="props.item.stateclass">{{ props.item.rentstatuslabel }}</td>
      <td :class="props.item.stateclass">{{ props.item.rentphaselabel }}</td>
      <td class="text-xs-right">{{ props.item.rentplace }}</td>
      <td class="text-xs-right">{{ props.item.transportPrice }}</td>
      <td class="text-xs-right">{{ props.item.rentfrom }}</td>
      <td class="text-xs-right">{{ props.item.rentto }}</td>
              <td>
                <v-icon
                  small
                  class="mr-2"
                  @click="gotoDetail(props.item.machineuuid, props.item.rentuuid)"
                >edit</v-icon>
              </td>      
    </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
        La búsqueda de  "{{ search }}" no ha encontrado resultados.
      </v-alert>
  </v-data-table>
   </v-card>

      </v-layout>
    </v-container>

   <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top=true
    >
      {{ snacktext }}
      <v-btn
        dark
        flat
        @click="snackresult = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

  </v-container>
  </div>
</template>

<script>
/*
amountpayed: 900
idpaymentstatus: 2
idrent: 488
machinename: "Ter"
owneralias: "Marta12"
paymentamount: 900
paymentdata: "959999/018687897529827156149505050702"
paymentdate: "Mon, 05 Aug 2019 00:00:00 GMT"
paymenttype: 1
rentfrom: "Tue, 20 Aug 2019 00:00:00 GMT"
rentto: "Wed, 28 Aug 2019 00:00:00 GMT"
transactiondate: "Fri, 19 Jul 2019 11:48:26 GMT"
useralias: "Marta12"
*/
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import PlainTableOrder from "@/components/Widgets/list/PlainTableOrder";

export default {
  mixins: [validationMixin],

  data() {
    return {
      search: "",
      headers: [
         { text: "Propietario", value: "owneralias" },
         { text: "Arrendatario", value: "owneralias" },
         { text: "ID alquiler", value: "idrent" },
        { text: "Equipo ", value: "machinename" },
        { text: "Estado", value: "rentstatuslabel" },
        { text: "Fase", value: "rentphaselabel" },
        { text: "Lugar de entrega", value: "rentplace" },
        { text: "Precio transporte ", value: "transportPrice" },
        { text: "Fecha inicio ", value: "rentfrom" },
        { text: "Fecha Fin", value: "rentto" },
        { text: "Acciones ", value: "" }

      ],      
      payments: [],
      isLoading: false,
      snackbar: false,
       page: {
        title: "Transportes",
        headline: "Lista de transportes",
        description: `Alquileres en los que el transporte es realizado por terceros.`
      },
      breadcrumbs: [
        {
          text: "Transportes",
          disabled: false
        }
      ],
      snackresult: false,
      snacktext: "",
      snackcolor: "success",     
    };
  },
  components: {
    PlainTableOrder
  },
    mounted: function() {
    this.loadData();
    console.log("mounted: got here");
  },
  methods: {
            gotoDetail: function(uuid, rentuuid){

     //       this.$router.push({ path: '/pages/transportdetail/:id', query: { id: uuid }})
        this.$router.push({
          name: "pages/TransportDetailPage",
          params: {
            currentrentuuid: rentuuid,
            currentmachine: uuid
            }
       //   query: { id: useruuid }
        });
           // this.$router.push({ path: '/machinedetail'})

        },
    loadData(){
        const self = this
            let url = process.env.VUE_APP_BASE_URI + '/gettransporters'
            self.$http.get(url).then(
                function(success) {
                    var transporterstemp = success.data.transporters;
                    var id1 = transporterstemp[0].iduser;
                    self.getUserData(id1);
                },
                function(error) {
                      self.snacktext="Error al obtener lista de transportes";
                        self.snackcolor="error";
                        self.snackresult =true;
             //       self.$toast.open({
             //           duration: 5000,
             //           message: self.$t('myMachinesErrorGetMachines'),
             //           type: 'is-danger'
             //       })
                }
            )
 
    },
    getUserData(idtransporter){
        const self = this
            let url = process.env.VUE_APP_BASE_URI + '/getuserbyid'
            var bodyuser ={
              "iduser": idtransporter
            }
            self.$http.post(url, bodyuser).then(
                function(success) {
                    var transporterstemp = success.data.userdata;
                    var uuid1 = transporterstemp.useruuid;
                    self.getTransports(uuid1);
                },
                function(error) {
                    self.$toast.open({
                        duration: 5000,
                        message: self.$t('myMachinesErrorGetMachines'),
                        type: 'is-danger'
                    })
                }
            )
 
    },    
    getTransports(uuidtransporter) {

        const self = this

            let url = process.env.VUE_APP_BASE_URI + '/getrentsfortransporter/'+uuidtransporter
            self.isLoading = true
            self.$http.get(url).then(
                function(success) {
                    var paymentstemp = success.data.rents
                    var transportsfinal = []
                     self._.forEach(paymentstemp, function(expense, index) {
                       if( expense.rentstatus  == 2 || expense.rentstatus == 6 ||  expense.rentstatus ==7 )
                        {
                        paymentstemp[index].rentfrom = self.moment(expense.rentfrom).format('DD-MM-YYYY').split('T')[0];
                        paymentstemp[index].rentto = self.moment(expense.rentto).format('DD-MM-YYYY').split('T')[0];
                        if( expense.paymenttype === 1){
                          paymentstemp[index].paymenttypelabel="Tarjeta";
                        }
                       if( expense.paymenttype === 2){
                          paymentstemp[index].paymenttypelabel="Adeudo ";
                        }
                       if( expense.paymenttype === 3){
                          paymentstemp[index].paymenttypelabel="Transferencia";
                        }

                        if( expense.rentstatus === 1){
                          paymentstemp[index].rentstatuslabel="Solicitado";
                        }
                       if( expense.rentstatus === 2){
                          paymentstemp[index].rentstatuslabel="Aprobado";
                        }
                       if( expense.rentstatus >= 3 &&  expense.rentstatus < 6 ){
                          paymentstemp[index].rentstatuslabel="Firmado";
                        }
                       if( expense.rentstatus === 6){
                          paymentstemp[index].rentstatuslabel="Entrega";
                        }
                       if( expense.rentstatus === 7){
                          paymentstemp[index].rentstatuslabel="Recogida";
                        }
                       if( expense.rentstatus === 8){
                          paymentstemp[index].rentstatuslabel="Liquidación";
                        }
                       if( expense.rentstatus === 9){
                          paymentstemp[index].rentstatuslabel="Finalizado";
                        }

                       if( expense.transportNeeded === 1){
                          paymentstemp[index].transportNeededlabel="SI";
                        }
                        else{
                          paymentstemp[index].transportNeededlabel="NO";

                        }

                      if( expense.rentstatus >= 6 &&  expense.rentstatus < 9){
                            if( expense.rentphase === 1){
                                paymentstemp[index].rentphaselabel="Salida";
                                }
                            if( expense.rentphase === 2){
                                paymentstemp[index].rentphaselabel="Entrega";
                                }
                            if( expense.rentphase === 3){
                                paymentstemp[index].rentphaselabel="Recogida";
                                }
                            if( expense.rentphase === 4){
                                paymentstemp[index].rentphaselabel="Devolución";
                                }

                      }
                      else{
                                paymentstemp[index].rentphaselabel=" ";

                      }


                      transportsfinal.push(paymentstemp[index])
                      }
                    })
                  self.payments = transportsfinal;
                  self.isLoading = false
                },
                function(error) {
                    self.isLoading = false
                    self.$toast.open({
                        duration: 5000,
                        message: self.$t('myMachinesErrorGetMachines'),
                        type: 'is-danger'
                    })
                }
            )
   /*     const _this=this;
      
        const { data } =  PaymentsRepository.listPayments().then(
                function(success) {
                    _this.$store.commit('', false)
                    //this.$router.push({path: '/myMachines'})
                    //his.posts = data;
                    _this.$router.push({
                        name: "dashboard/Dashboardv1"
                    });

                }).catch(function(error) {
                    _this.loginError = true
                }
            )
*/
    }
  }
};
</script>

<style lang="stylus" scoped>
  .section-def
    margin-bottom: 50px
  .toobar-extension
    margin-left: 5px !important
  .breadcrumb-section
    margin-left: -25px !important
  .action-btn
    margin-left: 5px !important

  .state1{
    background-color :salmon;
    text-color : #ffffff;

  }

  .state2{
    background-color :green;
    text-color : #ffffff;
    
  }

  .state3{
    background-color :red;
    text-color : #ffffff;
    
  }

</style>

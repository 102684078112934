import { initializeApp } from "@firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import "@firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyATgYaNFsOem-oTQdTLMGmnYiL7pQKScP8",
  authDomain: "ploou-pre.firebaseapp.com",
  databaseURL: "https://ploou-pre.firebaseio.com",
  projectId: "ploou-pre",
  storageBucket: "ploou-pre.appspot.com",
  messagingSenderId: "290544749716",
  appId: "1:290544749716:web:69f77fd8a96d66e2943881",
  measurementId: "G-FKNE17WX8G"
  };
  const firebaseApp = initializeApp(firebaseConfig)

  export const firebase2 = firebaseApp;

  export const db = getFirestore(firebaseApp);


<template>
  <section>
    <div class="myContainer mtop40">
      <div class="columns is-12">
        <div class="column is-6">
          <bar
            :serviceUrl="serviceMachineRentsUrl"
            :chartData="''"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'bottom',
                labels: { fontColor: '#000080' }
              },
              title: { display: true, text: '' },
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
            }"
          ></bar>
        </div>
        <div class="column is-6">
          <bar
            :serviceUrl="serviceMachineRentsDuration"
            :chartData="''"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'bottom',
                labels: { fontColor: '#000080' }
              },
              title: { display: true, text: '' },
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
            }"
          ></bar>
        </div>
      </div>

      <div class="columns is-12">
        <div class="column is-6">
          <bar
            :serviceUrl="serviceMachineInFavorites"
            :chartData="''"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'bottom',
                labels: { fontColor: '#000080' }
              },
              title: { display: true, text: '' },
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
            }"
          ></bar>
        </div>
        <div class="column is-6">
          <bar
            :serviceUrl="serviceMachineMonthDistributed"
            :chartData="''"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'bottom',
                labels: { fontColor: '#000080' }
              },
              title: { display: true, text: '' },
              scales: { yAxes: [{ ticks: { beginAtZero: true } }] }
            }"
          ></bar>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import bar from "../../components/Elements/ploouchart/bar.vue";
export default {
  components: {
    bar
  },
  data() {
    return {
      machineRents: Object,
      machineRentsDuration: Object,
      rentsDuration: Object,
      showPie: false,
      showBar: false,
      showBarDuration: false,
      serviceMachineRentsUrl: "/graphmachinerents",
      serviceMachineRentsDuration: "/graphmachinerentsduration",
      serviceBillingForMachine: "/graphbillingbymachine",
      serviceMachineInFavorites: "/graphmachineinfavorites",
      serviceMachineMonthDistributed: "/graphrentsmonthdistributed"
    };
  },
  mounted() {
    //    this.getMachineRents()
    //    this.getMachineRentsDuration()
    this.getRentsDurationPie();
  },
  methods: {
    getRentsDurationPie() {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/graphrentsdurationpie";
      self.$http.get(url).then(
        function(response) {
          let rents = {};
          rents.title = response.data.data.title;
          rents.labels = response.data.data.labels;
          rents.datasets = response.data.data.datasets;
          rents.datasets[0].borderWidth = 2;
          let colors = self.getRandomRGBAColorArray(
            response.data.data.labels.length
          );
          rents.datasets[0].backgroundColor = colors.bgColors;
          rents.datasets[0].borderColor = colors.borderColors;
          self.rentsDuration = rents;
          self.showPie = true;
        },
        function(error) {
          console.log(error);
        }
      );
    },
    getRandomRGBAColor() {
      let bgColors = [];
      let borderColors = [];
      var o = Math.round,
        r = Math.random,
        s = 255;
      bgColors.push(
        "rgba(" +
          o(r() * s) +
          "," +
          o(r() * s) +
          "," +
          o(r() * s) +
          ",0.5" +
          ")"
      );
      borderColors.push(bgColors[0].replace("0.2", "1"));
      return { bgColors: bgColors, borderColors: borderColors };
    },
    getRandomRGBAColorArray(numerOfColors) {
      let bgColors = [];
      let borderColors = [];
      var o = Math.round,
        r = Math.random,
        s = 255;
      for (var i = 0; i < numerOfColors; i++) {
        bgColors.push(
          "rgba(" +
            o(r() * s) +
            "," +
            o(r() * s) +
            "," +
            o(r() * s) +
            ",0.5" +
            ")"
        );
        borderColors.push(bgColors[i].replace("0.2", "1"));
      }
      return { bgColors: bgColors, borderColors: borderColors };
    }
  }
};
</script>

<style scoped>
.myContainer {
  margin: 50px;
}
.mtop40 {
  margin-top: 40px;
}
.mBottom20 {
  margin-bottom: 20px;
}
.bold {
  font-weight: bold;
}
</style>

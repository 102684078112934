/*eslint-disable*/
<template>
  <v-container id="input-usage" grid-list-xl fluid>
    <section-definition :title="page.title"></section-definition>
    <v-layout wrap>
      <v-flex xs6>
        <v-text-field
          v-model="currentticket.creatoralias"
          label="Reportada por"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="currentticket.ticketcreationdate"
          label="Fecha"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="currentticket.statuslabel"
          label="Estado de la incidencia"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field
          v-model="currentticket.ticketname"
          :counter="100"
          label="Asunto"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md12>
        <v-textarea
          v-model="ticketdescription"
          :rules="nameRules"
          :counter="500"
          label="Descripción"
          readonly
        ></v-textarea>
      </v-flex>
      <v-flex xs12 md12>
        <v-textarea
          v-model="ticketresponse"
          :rules="nameRules"
          :counter="2000"
          label="Respuesta"
          readonly
        ></v-textarea>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="currentticket.ticketresponsedate"
          label="Fecha de respuesta"
          readonly
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top="true"
    >
      {{ snacktext }}
      <v-btn dark flat @click="snackresult = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { db } from "../../firebase";
import { firebase2 } from "../../firebase";
import moment from "moment";

export default {
  name: "user",
  props: ["id"],
  data() {
    return {
      userData: {},
      endPointImages: process.env.VUE_APP_BASE_URI + "/getimagebypathbase64/",
      endPointAvatar: String,
      endPointIdFront: String,
      isChangeTicket: false,
      avatarImg: false,
      idFrontImg: false,
      idBackImg: false,
      driverLicenseFrontImg: false,
      driverLicenseBackImg: false,
      isChangePassword: false,
      isSaveTicket: false,
      labels: {
        message: this.$t("modalConfirmSaveUserText"),
        success: this.$t("userSuccessEdit"),
        error: this.$t("userErrorEdit"),
        leftButton: this.$t("passwordExit"),
        rigthButton: this.$t("modalConfirm")
      },
      endPoint:
        process.env.VUE_APP_BASE_URI +
        "/modifyuser/" +
        this.$store.getters.uuid,
      imagetype: String,
      problemslist: [
        "Consulta",
        "Problemas con el alquiler",
        "Error en la app",
        "Solicitud de nueva funcionalidad"
      ],
      stateslist: [
        "1-Pendiente",
        "2-Atendido",
        "3-Resuelto",
        "4-En Espera de  Versión",
        "5-Desestimado"
      ],
      pagecounts: [],
      selectedtype: "",
      tickets: [],
      fireid: null,
      currentticket: null,
      ticketresponse: null,
      ticketname: null,
      ticketdescription: null,
      snackresult: false,
      snacktext: "",
      snackcolor: "success",
      page: {
        title: "Responder incidencia",
        headline: "",
        description: `.`
      },
      breadcrumbs: []
    };
  },
  mounted() {
    const self = this;
    this.ticketuuid = this.$store.state.ticketUuid;
    const coll = db
      .collection("tickets")
      .where("id", "==", this.ticketuuid)
      .get()
      .then(res => {
        self.fireid = res.docs[0].id;
        db.collection("tickets")
          .doc(self.fireid)
          .get()
          .then(snapshot => {
            const document = snapshot.data();
            self.currentticket = snapshot.data();
            self.ticketdescription = self.currentticket.ticketdescription;
            self.ticketresponse = self.currentticket.ticketresponse;
          });
      });
  },
  methods: {
    editUser() {
      this.isSaveUser = true;
    },
    downloadImage(imagepath, cont) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/downloadimagefrompath";
      var bodyuser = {
        imagepath: imagepath
      };
      self.$http
        .post(url, bodyuser, {
          responseType: "arraybuffer"
        })
        .then(
          function(success) {
            var b64 = new Buffer(success.data, "binary").toString("base64");
            var imgsrc = "data:image/jpeg;base64," + b64;
            self.sepadocs[cont].b64img = imgsrc;
          },
          function() {
            self.snacktext = "Error. La imagen  no se ha podido  recuperar";
            self.snackcolor = "warning";
            self.snackresult = true;
            self.isChangeTicket = false;
          }
        );
    },

    confirmSave() {
      const today = moment();
      var userData2 = {
        id: today.format("YYYYMMDDmmss"),
        creatoralias: this.currentticket.creatoralias,
        creatoruuid: this.currentticket.creatoruuid,
        tickettype: this.currentticket.tickettype,
        ticketname: this.currentticket.ticketname,
        ticketcreationdate: this.currentticket.ticketcreationdate,
        ticketdescription: this.ticketdescription,
        ticketresponse: this.ticketresponse,
        tickestatus: 2,
        statuslabel: "ATENDIDA",
        statusclass: "state2",
        ticketresponsedate: moment.format("DD/MM/YYYY HH:mm:ss"),
        responderuuid: this.$store.getters.uuid,
        responderalias: this.$store.getters.useralias
      };
      const self = this;
      db.doc("tickets/" + self.fireid).set(userData2);
      self.snacktext = "La incidencia ha sido respondida";
      self.snackcolor = "success";
      self.snackresult = true;
      self.isChangeTicket = false;
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    upload: function() {
      this.$binding(
        "messages",
        db.collection("users/adminuser/userrooms")
      ).then(messages => {
        console.log(messages);
      });
      var storageRef = firebase2.storage().ref();
      var mountainsRef = storageRef.child(`chatimages/${this.imageName}`);
      mountainsRef.put(this.imageFile).then(snapshot => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          this.imageUrl = downloadURL;
          const bucketName = "image-upload-62ed7.appspot.com";
          const filePath = this.imageName;
          db.collection("images").add({
            downloadURL,
            downloadUrl:
              `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/images` +
              "%2F" +
              `${encodeURIComponent(filePath)}?alt=media`,
            timestamp: Date.now()
          });
          this.getImages();
        });
      });
    }
  }
};
</script>

<style scoped>
.mtop31 {
  margin-top: 31px;
}
.mtop40 {
  margin-top: 40px;
}
.mtop-30 {
  margin-top: -30px;
}
.icon-size {
  font-size: 18pt;
}
.float-right {
  float: right;
}
</style>

<template>
    <section>
        <div class="modal-card">
            <div class="modal-card-body">
                <p class="title has-text-centered">{{ mutationLabels.message }}</p>
                <div class="columns">
                    <div class="column">
                        <button class="button is-primary float-right" type="button" @click="confirm">{{ mutationLabels.rigthButton }}</button>
                        <button class="button is-accent float-right mRight10" @click="$parent.close()">{{ mutationLabels.leftButton }}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'confirm',
    props: ['userData', 'labels', 'endPoint'],
    data () {
        return {
            mutationLabels: this.labels
        }
    },
    methods : {
        confirm () {
            const self = this
            self.$http.post(self.endPoint, self.userData).then(
                function (success) {
                    self.$toast.open({
                        duration: 5000,
                        message: self.mutationLabels.success,
                        type: 'is-success'
                    })
                    self.$store.commit('setName', self.userData.username)
                    self.$store.commit('setLastname', self.userData.usersurname1 + ' ' + self.userData.usersurname2)
                    self.$parent.close()
                },
                function (error) {
                    self.$toast.open({
                        duration: 5000,
                        message: self.mutationLabels.error,
                        type: 'is-danger'
                    })
                }
            )
        }
    }
}
</script>


<style>
.float-right {
    float: right;
}
.mRight10 {
    margin-right: 10px;
}
.title {
    margin-top: 30px;
    margin-bottom: 40px !important;
    font-size: 14pt;
    font-weight: bold;
}
.modal-card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>

<template>
  <div>
    <section-definition :title="page.title"></section-definition>
    <v-container grid-list-md text-xs-center>
      <v-layout row>
          <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
          </v-flex>
          <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
            <v-btn color="act" block class="bold" @click="saveData()">
              Guardar equipo
            </v-btn>
          </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-carousel :cycle="false" height="500" max="400"  v-model="carouselIndex">
            <v-carousel-item
              v-for="(item, i) in images"
              :key="i"
              :src="item.src"
              reverse-transition="fade"
              transition="fade"
            ></v-carousel-item>
          </v-carousel>
          <v-flex xs12 sm6>
            <v-subheader style="font-weight:bold;" >Gestión de imágenes  </v-subheader>
          </v-flex>
          <v-layout row>
          <v-flex sm1 md1 xs1>
            <v-field :label="Foto"></v-field>
            <upload-btn
              @file-update="update"
              title="Añadir Imagen"
              noTitleUpdate="true"
            ></upload-btn>
          </v-flex>
          <v-flex sm1 md1 xs1 class="text-sm-center text-xs-center">
            <v-btn color="error" block class="bold" @click="deletePhoto()">
              Borrar imagen
            </v-btn>
          </v-flex>

          <v-flex sm1 md1 xs1 class="text-sm-center text-xs-center">
            <v-btn color="warning" block title="Marcar como principal" class="bold" @click="mainPhoto()">
                <v-icon
                  small
                  color="white"
                  class="mr-2"
                  title="Marcar como x principal"
                >
                  star
                </v-icon>            
              </v-btn>
          </v-flex>  

          </v-layout>
        
          <v-flex xs12 sm6>
            <v-subheader style="font-weight:bold;" >Disponibilidad  </v-subheader>
          </v-flex>
          <v-flex xs12 sm6 class="my-3">
            <div class="subheading"></div>
            <v-date-picker
              v-model="date1"
              :events="blockeddays"
              event-color="red lighten-1"
              locale="es-es"
              :first-day-of-week="1"
              header-color="blue"
            ></v-date-picker>
          </v-flex>

          <v-flex sm2 xs6 class="text-sm-left text-xs-center"></v-flex>

          <v-flex sm2 xs6 class="text-sm-right text-xs-right"></v-flex>
          <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
            <v-btn color="act" block class="bold" @click="saveData()">
              Guardar equipo
            </v-btn>
          </v-flex>
        </v-flex>

        <v-flex xs12 sm6>
          <v-card>
            <v-card-text>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="macdata.machinename"
                  :rules="nameRules"
                  :counter="50"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-flex>
              <v-select
                v-model.number="macdata.machinebrandid"
                type="number"
                :items="brands"
                item-text="brandname"
                item-value="brandid"
                label="Marca"
                v-on:change="changeBrand"
              ></v-select>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="macdata.machinedescription"
                  :rules="nameRules"
                  :counter="100"
                  label="Descripción"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="macdata.machinemodel"
                  :rules="nameRules"
                  :counter="30"
                  label="Modelo"
                  required
                ></v-text-field>
              </v-flex>
              <v-divider></v-divider>
              <div class="text-sm-left text-xs-left">
                <v-flex xs12 md6>
                  <v-text-field
                    v-model.number="macdata.machineprice"
                    type="number"
                    :rules="nameRules"
                    :counter="10"
                    :step="0.1"
                    label="Precio (€)"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    v-model.number="macdata.machinepriceexcess"
                    type="number"
                    :rules="nameRules"
                    :counter="10"
                    :step="0.1"
                    label="Fianza (€)"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    v-model.number="macdata.machinepower"
                    type="number"
                    :rules="nameRules"
                    :counter="50"
                    label="Potencia (CV)"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md12>
                  <v-text-field
                    v-model="macdata.machineplace"
                    :rules="nameRules"
                    :counter="50"
                    label="Localización"
                    required
                    readonly="true"
                  ></v-text-field>
                </v-flex>

                <google-map />

                <v-flex xs12 md12>
                  <v-text-field
                    v-model="macdata.machinelocation"
                    :rules="nameRules"
                    :counter="50"
                    label="Coordenadas"
                    required
                    readonly="true"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    v-model="macdata.machineyear"
                    :rules="nameRules"
                    :counter="50"
                    label="Año de fabricación"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    v-model.number="macdata.machinetacometer"
                    type="number"
                    :rules="nameRules"
                    :counter="50"
                    label="Horas de uso "
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    v-model="macdata.machineserialnumber"
                    :rules="nameRules"
                    :counter="50"
                    label="Número de bastidor "
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    v-model="macdata.machineplatenumber"
                    :rules="nameRules"
                    :counter="50"
                    label="Matrícula "
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md10>
                  <v-slider
                    v-model.number="macdata.machinediscount1"
                    type="number"
                    :rules="rules"
                    label="Descuento 15-30 dias"
                    step="1"
                    :max="40"
                    :min="0"
                    ticks
                    thumb-label="always"
                  ></v-slider>
                </v-flex>

                <v-flex xs12 md10>
                  <v-slider
                    v-model.number="macdata.machinediscount2"
                    type="number"
                    :rules="rules"
                    label="Descuento 30-60 dias"
                    step="1"
                    :max="40"
                    :min="0"
                    ticks
                    thumb-label="always"
                  ></v-slider>
                </v-flex>

                <v-flex xs12 md10>
                  <v-slider
                    v-model.number="macdata.machinediscount3"
                    type="number"
                    :rules="rules"
                    label="Descuento más de 60 dias"
                    step="1"
                    :max="40"
                    :min="0"
                    ticks
                    thumb-label="always"
                  ></v-slider>
                </v-flex>

                <!-- <li v-for="(ingredient, i) in item.recipe.ingredientLines" :key="i">{{ingredient}}</li> -->
              </div>
              <v-checkbox
                class="text-sm-left text-xs-left custom-checkbox"
                label="Transporte disponible"
                v-model="hasTransport"
              ></v-checkbox>
              <v-checkbox
                class="text-sm-left text-xs-left custom-checkbox"
                label="Seguro disponible"
                v-model="hasInsurance"
              ></v-checkbox>
            </v-card-text>
            <v-card-title></v-card-title>
          </v-card>
          <v-card class="py-8 my-8">
            <div
              class="custom-checkbox mb-8"
              v-for="(itemx, index) in attsType4"
            >
              <v-checkbox
                class="text-sm-left text-xs-left custom-checkbox"
                style="font-size:12;"
                :label="itemx.labelx"
                v-model="itemx.valuebool"
              ></v-checkbox>
            </div>
            <span> -</span>
          </v-card>

          <v-card>
            <div
              class="text-sm-left text-xs-left custom-machineatt"
              v-for="(itemx, index) in attsType3"
            >
              <v-layout row wrap>
                <v-flex d-flex xs6>
                  <v-card tile flat>
                    <span
                      height="30"
                      class="text-sm-left text-xs-left custom-check"
                      >{{ itemx.label }} : {{ itemx.value }}</span
                    >
                  </v-card>
                </v-flex>
              </v-layout>
            </div>
          </v-card>

          <v-card class="my-8  py-8">
            <div class="custom-machineatt"></div>
            <div
              class="text-sm-left text-xs-left custom-machineatt"
              v-for="(itemx, index) in attsType1"
            >
              <v-flex xs12 md6>
                <v-text-field
                  class="py-n4"
                  filled
                  v-model="itemx.value"
                  :label="itemx.label"
                ></v-text-field>
              </v-flex>
              <!--   <span class="text-sm-left text-xs-left custom-check">{{itemx.label}} : {{itemx.value}}</span> -->
            </div>
            <span> -</span><br />
            <span>- </span>
          </v-card>
        </v-flex>
      </v-layout>
      <v-snackbar
        v-model="snackresult"
        :color="snackcolor"
        :timeout="6000"
        top="true"
      >
        {{ snacktext }}
        <v-btn dark flat @click="snackresult = false">Cerrar</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import GoogleMap from "../../components/Maps/GoogleMap";
import { EventBus } from "../../eventBus";
import upload from "../../components/upload.vue";
import password from "../../components/modals/password.vue";
import saveUserConfirm from "../../components/modals/saveUserConfirm.vue";
import UploadButton from "vuetify-upload-button";

export default {
  name: "MachineDetail",
  props: ["id"],

  data() {
    return {
      images: [],
      uuid: "",
      useruuid: "",
      macdata: {},
      machinePhotos: [],
      isLoading2: false,
      currentIdFile: 0,
      hasTransport: false,
      hasInsurance: false,
      attsType1: [],
      attsType2: [],
      attsType3: [],
      attsType4: [],
      selectedBrand: null,
      caltype: "month",
      calstart: "2019-07-12",
      calend: "2020-11-06",
      blockeddays: [],
      currentMonth: 7,
      date: null,
      brands: [],
      attributes: [],
      snacktext: "",
      snackcolor: "",
      snackresult: "",
      page: {
        title: "Editar   Equipo",
        headline: "Lista de alquileres",
        description: "Alquileres no cancelados"
      },
      carouselIndex: 0
    };
  },
  mounted: function() {
    //this.getop(this.$route.query.id);
    this.uuid = this.$route.query.id;
    this.getop(this.$route.query.id);
    this.useruuid = this.$store.getters.uuid;
    const _this = this;
    EventBus.$on("newAddress", addressStr => {
      _this.macdata.machineplace = addressStr;
      console.log("Address: " + addressStr);
    });

    EventBus.$on("newLocation", locatStr => {
      _this.macdata.machinelocation = locatStr;
      console.log("Address: " + locatStr);
    });
  },
  components: {
    GoogleMap,
    upload,
    "upload-btn": UploadButton
  },
  computed: {},
  methods: {
    saveData: function() {
      const _this = this;
      this.isLoading2 = true;
      var dataToSend = this.macdata;
      delete dataToSend.cultivations;
      delete dataToSend.machineattributes;
      if( this.hasInsurance){
        this.macdata.machineinsuranceavailable =1
      }
      else{ 
          this.macdata.machineinsuranceavailable = 0
      
      }
      if (this.hasTransport == true ){
        this.macdata.machinetransportavailable = 1
      }
      else{ 
        this.macdata.machinetransportavailable = 0
      }
      const url = process.env.VUE_APP_BASE_URI + "/updatemachine/" + this.uuid;
      _this.$http
        .post(url, dataToSend) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(function(response) {
          console.log(response);
          _this.isLoading2 = false;

          _this.snacktext = "Los datos del equipo  se han modificado";
          _this.snackcolor = "success";
          _this.snackresult = true;
        })
        .catch(function(error) {
          console.log(error);
          _this.isLoading2 = false;
          _this.snacktext = "Error al modificar los datos del equipo ";
          _this.snackcolor = "error";
          _this.snackresult = true;
        });
    },
    changeBrand(b) {
      let obj = this.brands.find(obj => obj.brandid == b);
      this.macdata.machinebrandname = obj.brandname;
      // alert(this.macdata.machinebrand)
    },
    getop: function(opuuid) {
      const _this = this;
      this.isLoading2 = true;
      const url = process.env.VUE_APP_BASE_URI + "/getmachinebyuuid/" + opuuid;
      _this.$http
        .get(url) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log(response);
            this.isLoading2 = false;
            this.macdata = response.data.machinedata;
            if (this.macdata.machineinsuranceavailable == 1)
              this.hasInsurance = true;
            if (this.macdata.machinetransportavailable == 1)
              this.hasTransport = true;
            this.macdata.machineattributes.forEach(element => {
              if (element.attributetype == 1) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                if (element.attributename == "Potencia") var aa = 0;
                else this.attsType1.push(attnew);
              } else if (element.attributetype == 3) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                this.attsType3.push(attnew);
              } else if (element.attributetype == 4) {
                var attnew = {};
                attnew.labelx = element.attributename;
                if (element.attributevalueyesno == 0) attnew.valuebool = false;
                else attnew.valuebool = true;
                _this.attsType4.push(attnew);
              }
            });
            if (_this.macdata.machinelocation != null) {
              EventBus.$emit("setMacAddress", _this.macdata.machinelocation);
            }

            const url2 =
              process.env.VUE_APP_BASE_URI +
              "/getbrandsbytype/" +
              _this.macdata.machinetype; //+ _this.macdata.machinetypeid;
            _this.$http
              .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
              .then(function(response) {
                console.log("" + response);
                _this.brands = response.data.brands;
              })
              .catch(function(error) {
                console.log(error);
                // this.isLoading2 = false;
              });

            _this.$http
              .get(
                process.env.VUE_APP_BASE_URI +
                  "/getphotosformachine/" +
                  response.data.machinedata.machineuuid
              )
              .then(
                function(response) {
                  console.log("" + response);
                  //  this.isLoading2 = false;
                  this.machinePhotos = response.data;
                  for (var a = 0; a < this.machinePhotos.length; a++) {
                    var newimg = {};
                    newimg.src =
                      process.env.VUE_APP_BASE_URI +
                      "/getimage/" +
                      this.machinePhotos[a].imageuuid;
                    this.images.push(newimg);
                  }
                }.bind(this)
              )
              .catch(
                function(error) {
                  console.log(error);
                  // this.isLoading2 = false;
                }.bind(this)
              );
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            this.isLoading2 = false;
          }.bind(this)
        );

      const url2 =
        process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + opuuid;
      _this.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
            this.blockeddays = response.data.blockeddays;
            //                  for (var a = 0; a < this.machinePhotos.length; a++) {
            //                    var newimg = {};
            //                    newimg.src =
            //                      "https://ploou-produccion.appspot.com/getimage/" +
            //                      this.machinePhotos[a].imageuuid;
            //                    this.images.push(newimg);
            //                  }
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            // this.isLoading2 = false;
          }.bind(this)
        );
    },
    update(file) {
      var fb64 = this.getBase64(file);
    },

    getBase64(file) {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        const fb64 = reader.result;
        if (fb64 != null) {
          var imgsrc = fb64.split(",")[1];
          let url = process.env.VUE_APP_BASE_URI + "/uploadimage";

          const upinfo = {
            machineuuid: self.uuid,
            machineid: self.macdata.machineid,
            useruuid: self.useruuid,
            base64image: imgsrc
          };
          self.$http.post(url, upinfo).then(
            function(success) {
              self.isWaiting = false;
              self.snacktext = " La imagen   se ha guardado  ";
              self.snackcolor = "success";
              self.snackresult = true;
              self.refreshPhotos()
            },
            function(error) {
              self.isWaiting = false;
              self.snacktext = "Error. La imagen  no se ha podido  guardar";
              self.snackcolor = "warning";
              self.snackresult = true;
            }
          );
        }
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
        return null;
      };
    },
    refreshPhotos() {
      const self = this;
      self.images =[]
      self.$http
        .get(process.env.VUE_APP_BASE_URI + "/getphotosformachine/" + self.uuid)
        .then(function(response) {
          console.log("" + response);
          //  this.isLoading2 = false;
          self.machinePhotos = response.data;
          for (var a = 0; a < self.machinePhotos.length; a++) {
            var newimg = {};
            newimg.src =
              process.env.VUE_APP_BASE_URI +
              "/getimage/" +
              self.machinePhotos[a].imageuuid;
            self.images.push(newimg);
          }
        })
        .catch(function(error) {
          console.log(error);
          // this.isLoading2 = false;
        });
    },
    deletePhoto() {
      const self = this;
      self.$http
        .get(process.env.VUE_APP_BASE_URI + "/deleteimagebyuuid/" + self.machinePhotos[self.carouselIndex].imageuuid)
        .then(function(response) {
          console.log("" + response);
          //  this.isLoading2 = false;
              self.isWaiting = false;
              self.snacktext = " La imagen   se ha eliminado  ";
              self.snackcolor = "success";
              self.snackresult = true;
              self.refreshPhotos()

        })
        .catch(function(error) {
          console.log(error);
              self.isWaiting = false;
              self.snacktext = "Error. La imagen  no se ha podido  eliminar";
              self.snackcolor = "warning";
              self.snackresult = true;
        });
    },
    mainPhoto() {
      const self = this;
      self.$http
        .get(process.env.VUE_APP_BASE_URI + "/setfrontimage/" + self.machinePhotos[self.carouselIndex].imageuuid)
        .then(function(response) {
          console.log("" + response);
          //  this.isLoading2 = false;
              self.isWaiting = false;
              self.snacktext = " La imagen se ha marcado como principal  ";
              self.snackcolor = "success";
              self.snackresult = true;
              self.refreshPhotos()

        })
        .catch(function(error) {
          console.log(error);
              self.isWaiting = false;
              self.snacktext = "Error. La imagen  no se ha podido marcar como principal";
              self.snackcolor = "warning";
              self.snackresult = true;
        });
    }

  }
};
</script>
<style lang="stylus">
#example-custom-transition {
  .fade {
    &-enter-active, &-leave-active, &-leave-to {
      transition: 0.3s ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }
}

.custom-checkbox {
  height: 20px;
  font-size: 12;
  margin-left: 5px;
}

.custom-machineatt {
  height: 60px;
  font-size: 12;
  margin-left: 15px;
}

.v-label {
  font-size: 14px;
  font-color: black;
}
</style>

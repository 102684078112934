import Repository from "./Repository";

const resource = "/posts";
export default {
  get() {
    return Repository.get(`${resource}`);
  },

  getPost(postId) {
    return Repository.get(`${resource}/${postId}`);
  },

  async login(payload) {
    const pathres ='login';
    var tok = await Repository.post(`${pathres}`, payload);
    return tok;
  }
};
import Vue from 'vue';
import moment from "moment";

Vue.filter('utcToFormatDate', (date) => {
    var stillUtc = moment.utc(date).toDate()
    return moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss')
})

Vue.filter('splitDate', (date) => {
    return date.split('-')[2] + '-' + date.split('-')[1] + '-' + date.split('-')[0]
})

Vue.filter('extensions', (extensions) => {
    if (extensions.length > 0) {
        return 'SI'
    }
    return 'NO'
})
const messages = {
  message: {
    hello: "hello world"
  },
  profileUpdated: "User profile updated",
  passwordUpdated: "Password succesfully changed",
  company: {
    login: "Company login succesfully",
    alreadyLogin: "Comapny already login",
    create: "Company succesfully created",
    forgot: {
      name: "Company name(s) were sent to your e-mail account",
      password: "Your password has been succesfully reset"
    }
  },
  invitation: {
    sent: "Invitation sent",
    resent: "Invitation resent",
    revoke: "Invitation revoked"
  },
  companySettingUpdate: "Company Settings updated",
  profileSettingUpdate: "Profile updated",
  accountSettingUpdate: "Account Settings updated",
  notificationSettingUpdate: "Notification settings updated",
  fileupload: {
    dictFileTooBig: "File is too large",
    dictFileTooSmall: "File is too small",
    dictCancelUploadConfirmation: "Are you sure?"
  },
  users: {
    deleteConfirmation:
      "Are you sure you want to delete the user? <br> Once deleted, the user will not be able to join the company anymore."
  },
  member: {
    searchNoMatchFound: "Could not find member",
   
  },
  spanish: "Español",
  loginUserLabel: "Correo electrónico",
  loginUserPlaceholder: "Introduzca su correo electrónico",
  loginPasswordLabel: "Contraseña",
  loginPasswordPlaceholder: "Introduzca su contraseña",
  loginErrorMessage: "Usuario o contraseña incorrectos",
  loginButton: "Acceder",
  loginRestorePassword: "Restablecer contraseña",
  loginNumberVersion: "Número de versión",
  restorePasswordNifPlaceholder: "Introduzca su NIF",
  restorePasswordGoToLogin: "Ir al login",
  restorePasswordButton: "Restablecer contraseña",
  restorePasswordSuccess: "Se ha enviado un correo a la dirección proporcionada",
  restorePasswordError: "Ha ocurrido un error al enviar un correo a la dirección proporcionada",
  idleCountDownFirst: "Su sesion caducará en",
  idleCountDownSecond: "segundos",
  navBarMyMachines: "Mis equipos",
  navBarMySales: "Mis ventas",
  navBarMyReports: "Mis informes",
  navBarMyRentsManagement: "Gestión de alquileres",
  navBarMyAppManagement: "Gestión de la aplicación",
  navBarUserManagement: "Gestión de usuario", 
  navBarExit: "Salir",
  optionsTablePerPage: "por página",
  optionsTableLookFor: "Buscar",
  optionsTablePagination: "Paginación",
  myMachinesUsername: "Concesión (Usuario)",
  myMachinesMachineType: "Tipo de máquina",
  myMachinesSerialNumber: "Número de serie",
  myMachinesBrand: "Marca",
  myMachinesModel: "Modelo",
  myMachinesIsRented: "Alquilado",
  myMachinesRentNumber: "Número de alquileres",
  myMachinesTotalDuration: "Duración total (d)",
  myMachinesRentHours: "Horas alquilado",
  myMachinesBilling: "Facturación",
  myMachinesActualHours: "Horas actuales",
  myMachinesLastCheckList: "Último check list",
  myMachinesNumberFavourites: "Número listado favoritos",
  myMachinesDetail: "Detalle",
  myMachinesActions: "Acciones",
  myMachinesNumberDetails: "Número veces consultados los detalles",
  myMachinesNumberRequests: "Número de solicitudes",
  myMachinesErrorGetMachines: "Ha ocurrido un error al obtener las maquinas del usuario",
  myMachinesSuccessChangeStateEnabled: "La máquina ha sido activada",
  myMachinesSuccessChangeStateDisabled: "La máquina ha sido desactivada",
  myMachinesErrorChangeStateEnabled: "Ha ocurrido un error al activar la máquina",
  myMachinesErrorChangeStateDisabled: "Ha ocurrido un error al desactivar la máquina",
  myMachinesNoData: "No existen equipos para mostrar",
  myMachineDetailTitle1: "Detalle de la máquina del tipo ",
  myMachineDetailSubject: "Concepto",
  myMachineDetailDescription: "Descripción",
  myMachineDetailDate: "Fecha",
  myMachineDetailAmount: "Importe",
  myMachineDetailTitle2: ' de la marca',
  myMachineRevenueErrorMessage: "Ha ocurrido un error al conseguir los ingresos asociados a una máquina",
  myMachineExpenseErrorMessage: "Ha ocurrido un error al conseguir los gastos asociados a una máquina",
  myMachineDetailErrorMessage: "Ha ocurrido un error al conseguir los detalles de una máquina",
  myMachineMinSalePriceErrorMessage: "Ha ocurrido al conseguir el precio mínimo de venta de una máquina",
  myMachineDetailEarningsTitle: "Ingresos asociados a la máquina",
  myMachineDetailExpenseTitle: "Gastos asociados a la máquina",
  myMachineDetailNewExpense: "Nuevo gasto",
  myMachineDetailLiquidationId: "Identificador liquidación",
  myMachineDetailDamage: "Daño",
  myMachineDetailRentAmount: "Precio alquiler",
  myMachineDetailNoEarningData: "No existen ingresos asociados a la máquina",
  myMachineDetailNoExpenseData: "No existen gastos asociados a la máquina",
  myMachineDetailMachineDetailTitle: "DETALLES DE LA MÁQUINA",
  myMachineDetailMachineDetailName: "NOMBRE:",
  myMachineDetailMachineDetailBrand: "MARCA:",
  myMachineDetailMachineDetailModel: "MODELO:",
  myMachineDetailMachineDetailSerialNumber: "NÚMERO DE SERIE:",
  myMachineDetailMachineDetailPlate: "MATRÍCULA:",
  myMachineDetailMachineDetailMinPriceTitle: "PRECIO MÍNIMO DE VENTA",
  myMachineDetailDelete: "Eliminar máquina",
  myMachineDetailDeleteSuccess: "La máquina ha sido eliminada",
  myMachineDetailDeleteError: "Ha ocurrido un error al eliminar la máquina",
  myMachineDetailMaps: "Ver posición de la máquina",
  myMachineDetailCalendar: "Disponibilidad de la máquina",
  myMachineDetailNoCoordinates: "No hay ninguna coordenada asociada a esta máquina",
  mySalesClientName: "Nombre Cliente",
  mySalesRentPlace: "Localización (municipio)",
  mySalesUse: "Uso",
  mySalesFromDate: "Fecha de inicio",
  mySalesToDate: "Fecha de fin",
  mySalesTotalDays: "Días alquiler",
  mySalesInitHours: "Horas inicio",
  mySalesEndHours: "Horas Fin",
  mySalesLiquidationResume: "Resumen de liquidación",
  mySalesRentContract: "Contrato de alquiler",
  mySalesCheckOut: "Check in salida",
  mySalesCheckDelivery: "Check in entrega",
  mySalesCheckCollection: "Check in recogida",
  mySalesCheckIn: "Check in recepción",
  mySalesErrorGetSales: "Ha ocurrido un error al conseguir las ventas del usuario",
  mySalesDocumentNotFoundError: "El documento no existe o no se ha encontrado",
  mySalesDocumentGenericError: "Ha ocurrido un error al conseguir el documento",
  mySalesViewDocument: "Ver documento",
  mySalesNoData: "No existen ventas para mostrar",
  userName: "Nombre",
  userLastName1: "Primer apellido",
  userLastName2: "Segundo apellido",
  userAlias: "Alias",
  userPhone: "Teléfono",
  userAddress: "Dirección",
  postalCode: "Código Postal",
  userCity: "Localidad",
  userProvince: "Provincia",
  userCountry: "País",
  userIdDocument: "NIF",
  userEmail: "Correo electrónico",
  userEdit: "Guardar usuario",
  userChangePassword: "Cambiar contraseña",
  userAvatar: "Avatar",
  userIdDocuments: "Documento de identidad",
  userFrontIdDocument: "Anverso documento de identidad",
  userBackIdDocument: "Reverso documento de identidad",
  userDriverLicense: "Permiso de conducir",
  userDriverLicenseFront: "Anverso permiso de conducir",
  userDriverLicenseBack: "Reverso permiso de conducir",
  userErrorGetUserData: "Ha ocurrido un error al obtener los datos del usuario",
  userSuccessEdit: "Se ha editado el usuario",
  userErrorEdit: "Ha ocurrido un error al guardar el usuario",
  uploadSelectFile: "Seleccionar imagen",
  uploadUploadFile: "Subir",
  uploadInfoUpload: "Solo se pueden subir ficheros jpg, jpeg o png",
  uploadSuccessUpload: "Se ha subido el fichero seleccionado",
  uploadErroUpload: "Ha ocurrido un error al subir el fichero seleccionado",
  uploadErrorReadImage: "Ha ocurrido un error al leer la imagen",
  passwordChangePassText: "Cambiar contraseña",
  passwordNewPass: "Nueva contraseña",
  passwordRepeatNewPass: "Repita nueva contraseña",
  passwordChange: "Cambiar",
  passwordChangeSuccess: 'La contraseña ha sido cambiada',
  passwordChangeError: "La contraseña no ha podido ser cambiada",
  passwordExit: "Salir",
  modalConfirm: "Guardar",
  modalConfirmSaveUserText: "¿Está seguro que desea guardar el usuario?",
  addExpenseTitle: "Añadir gasto",
  addExpenseSubject: "Concepto",
  addExpenseSubjectPlaceholder: "Añade concepto",
  addExpenseDescriptionPlaceholder: "Añade una descripción",
  addExpenseAmount: "Importe",
  addExpenseAmountPlaceholder: "Añade importe",
  addExpenseButton: "Añadir gasto",
  addExpenseSuccessMessage: "Se ha añadido un gasto",
  addExpenseErrorMessage: "Ha ocurrido un error al añadir un gasto",
  myRentManagementIdRent: "Identificador alquiler",
  myRentManagementOwnerAlias: "Alias propietario",
  myRentManagementUserAlias: "Alias usuario",
  myRentManagementRentFrom: "Alquier desde",
  myRentManagementRentTo: "Alquiler hasta",
  myRentManagementErrorMessage: "Ha ocurrido un error al conseguir los alquileres",
  myRentManagementNoData: "No existen alquileres para mostrar",
  myRentDetailTitle1: "Detalle del alquiler de la máquina",
  myRentDetailTitle2: "del propietario",
  myRentDetailTitle3: "y del usuario",
  myRentDetailInsurancePositive: "Seguro habilitado",
  myRentDetailInsuranceNegative: "Seguro no habilitado",
  myRentDetailTransportPositive: "Con transporte",
  myRentDetailTransportNegative: "Sin transporte",
  myRentDetailRentStatus: "Estado del alquiler",
  myRentDetailRentCreationDate: "Fecha solicitud alquiler",
  myRentDetailRentFinalPrice: "Precio final alquiler",
  myRentDetailRentPriceExcess: "Fianza",
  myRentDetailRentTransportPrice: "Precio transporte",
  myRentDetailRentFrom: "Alquiler desde",
  myRentDetailRentTo: "Alquiler hasta",
  myRentDetailRentPlace: "Localización",
  myRentDetailRentLocation: "Coordenadas",
  myRentDetailRentShortcode: "Shortcode",
  myRentDetailErrorMessage: "Ha ocurrido un error al conseguir los detalles del alquiler",
  myRenDetailIncomingPaymentsErrorMessage: "Ha ocurrido un error al conseguir los pagos asociados al alquiler",
  myRentDetailCreationDate: "Fecha de creación",
  myRentDetailTransferenceDate: "Fecha de la transferencia",
  myRentDetailTransferenceData: "Datos de la transferencia",
  myRentDetailIncomingPaymentTitle: "Pagos entrantes",
  myRentDetailNewIncomingPayment: "Nuevo pago entrante",
  myRentDetailConfirmPayment: "Confirmar pago",
  myRentDetailRequestedStatus: "Solicitado",
  myRentDetailAcceptedOwnerStatus: "Aceptado por el propietario",
  myRentDetailAcceptedClientStaus: "Aceptado por el cliente",
  myRentDetailSignedStatus: "Firmado",
  myRentDetailPaidStatus: "Pagado",
  myRentDetailPaymentConfirmedStatus: "Pago confirmado",
  myRentDetailChecklistStatus: "Entregado",
  myRentDetailChecklistTrStatus: "Devuelto",
  myRentDetailSoldOutStatus: "Liquidado",
  myRentDetailCancelledStatus: "Cancelado",
  myRentDetailNoData: "No existen pagos entrantes para mostrar",
  myReportsRentsDurationTitle: "Duración alquileres",
  myRenDetailConfirmPaymentSuccessMessage: "El pago ha sido confirmado",
  myRenDetailConfirmPaymentErrorMessage: "Ha ocurrido un eror al confirmar el pago",
  addIncomingPaymentTitle: "Añadir pago entrante",
  addIncomingShortCodePlaceholder: "Añade shortcode",
  addIncomingTransferenceDataLabel: "Datos de la transferencia",
  addIncomingTransferenceDataPlaceholder: "Añade los datos de la tranferencia",
  addIncomingAddExpsenseButton: "Añadir pago",
  addIncomingPaimentSuccessMessage: "Se ha añadido un nuevo pago",
  addIncomingPaimentErrorMessage: "Ha ocurrido un error al añadir un nuevo pago",
  list_months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  list_days: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  logoutConfirmTitle: "¿Desea cerrar la sesión actual?",
  logoutBackBtn: "Volver",
  logoutExitSessionBtn: "Cerrar sessión",
  deteleMachineConfirmMessage: "¿Está seguro de eliminar la máquina?",
  closeBtn: "Cerrar",
  deleteBtn: "Eliminar",
  appManagementTypeMachinePlaceholder: "Selecciona un tipo de máquina",
  appManagementCategoryByMachineTypePlaceholder: "Selecciona una categoría",
  appManagementMachine: "Marca",
  appManagementAddBrandsBtn: "Añadir marcas",
  appManagementErrorGetMachineTypes: "Ha ocurrido un error al obtener los tipos de máquinas",
  appManagementErrorGetBrandsTypes: "Ha ocurrido un error al obtener los tipos de marcas",
  appManagementErrorAddBrands: "Ha ocurrido un error al añadir las marcas",
  addManagementSuccessAddBrands: "Las marcas ha sido añadidas",
  appManagementErrorGetCategoriesByMachineTypes: "Ha ocurrido un error al conseguir las categorías de la máquina",
  addBrandsNoAssociatedBrandsToMachineType: "No hay marcas asociadas al tipo de máquina seleccionada",
  addBrandsByCategoryNoAssociatedBrandsToCategory1: "No hay marcas asociadas a la categoría seleccionada",
  addBrandsModalTitle: "Selecciona las marcas que desea añadir",
  calendarGetMachineDatesError: "Ha ocurrido un error al obtener las fechas de reserva de la máquina",
  calendarBlockDatesSuccess: "Se han bloqueado los días seleccionados",
  calendarBlockDatesError: "Ha ocurrido un error al bloquear los días seleccionados",
  calendarUnblockDatesSuccess: "Se han desbloqueado los días seleccionados",
  calendarUnblockDatesError: "Ha ocurrido un error al desbloquear los días seleccionados",
  calendarNoBlockedDates: "No hay fechas bloquedas",
  calendarNoUnblockRentDate: "No se puede desbloquear la fecha seleccionada",
  calendarEvents: "Ha ocurrido un error al obtener los eventos del calendario"
};

export default messages;

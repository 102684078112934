<template>
  <v-container id="input-usage" grid-list-xl fluid>
    <section-definition
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    ></section-definition>

    <v-layout wrap>
      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.username"
          :rules="nameRules"
          :counter="50"
          label="Nombre"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.usersurname1"
          :rules="nameRules"
          :counter="50"
          label="Apellido 1"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.usersurname2"
          :rules="nameRules"
          :counter="50"
          label="Apellido 2"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useralias"
          :rules="nameRules"
          :counter="40"
          label="Alias"
          readonly
        ></v-text-field>
      </v-flex>

 <!--     <v-flex xs12 md4>
        <v-text-field
          v-model="userData.userphone"
          :rules="nameRules"
          :counter="12"
          label="Móvil"
          readonly
        ></v-text-field>
      </v-flex>
-->
      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddress1"
          :rules="nameRules"
          :counter="100"
          label="Dirección"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddressnumber"
          :rules="nameRules"
          :counter="10"
          label="C.P."
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddress2"
          :rules="nameRules"
          :counter="50"
          label="Ciudad"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddressprovince"
          :rules="nameRules"
          :counter="50"
          label="Provincia"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.usercountry"
          :rules="nameRules"
          :counter="50"
          label="Pais"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useridentitydocument"
          :rules="nameRules"
          :counter="10"
          label="NIF"
          readonly
        ></v-text-field>
      </v-flex>

  <!--    <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useremail"
          :rules="nameRules"
          :counter="100"
          label="E-mail"
          readonly
        ></v-text-field>
      </v-flex>
-->
 <!--     <v-flex xs12 md4>
        <v-text-field
          v-model="userData.typelabel"
          :counter="100"
          label="Tipo"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md12> </v-flex>
-->
      <v-flex xs12 md12>
        <v-divider inset xs12 md12></v-divider>
      </v-flex>

      <v-flex xs12 md12>
        <v-flex xs12 md4> </v-flex>
      </v-flex>

      <v-flex xs12 md12>
        <v-divider inset xs12 md12></v-divider>
      </v-flex>
      <!--
      <v-flex xs12 md12>
        <v-subheader>Documentos de identidad</v-subheader>
      </v-flex>
      <v-flex xs12 md4>
        <v-field :label="$t('userFrontIdDocument')"></v-field>
        <img height="200px" :src="endPointIdFront" v-if="idFrontImg" />
        <span class="mdi mdi-file-image icon-size" v-else></span>
      </v-flex>

      <v-flex xs12 md4>
        <v-field :label="$t('userFrontIdDocument')"></v-field>
        <v-img height="200px" :src="endPointIdBack" v-if="idBackImg" />
        <span class="mdi mdi-file-image icon-size" v-else></span>
      </v-flex>

      <v-flex xs12 md4>
        <v-field :label="$t('userFrontIdDocument')"></v-field>
        <img
          height="200px"
          :src="endPointDriverLicenseFront"
          v-if="driverLicenseFrontImg"
        />
        <span class="mdi mdi-file-image icon-size" v-else></span>
      </v-flex>

      <v-flex xs12 md4>
        <v-field :label="$t('userFrontIdDocument')"></v-field>
        <img
          height="200px"
          :src="endPointDriverLicenseBack"
          v-if="driverLicenseBackImg"
        />
        <span class="mdi mdi-file-image icon-size" v-else></span>
      </v-flex>

      <v-flex xs12 md12>
        <v-divider inset xs12 md12></v-divider>
      </v-flex>
      <v-flex xs12 md6>

        <v-text-field
          v-model="bankAccount"
          :counter="50"
          label="Número de cuenta"
          readonly
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md12>
        <v-divider inset xs12 md12></v-divider>
      </v-flex>

      <v-container>
        <v-subheader>Documentos SEPA</v-subheader>

        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex v-for="sepadoc in sepadocs" :key="sepadoc" xs12 sm6 md4>
            <v-card>
              <img :src="sepadoc.b64img" width="300px" v-if="sepadoc.isImage" />

              <pdf
                v-if="!sepadoc.isImage"
                :src="sepadoc.b64img"
                @num-pages="sepadoc.pageCount = $event"
                :page="sepadoc.currentPage"
                @page-loaded="sepadoc.currentPage = $event"
                @link-clicked="page = $event"
              ></pdf>
              <span
                class="pl-3 pt-3 green--text"
                v-text="sepadoc.documentstatuslabel"
              ></span>

              <v-card-actions class="white justify-center">
                <div xs12 v-if="!sepadoc.isImage">
                  {{ sepadoc.currentPage }} / {{ sepadoc.pageCount }}
                  <v-btn
                    icon
                    light
                    class="hidden-md-and-down"
                    @click.stop="prevPage(sepadoc)"
                  >
                    <v-tooltip bottom color="sidebar">
                      <v-icon slot="activator">fas fa-arrow-circle-left</v-icon>
                      <span>Volver</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn
                    icon
                    light
                    class="hidden-md-and-down"
                    @click.stop="nextPage(sepadoc)"
                  >
                    <v-tooltip bottom color="sidebar">
                      <v-icon slot="activator"
                        >fas fa-arrow-circle-right</v-icon
                      >
                      <span>Volver</span>
                    </v-tooltip>
                  </v-btn>
                </div>
                <span>{{ sepadoc.documenttypelabel }}</span>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 d-flex> </v-flex>
  
        </v-layout>
      </v-container>
              -->
    </v-layout>

    <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top="true"
    >
      {{ snacktext }}
      <v-btn dark flat @click="snackresult = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import upload from "../../components/upload.vue";
import password from "../../components/modals/password.vue";
import saveUserConfirm from "../../components/modals/saveUserConfirm.vue";
import { EventBus } from "../../eventBus.js";
import pdf from "vue-pdf";
import UploadButton from "vuetify-upload-button";

export default {
  name: "user",
  components: {
    upload,
    password,
    saveUserConfirm,
    pdf,
    "upload-btn": UploadButton
  },
  props: {
    currentuuid: {
      type: String,
      required: true
    },
    currentuserid: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentuseruuid: "",
      currentuserid: 0,
      currentUserType: 1,
      userData: {},
      bankAccount: "",
      bankAccounts: {},
      endPointImages: process.env.VUE_APP_BASE_URI + "/getimagebypathbase64/",
      endPointAvatar: "",
      endPointIdFront: "",
      endPointIdBack: "",
      endPointDriverLicenseFront: "",
      endPointDriverLicenseBack: "",
      srcPointIdFront: String,
      srcPointIdBack: String,
      srcPointDriverLicenseFront: String,
      srcPointDriverLicenseBack: String,
      avatarImg: false,
      idFrontImg: false,
      idBackImg: false,
      driverLicenseFrontImg: false,
      driverLicenseBackImg: false,
      isChangePassword: false,
      isSaveUser: false,
      isModType: false,
      isBankAccountMod: false,
      labels: {
        message: this.$t("modalConfirmSaveUserText"),
        success: this.$t("userSuccessEdit"),
        error: this.$t("userErrorEdit"),
        leftButton: this.$t("passwordExit"),
        rigthButton: this.$t("modalConfirm")
      },
      endPoint:
        process.env.VUE_APP_BASE_URI +
        "/modifyuser/" +
        this.$store.getters.uuid,
      imagetype: String,
      currentIdFile: 0,
      currentSepaFile: "",
      sepadocs: [],
      pagecounts: [],
      passw1: "",
      passw2: "",
      snackresult: false,
      snacktext: "",
      snackcolor: "success",
      currentPage: 0,
      pageCount: 0,
      page: {
        title: "Datos de Usuario",
        headline: "Datos de usuario",
        description: `.`
      },
      breadcrumbs: [
        {
          text: "Admin",
          disabled: false
        },
        {
          text: "Usuarios",
          disabled: false
        },
        {
          text: "Datos Usuario",
          disabled: false
        }
      ],
      items: [
        { nombre: "Certificado de titularidad de cuenta", codigo: "3" },
        { nombre: "Recibo autónomos o modelo 36/37", codigo: "5" },
        { nombre: "Escrituras de Constitución", codigo: "11" },
        { nombre: "CIF", codigo: "12" }
      ],
      userTypes: [
        { nombre: "Normal", codigo: "1" },
        { nombre: "Transportista", codigo: "3" },
        { nombre: "Premium", codigo: "2" },
        { nombre: "Administrador", codigo: "5" }
      ]
    };
  },
  mounted() {
    EventBus.$on("upload", imageObject => {
      this.checkImageType(imageObject.imagetype, imageObject.image);
    });
    const uuid = this.$route.params.currentuuid; //  this.$route.query.id;
    this.currentuseruuid = this.$route.params.currentuuid;
    this.currentuserid = this.$route.params.currentuserid; //  this.$route.query.id;
    if (this.currentuserid == 0) {
      this.getUserDataByUuid(this.currentuseruuid); //'d71ade4e-f2f5-4e2c-a34d-bd8872bd447d')
    } else {
      this.getUserData(this.currentuserid); //'d71ade4e-f2f5-4e2c-a34d-bd8872bd447d')
    }
    this.getSepadocs(uuid);
    // this.getBankAccounts(uuid);
  },
  methods: {
    getBankAccounts(uuid) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getbankaccounts/" + uuid;

      self.$http.get(url).then(
        function(response) {
          var accounts = response.data;
          if (accounts.chargeaccount != null)
            self.bankAccount = accounts.chargeaccount;
        },
        function(error) {
          self.snacktext = "Error obteniendo cuentas del usuario  ";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    },
    getUserData(currentid) {
      const self = this;
      const uuid = self.currentuseruuid;
      let url = process.env.VUE_APP_BASE_URI + "/getuserbyid"; //+ uuid; // self.$store.getters.uuid
      const dataid = {
        iduser: currentid
      };
      self.$http.post(url, dataid).then(
        function(success) {
          let data = {};
          data.username = success.data.userdata.name;
          data.usersurname1 = success.data.userdata.surname1;
          data.usersurname2 = success.data.userdata.surname2;
          data.userphone = success.data.userdata.phone;
          data.useremail = success.data.userdata.email;
          data.useralias = success.data.userdata.alias;
          data.useridentitydocument = success.data.userdata.identitydocument;
          data.useraddress1 = success.data.userdata.address1;
          data.useraddress2 = success.data.userdata.address2;
          data.useraddressnumber = success.data.userdata.addressnumber;
          data.useraddressprovince = success.data.userdata.province;
          data.usercountry = success.data.userdata.country;
          data.type = success.data.userdata.type;
          if (data.type === 1) {
            data.typelabel = "Normal";
          } else if (data.type === 2) {
            data.typelabel = "Premium ";
          } else if (data.type === 3) {
            data.typelabel = "Transportista";
          } else if (data.type === 5) {
            data.typelabel = "Administrador";
          } else {
            data.typelabel = "---";
          }
          data.useruuid = uuid;
          if (success.data.userdata.avatar)
            self.getImages("avatar", self.endPointImages + "avatar/" + uuid);
          if (success.data.userdata.idFront)
            self.getImages("idfront", self.endPointImages + "idfront/" + uuid);
          if (success.data.userdata.idBack)
            self.getImages("idback", self.endPointImages + "idback/" + uuid);
          if (success.data.userdata.dlFront)
            self.getImages("dlfront", self.endPointImages + "dlfront/" + uuid);
          if (success.data.userdata.dlBack)
            self.getImages("dlback", self.endPointImages + "dlback/" + uuid);
          data.useruuid = self.currentuseruuid;
          data.iduser = self.currentuserid;
          self.currentUserType = data.type;

          self.userData = data;
        },
        function(error) {
          self.snacktext = "Error obteniendo datos del usuario  ";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    },
    getUserDataByUuid(currentuuid) {
      const self = this;
      const uuid = self.currentuseruuid;
      let url = process.env.VUE_APP_BASE_URI + "/getuserbyuuid/" + currentuuid; // self.$store.getters.uuid

      self.$http.get(url).then(
        function(success) {
          let data = {};
          data.username = success.data.userdata.name;
          data.usersurname1 = success.data.userdata.surname1;
          data.usersurname2 = success.data.userdata.surname2;
          data.userphone = success.data.userdata.phone;
          data.useremail = success.data.userdata.email;
          data.useralias = success.data.userdata.alias;
          data.useridentitydocument = success.data.userdata.identitydocument;
          data.useraddress1 = success.data.userdata.address1;
          data.useraddress2 = success.data.userdata.address2;
          data.useraddressnumber = success.data.userdata.addressnumber;
          data.useraddressprovince = success.data.userdata.province;
          data.usercountry = success.data.userdata.country;
          data.type = success.data.userdata.type;
          if (data.type === 1) {
            data.typelabel = "Normal";
          } else if (data.type === 2) {
            data.typelabel = "Premium ";
          } else if (data.type === 3) {
            data.typelabel = "Transportista";
          } else if (data.type === 5) {
            data.typelabel = "Administrador";
          } else {
            data.typelabel = "---";
          }
          data.useruuid = uuid;
          if (success.data.userdata.avatar)
            self.getImages("avatar", self.endPointImages + "avatar/" + uuid);
          if (success.data.userdata.idFront)
            self.getImages("idfront", self.endPointImages + "idfront/" + uuid);
          if (success.data.userdata.idBack)
            self.getImages("idback", self.endPointImages + "idback/" + uuid);
          if (success.data.userdata.dlFront)
            self.getImages("dlfront", self.endPointImages + "dlfront/" + uuid);
          if (success.data.userdata.dlBack)
            self.getImages("dlback", self.endPointImages + "dlback/" + uuid);
          data.useruuid = self.currentuseruuid;
          data.iduser = self.currentuserid;
          self.currentUserType = data.type;

          self.userData = data;
        },
        function(error) {
          self.snacktext = "Error obteniendo datos del usuario  ";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    },
    getImages(type, endPoint) {
      const self = this;
      self.$http(endPoint).then(
        function(response) {
          self.checkImageType(type, response.data);
        },
        function(error) {
          console.log(error);
        }
      );
    },
    checkImageType(imagetype, image) {
      if (imagetype.indexOf("avatar") !== -1) {
        this.endPointAvatar = image;
        this.avatarImg = true;
      } else if (imagetype.indexOf("idfront") !== -1) {
        this.endPointIdFront = image;
        this.idFrontImg = true;
      } else if (imagetype.indexOf("idback") !== -1) {
        this.endPointIdBack = image;
        this.idBackImg = true;
      } else if (imagetype.indexOf("dlfront") !== -1) {
        this.endPointDriverLicenseFront = image;
        this.driverLicenseFrontImg = true;
      } else if (imagetype.indexOf("dlback") !== -1) {
        this.endPointDriverLicenseBack = image;
        this.driverLicenseBackImg = true;
      }
    },
    getSepadocs(uuid) {
      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI + "/getsepadocumentbyuseruuid/" + uuid;

      self.$http.get(url).then(
        function(response) {
          var docs = response.data.info.documents;
          for (var i = 0; i < docs.length; i++) {
            if (docs[i].documenttype == 8) {
              docs[i].documenttypelabel = "ANVERSO DNI";
            } else if (docs[i].documenttype == 9) {
              docs[i].documenttypelabel = "REVERSO DNI";
            } else if (docs[i].documenttype == 3) {
              docs[i].documenttypelabel = "CERTIFICADO CUENTA";
            } else if (docs[i].documenttype == 5) {
              docs[i].documenttypelabel = "RECIBO AUTONOMOS";
            } else if (docs[i].documenttype == 12) {
              docs[i].documenttypelabel = "CIF";
            } else if (docs[i].documenttype == 11) {
              docs[i].documenttypelabel = "ESCRITURAS";
            } else {
              docs[i].documenttypelabel = "OTRO";
            }

            if (docs[i].documentstatus == 3) {
              docs[i].documentstatuslabel = "VERIFICADO";
            } else {
              docs[i].documentstatuslabel = "";
            }
            if (docs[i].documentpath.indexOf(".pdf") > 0) {
              docs[i].isImage = false;
              docs[i].currentPage = 1;
              docs[i].pageCount = 1;
            } else {
              docs[i].isImage = true;
            }

            self.downloadImage(docs[i].documentpath, i);
            //docs[i].imgb64 = imgsrc

            docs[i].b64img = "XXXXXX";
            self.sepadocs.push(docs[i]);
          }

          //  self.sepadocs = docs
        },
        function(error) {
          console.log(error);
        }
      );
    },
    nextPage(currentdoc) {
      currentdoc.currentPage++;
      if (currentdoc.currentPage > currentdoc.pageCount)
        currentdoc.currentPage = 1;
    },
    prevPage(currentdoc) {
      currentdoc.currentPage--;
      if (currentdoc.currentPage < 1) currentdoc.currentPage = 1;
    },
    editUser() {
      this.isSaveUser = true;
    },
    changePassw() {
      if (this.passw1.length > 5 && this.passw1 == this.passw2) {
        const self = this;
        let url =
          process.env.VUE_APP_BASE_URI +
          "/changepassword/" +
          this.userData.useruuid;
        var bodyuser = {
          newpassword: this.passw1
        };
        self.$http.post(url, bodyuser).then(
          function(success) {
            self.isChangePassword = false;
            self.snacktext = "La contraseña se ha modificado";
            self.snackcolor = "success";
            self.snackresult = true;
          },
          function(error) {
            self.isChangePassword = false;

            self.snacktext = "Error. La contraseña  no se ha modificado";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
      } else {
        //this.isChangePassword = false

        this.snacktext =
          "Las contraseñas  no coinciden o son demasiado cortas ";
        this.snackcolor = "red";
        this.snackresult = true;
      }
    },
    modUserType() {
      var newType = parseInt(this.currentUserType, 10);
      if (newType > 0 && this.currentUserType != this.userData.type) {
        const self = this;
        let url = process.env.VUE_APP_BASE_URI + "/changeusertype";
        var bodyuser = {
          useruuid: this.userData.useruuid,
          usertype: newType
        };
        self.$http.post(url, bodyuser).then(
          function(success) {
            self.isModType = false;
            self.snacktext = "El tipo de usuario se ha modificado";
            self.snackcolor = "success";
            self.snackresult = true;
            self.getUserData();
          },
          function(error) {
            self.isModType = false;

            self.snacktext = "Error. El tipo de usuario  no se ha modificado";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
      } else {
        //this.isChangePassword = false
        this.isModType = false;
        this.snacktext = "El tipo de usuario es el mismo que el actual ";
        this.snackcolor = "red";
        this.snackresult = true;
      }
    },

    update(file) {
      const fb64 = this.getBase64(file);
    },
    updateSepa(file) {
      const fb64 = this.getBase64Sepa(file);
    },
    setCurrentFile(fileid) {
      this.currentIdFile = fileid;
      // alert(fileid)
    },
    getBase64(file) {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        const fb64 = reader.result;
        if (fb64 != null) {
          if (self.currentIdFile == 1) {
            self.checkImageType("idfront", fb64);
            var imgsrc = fb64.split(",")[1];
            self.uploadImage(imgsrc, "idfront");
          }
          if (self.currentIdFile == 2) {
            //  var imgsrc = fb64;
            self.checkImageType("idback", fb64);
            var imgsrc = fb64.split(",")[1];
            self.uploadImage(imgsrc, "idback");
          }
          if (self.currentIdFile == 3) {
            self.checkImageType("dlfront", fb64);
            var imgsrc = fb64.split(",")[1];
            self.uploadImage(imgsrc, "dlfront");
          }
          if (self.currentIdFile == 4) {
            self.checkImageType("dlback", fb64);
            var imgsrc = fb64.split(",")[1];
            self.uploadImage(imgsrc, "dlback");
          }
        }
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
        return null;
      };
    },
    getBase64Sepa(file) {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        const fb64 = reader.result;
        if (fb64 != null) {
          const num = parseInt(self.currentSepaFile, 10);
          var imgsrc = fb64.split(",")[1];
          self.uploadDocument(imgsrc, num);
        }
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
        return null;
      };
    },
    uploadImage(image, imgtype) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/uploadidimage";
      var bodyuser = {
        base64image: image,
        imageside: imgtype,
        useruuid: this.userData.useruuid
      };
      self.$http.post(url, bodyuser).then(
        function(success) {
          self.snacktext = " La imagen   se ha guardado  ";
          self.snackcolor = "success";
          self.snackresult = true;
        },
        function(error) {
          self.snacktext = "Error. La imagen  no se ha podido  guardar";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    },
    uploadDocument(docu, docutype) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/uploaddocumentsepa";
      var bodyuser = {
        base64document: docu,
        documenttype: docutype,
        documentname: "Documento",
        useruuid: this.$route.params.currentuuid,
        iduser: this.$route.params.currentuserid
      };
      self.$http.post(url, bodyuser).then(
        function(success) {
          self.snacktext = "El documento se ha guardado";
          self.snackcolor = "success";
          self.snackresult = true;
          const uuid = self.$route.query.id;
          self.getSepadocs(uuid);
        },
        function(error) {
          self.snacktext = "Error. El documento no se ha podido guardar";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    downloadImage(imagepath, cont) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/downloadimagefrompath";
      var bodyuser = {
        imagepath: imagepath
      };
      self.$http
        .post(url, bodyuser, {
          responseType: "arraybuffer"
        })
        .then(
          function(success) {
            var b64 = new Buffer(success.data, "binary").toString("base64");
            var imgsrc = "data:image/jpeg;base64," + b64;
            self.sepadocs[cont].b64img = imgsrc;
          },
          function(error) {
            self.snacktext = "Error. La imagen  no se ha podido  recuperar";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
    },

    confirmSave() {
      if (true) {
        const self = this;
        let url =
          process.env.VUE_APP_BASE_URI +
          "/modifyuser/" +
          this.userData.useruuid;

        self.$http.post(url, self.userData).then(
          function(success) {
            self.isSaveUser = false;
            self.snacktext =
              "Los datos de usuario se han modificado modificado";
            self.snackcolor = "success";
            self.snackresult = true;
          },
          function(error) {
            self.isSaveUser = false;

            self.snacktext = "Error. Los datos  no se han modificado";
            self.snackcolor = "red";
            self.snackresult = true;
          }
        );
      }
    },
    confirmSaveAccount() {
      if (true) {
        const self = this;
        let url =
          process.env.VUE_APP_BASE_URI +
          "/addbankaccounts/" +
          this.userData.useruuid;
        const accountData = {
          paymentaccount: this.bankAccount,
          chargeaccount: this.bankAccount
        };

        self.$http.post(url, accountData).then(
          function(success) {
            self.isBankAccountMod = false;
            self.snacktext = "Los datos de cuenta se han modificado ";
            self.snackcolor = "success";
            self.snackresult = true;
          },
          function(error) {
            self.isBankAccountMod = false;

            self.snacktext = "Error. Los datos  no se han modificado";
            self.snackcolor = "red";
            self.snackresult = true;
          }
        );
      }
    }
  }
};
</script>

<style scoped>
.mtop31 {
  margin-top: 31px;
}
.mtop40 {
  margin-top: 40px;
}
.mtop-30 {
  margin-top: -30px;
}
.icon-size {
  font-size: 18pt;
}
.float-right {
  float: right;
}
</style>

<template>
  <v-container grid-list-md text-xs-center>
    <div xs12 sm12>
      <span class="headline font-weight-bold ">Detalle de Transporte</span>
    </div>
    <v-layout row wrap>
      <v-flex xs12 sm6>
        <google-map-only-view />

        <v-carousel cycle="false" height="500" max="400">
          <v-carousel-item
            v-for="(item, i) in images"
            :key="i"
            :src="item.src"
            reverse-transition="fade"
            transition="fade"
            contain
            cycle="false"
          ></v-carousel-item>
        </v-carousel>
      </v-flex>

      <v-flex xs12 sm6>
        <v-card>
          <v-card-text>
            <div class="title">{{ macdata.machinename }}</div>
            <div class="title">{{ macdata.machinebrandname }}</div>
            <div class="subheading">{{ macdata.machinedescription }}</div>
            <v-divider></v-divider>
            <div class="text-sm-left text-xs-left">
              <div>
                Nivel de transporte:
                <span class="font-weight-bold red--text"
                  >{{ macdata.transportlevel }}
                </span>
              </div>
              <div>
                Potencia:
                <span class="font-weight-bold red--text"
                  >{{ macdata.machinepower }} CV</span
                >
              </div>
              <div>
                Propietario:
                <span class="font-italic font-weight-thin red--text">{{
                  macdata.machineowneralias
                }}</span>
              </div>

              <div>
                Localización de equipo:
                <span class="font-italic font-weight-thin red--text">{{
                  macdata.machineplace
                }}</span>
              </div>

              <div>
                Localización de alquiler:
                <span class="font-italic font-weight-thin red--text">{{
                  currentrent.rentplace
                }}</span>
              </div>

              <div>
                Fecha de entrega:
                <span class="font-weight-bold  red--text">{{
                  currentrent.rentfrom
                }}</span>
              </div>
              <div>
                Fecha de recogida:
                <span class="font-weight-bold red--text">{{
                  currentrent.rentto
                }}</span>
              </div>
              <!-- <li v-for="(ingredient, i) in item.recipe.ingredientLines" :key="i">{{ingredient}}</li> -->
              <div>
                Teléfono del propietario:
                <span class="font-italic font-weight-thin red--text">{{
                  ownerData.userphone
                }}</span>
              </div>
              <div>
                Email del propietario:
                <span class="font-italic font-weight-thin red--text">{{
                  ownerData.useremail
                }}</span>
              </div>

              <div>
                Nombre del cliente:
                <span class="font-italic font-weight-thin red--text">{{
                  userData.username
                }}</span>
              </div>

              <div>
                Teléfono del cliente:
                <span class="font-italic font-weight-thin red--text">{{
                  userData.userphone
                }}</span>
              </div>
              <div>
                Email del cliente:
                <span class="font-italic font-weight-thin red--text">{{
                  userData.useremail
                }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-title></v-card-title>
        </v-card>
        <v-card>
          <div class="custom-checkbox" v-for="(itemx, index) in attsType4">
            <v-checkbox
              class="text-sm-left text-xs-left custom-checkbox"
              style="font-size:12;"
              :label="itemx.labelx"
              :value="true"
              readonly="true"
              :input-value="itemx.valuebool"
            ></v-checkbox>
          </div>
          <div class="custom-machineatt"></div>
          <div
            class="text-sm-left text-xs-left custom-machineatt"
            v-for="(itemx, index) in attsType1"
          >
            <span class="text-sm-left text-xs-left custom-check"
              >{{ itemx.label }} : {{ itemx.value }}</span
            >
          </div>

          <div
            class="text-sm-left text-xs-left custom-machineatt"
            v-for="(itemx, index) in attsType3"
          >
            <span class="text-sm-left text-xs-left custom-check"
              >{{ itemx.label }} : {{ itemx.value }}</span
            >
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <!--   <v-text-field
        v-model="search"
        append-icon="search"
        label="Buscar.."
        single-line
      ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="rents"
      class="elevation-1"
      :search="search"
      :loading="isLoading"
      rows-per-page-text="Filas por página"
      :rows-per-page-items="[5,25,50,{text:'todas', value:-1}]"
    >
    <template slot="no-data">
      <v-alert :value="true" color="error" icon="warning">
        Sorry, nothing to display here :(
      </v-alert>
    </template>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.useralias }}</td>
        <td>{{ props.item.idrent }}</td>
        <td class="text-xs-right">{{ props.item.rentprice }}</td>
        <td class="text-xs-right">{{ props.item.rentpriceexcess }}</td>
        <td :class="props.item.stateclass">
          <template v-slot:props.item.rentstatuslabel="{ item }">
            <v-chip :color="getColor(props.item.rentstatus)" dark>{{ props.item.rentstatuslabel }}</v-chip>
          </template>
        </td>
        <td :class="props.item.stateclass">{{ props.item.rentphaselabel }}</td>
        <td class="text-xs-right">{{ props.item.rentplace }}</td>
        <td class="text-xs-right">{{ props.item.transportNeededlabel }}</td>
        <td class="text-xs-right">{{ props.item.transportPrice }}</td>
        <td class="text-xs-right">{{ props.item.rentfrom }}</td>
        <td class="text-xs-right">{{ props.item.rentto }}</td>
      </template>
    </v-data-table>
    -->
  </v-container>
</template>

<script>
import GoogleMapOnlyView from "../../components/Maps/GoogleMapOnlyView";
import { EventBus } from "../../eventBus";

//EventBus.$on('newAddress', clickCount => {
//  console.log(`Oh, that's nice. It's gotten ${clickCount} clicks! :)`)
//});

export default {
  name: "MachineDetail",
  props: ["id"],

  data() {
    return {
      images: [],
      uuid: "",
      rentuuid: "",
      currentrent: {},
      machineuuid: "",
      macdata: {},
      userData: {},
      ownerData: {},
      machinePhotos: [],
      isLoading2: false,
      hasTransport: false,
      hasInsurance: false,
      attsType1: [],
      attsType2: [],
      attsType3: [],
      attsType4: [],
      search: "",
      caltype: "month",
      calstart: "2019-07-12",
      calend: "2020-01-06",
      blockeddays: ["2019-07-16", "2019-07-17"],
      currentMonth: 8,
      rents: [],
      date: null,
      headers: [
        { text: "Arrendatario", value: "useralias" },
        { text: "ID alquiler", value: "idrent" },
        { text: "Precio (€)", value: "rentprice" },
        { text: "Fianza (€)", value: "rentpriceexcess" },
        { text: "Estado", value: "rentstatuslabel" },
        { text: "Fase", value: "rentphase" },
        { text: "Lugar de entrega", value: "rentplace" },
        { text: "Transporte?", value: "transportNeeded" },
        { text: "Precio transporte (€)", value: "transportPrice" },
        { text: "Fecha inicio ", value: "rentfrom" },
        { text: "Fecha fin ", value: "rentto" }
      ]
    };
  },
  components: {
    GoogleMapOnlyView
  },
  created: function() {
    this.$on("newAddress", section => {
      console.log(section);
    });
  },
  mounted: function() {
    //this.getop(this.$route.query.id);
    //  this.uuid = this.$route.query.id;
    //   this.getRents(this.$route.query.id);
    this.machineuuid = this.$route.params.currentmachine;
    this.rentuuid = this.$route.params.currentrentuuid;
    // this.getop(this.$route.query.id);
    this.getop(this.machineuuid);
    this.getRent(this.rentuuid);
  },
  computed: {},
  methods: {
    onNewAddress(someValue) {
      console.log(someValue);
    },

    getop: function(opuuid) {
      const _this = this;
      this.isLoading2 = true;
      const url = process.env.VUE_APP_BASE_URI + "/getmachinebyuuid/" + opuuid;
      _this.$http
        .get(url) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log(response);
            _this.isLoading2 = false;
            _this.macdata = response.data.machinedata;
            if (_this.macdata.machineinsuranceavailable == 1)
              _this.hasInsurance = true;
            if (_this.macdata.machinetransportavailable == 1)
              _this.hasTransport = true;
            _this.macdata.machineattributes.forEach(element => {
              if (element.attributetype == 1) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                if (element.attributename == "Potencia") var aa = 0;
                else _this.attsType1.push(attnew);
              } else if (element.attributetype == 3) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                _this.attsType3.push(attnew);
              } else if (element.attributetype == 4) {
                var attnew = {};
                attnew.labelx = element.attributename;
                if (element.attributevalueyesno == 0) attnew.valuebool = false;
                else attnew.valuebool = true;
                _this.attsType4.push(attnew);
              }
            });
            if (_this.macdata.machinelocation != null) {
              EventBus.$emit("setMacAddress", _this.macdata.machinelocation);
            }

            _this.$http
              .get(
                process.env.VUE_APP_BASE_URI +
                  "/getphotosformachine/" +
                  response.data.machinedata.machineuuid
              )
              .then(
                function(response) {
                  console.log("" + response);
                  //  this.isLoading2 = false;
                  this.machinePhotos = response.data;
                  for (var a = 0; a < this.machinePhotos.length; a++) {
                    var newimg = {};
                    newimg.src =
                      process.env.VUE_APP_BASE_URI +
                      "/getimage/" +
                      this.machinePhotos[a].imageuuid;
                    this.images.push(newimg);
                  }
                }.bind(this)
              )
              .catch(
                function(error) {
                  console.log(error);
                  // this.isLoading2 = false;
                }.bind(this)
              );
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            this.isLoading2 = false;
          }.bind(this)
        );

      const url2 =
        process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + opuuid;
      _this.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
            this.blockeddays = response.data.blockeddays;
            //                  for (var a = 0; a < this.machinePhotos.length; a++) {
            //                    var newimg = {};
            //                    newimg.src =
            //                      "https://ploou-produccion.appspot.com/getimage/" +
            //                      this.machinePhotos[a].imageuuid;
            //                    this.images.push(newimg);
            //                  }
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            // this.isLoading2 = false;
          }.bind(this)
        );
    },
    getRent(rentuuid) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getrentbyuuid/" + rentuuid;
      self.isLoading = true;
      self.$http.get(url).then(
        function(success) {
          var renttemp = success.data;

          self.isLoading = false;

          if (renttemp.rentstatus < 10) {
            renttemp.rentfrom = self
              .moment(renttemp.rentfrom)
              .format("DD-MM-YYYY")
              .split("T")[0];
            renttemp.rentto = self
              .moment(renttemp.rentto)
              .format("DD-MM-YYYY")
              .split("T")[0];
            if (renttemp.rentstatus === 1) {
              renttemp.rentstatuslabel = "Solicitado";
            }
            if (renttemp.rentstatus === 2) {
              renttemp.rentstatuslabel = "Aprobado";
            }
            if (renttemp.rentstatus >= 3 && renttemp.rentstatus < 6) {
              renttemp.rentstatuslabel = "Firmado";
            }
            if (renttemp.rentstatus === 6) {
              renttemp.rentstatuslabel = "Entrega";
            }
            if (renttemp.rentstatus === 7) {
              renttemp.rentstatuslabel = "Recogida";
            }
            if (renttemp.rentstatus === 8) {
              renttemp.rentstatuslabel = "Liquidación";
            }
            if (renttemp.rentstatus === 9) {
              renttemp.rentstatuslabel = "Finalizado";
            }

            if (renttemp.rentstatus >= 6 && renttemp.rentstatus < 9) {
              if (renttemp.rentphase === 1) {
                renttemp.rentphaselabel = "Salida";
              }
              if (renttemp.rentphase === 2) {
                renttemp.rentphaselabel = "Entrega";
              }
              if (renttemp.rentphase === 3) {
                renttemp.rentphaselabel = "Recogida";
              }
              if (renttemp.rentphase === 4) {
                renttemp.rentphaselabel = "Devolución";
              }
            } else {
              renttemp.rentphaselabel = " ";
            }
          }
          self.currentrent = renttemp;
          if (self.currentrent.rentplace != null) {
            EventBus.$emit("setAdditionalAddress", self.currentrent.rentplace);
          }

          self.getUserData(renttemp.iduser, 1);
          self.getUserData(renttemp.iduserowner, 2);
        },
        function(error) {
          self.isLoading = false;

          self.snacktext = "Error al obtener datos del alquiler";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    getRents(opuuid) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getrentsformachine/" + opuuid;
      self.isLoading = true;
      self.$http.get(url).then(
        function(success) {
          var paymentstemp = success.data.rents;
          var rentsfinal = [];
          self.isLoading = false;

          self._.forEach(paymentstemp, function(expense, index) {
            if (expense.rentstatus < 10) {
              paymentstemp[index].rentfrom = self
                .moment(expense.rentfrom)
                .format("DD-MM-YYYY")
                .split("T")[0];
              paymentstemp[index].rentto = self
                .moment(expense.rentto)
                .format("DD-MM-YYYY")
                .split("T")[0];
              if (expense.rentstatus === 1) {
                paymentstemp[index].rentstatuslabel = "Solicitado";
              }
              if (expense.rentstatus === 2) {
                paymentstemp[index].rentstatuslabel = "Aprobado";
              }
              if (expense.rentstatus >= 3 && expense.rentstatus < 6) {
                paymentstemp[index].rentstatuslabel = "Firmado";
              }
              if (expense.rentstatus === 6) {
                paymentstemp[index].rentstatuslabel = "Entrega";
              }
              if (expense.rentstatus === 7) {
                paymentstemp[index].rentstatuslabel = "Recogida";
              }
              if (expense.rentstatus === 8) {
                paymentstemp[index].rentstatuslabel = "Liquidación";
              }
              if (expense.rentstatus === 9) {
                paymentstemp[index].rentstatuslabel = "Finalizado";
              }

              if (expense.transportNeeded === 1) {
                paymentstemp[index].transportNeededlabel = "SI";
              } else {
                paymentstemp[index].transportNeededlabel = "NO";
              }

              if (expense.rentstatus >= 6 && expense.rentstatus < 9) {
                if (expense.rentphase === 1) {
                  paymentstemp[index].rentphaselabel = "Salida";
                }
                if (expense.rentphase === 2) {
                  paymentstemp[index].rentphaselabel = "Entrega";
                }
                if (expense.rentphase === 3) {
                  paymentstemp[index].rentphaselabel = "Recogida";
                }
                if (expense.rentphase === 4) {
                  paymentstemp[index].rentphaselabel = "Devolución";
                }
              } else {
                paymentstemp[index].rentphaselabel = " ";
              }

              if (expense.idpaymentstatus === 1) {
                paymentstemp[index].stateclass = "state1";
                paymentstemp[index].idpaymentstatus = "PENDIENTE";
              }
              if (expense.idpaymentstatus === 2) {
                paymentstemp[index].stateclass = "state2";
                paymentstemp[index].idpaymentstatus = "PAGADO";
              }
              if (expense.idpaymentstatus === 3) {
                paymentstemp[index].stateclass = "state3";
                paymentstemp[index].idpaymentstatus = "RECHAZADO";
              }
              rentsfinal.push(paymentstemp[index]);
            }
          });
          self.rents = rentsfinal;
        },
        function(error) {
          self.isLoading = false;

          self.snacktext = "Error al obtener lista de usuarios";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    getColor(status) {
      if (status > 7) return "red";
      else if (status > 2) return "orange";
      else return "green";
    },
    getUserData(userid, useractor) {
      const self = this;
      const uuid = self.currentuseruuid;
      let url = process.env.VUE_APP_BASE_URI + "/getuserbyid"; //+ uuid; // self.$store.getters.uuid
      const dataid = {
        iduser: userid
      };
      self.$http.post(url, dataid).then(
        function(success) {
          let data = {};
          data.username = success.data.userdata.name;
          data.usersurname1 = success.data.userdata.surname1;
          data.usersurname2 = success.data.userdata.surname2;
          data.userphone = success.data.userdata.phone;
          data.useremail = success.data.userdata.email;
          data.useralias = success.data.userdata.alias;
          data.useridentitydocument = success.data.userdata.identitydocument;
          data.useraddress1 = success.data.userdata.address1;
          data.useraddress2 = success.data.userdata.address2;
          data.useraddressnumber = success.data.userdata.addressnumber;
          data.useraddressprovince = success.data.userdata.province;
          data.usercountry = success.data.userdata.country;
          data.type = success.data.userdata.type;
          if (data.type === 1) {
            data.typelabel = "Normal";
          } else if (data.type === 2) {
            data.typelabel = "Premium ";
          } else if (data.type === 3) {
            data.typelabel = "Transportista";
          } else if (data.type === 5) {
            data.typelabel = "Administrador";
          } else {
            data.typelabel = "---";
          }
          data.useruuid = uuid;

          if (useractor == 1) {
            self.userData = data;
          } else {
            self.ownerData = data;
          }
        },
        function(error) {
          self.snacktext = "Error obteniendo datos del usuario  ";
          self.snackcolor = "warning";
          self.snackresult = true;
        }
      );
    }
  }
};
</script>
<style lang="stylus">
#example-custom-transition {
  .fade {
    &-enter-active, &-leave-active, &-leave-to {
      transition: 0.3s ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }
}

.custom-checkbox {
  height: 20px;
  font-size: 12;
  margin-left: 5px;
}

.custom-machineatt {
  height: 30px;
  font-size: 12;
  margin-left: 15px;
}

.v-label {
  font-size: 14px;
  font-color: black;
}
</style>

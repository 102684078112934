<template>
  <div>
    <v-layout>
      <span class="color-purple">ALQUILER</span>
      <span class="color-blue">OTROS</span>
      <span class="color-turquoise">DEMO</span>
      <span class="color-green">RESERVA DEMO</span>
    </v-layout>
    <vue-calendar
      class="pa-3"
      :events="events"
      :show-limit="4"
    ></vue-calendar>
  </div>
</template>

<script>
// https://github.com/Trekels/vue2-calendar
export default {
  name: "Calendar",
  data() {
    return {
      events: []
    }
  },
  created() {
    this.getCalendar();
  },
  methods: {
    getCalendar() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/getmachinescalendar`)
        .then(
          (response) => {
            this.mapEvents(response.data);
          },
          () => {
            this.$toast.open({
              duration: 5000,
              message: this.$t("calendarEvents"),
              type: "is-error"
            });
          }
        );
    },
    mapEvents(events) {
      this.events = events.map(event => {
        return {
          start: event.blockedday,
          title: `${event.machinename}`.trim(),
          classes: [this.getClass(event.idreason)],
          id: event.idrent
        }
      });
    },
    getClass(idReason) {
      if (idReason === 1) { // ALQUILER
        return "color-purple";
      } else if (idReason === 2) { // OTROS
        return "color-blue";
      } else if (idReason === 3) { // DEMO
        return "color-turquoise";
      } else if (idReason === 4) { // DEMO_BOOKING
        return "color-green";
      }
      return "";
    }
  }
};
</script>

<style lang="css">
.color-purple {
  max-width: 150px;
  background-color: #9B59B6 ! important;
  color: white !important;
}
.color-blue {
  max-width: 150px;
  background-color: #2980B9! important;
  color: #fff !important;
}
.color-turquoise {
  max-width: 150px;
  background-color: #48C9B0! important;
  color: black !important;
}
.color-green {
  max-width: 150px;
  background-color: #58D68D! important;
  color: black !important;
}

span {
  padding: 5px 15px;
  display: inline-block;
  justify-content: center;
  border-radius: 15px;
}
span:first-child {
  margin-left: 20px;
}
span:not(:last-child) {
  margin-right: 20px;
}
</style>

<template>
  <div>
    <section-definition
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    ></section-definition>

    <v-container fluid grid-list-xl page>
      <v-layout row wrap>
        <v-flex xs6 md3>
          <download-excel
            :data="payments"
            :fields="json_fields"
            name="Pagos.xls"
            worksheet="Pagos"
          >
            <v-btn color="act" class="text--white green bold btnfixed" round  dark bold outline >Descargar Excel</v-btn>
          </download-excel>
        </v-flex>
        <v-flex xs6 md3>
          <download-excel
            :data="payments"
            :fields="json_fields"
            name="Pagos.csv"
            type="csv"
            worksheet="Pagos"
          >
            <v-btn color="act" class="text--white green bold btnfixed" round  dark bold outline >Descargar CSV</v-btn>
          </download-excel>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar.."
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="payments"
            :search="search"
            :loading="isLoading"
            rows-per-page-text="Filas por página"
            :rows-per-page-items="[15, 30, 50, { text: 'todas', value: -1 }]"
      class="elevation-1 fixed-header v-table__overflow"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>

            <template slot="items" slot-scope="props">
              <td>{{ props.item.useralias }}</td>
              <td>
                <v-btn outline @click="gotoRentDetail(props.item)">{{
                  props.item.idrent
                }}</v-btn>
              </td>
              <td class="text-xs-right">{{ props.item.paymentamount }}</td>
              <td class="text-xs-right">{{ props.item.amountpayed }}</td>
              <td :class="props.item.stateclass">
                {{ props.item.idpaymentstatus }}
              </td>
              <td class="text-xs-right">{{ props.item.machinename }}</td>
              <td class="text-xs-right">{{ props.item.paymenttypelabel }}</td>
              <td class="text-xs-right">{{ props.item.transactiondate }}</td>
              <td class="text-xs-right">{{ props.item.paymentdate }}</td>
              <td class="text-xs-center text-primary">
                {{ props.item.invoicedlabel }}
              </td>
              <td class="justify-center layout px-0 ">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(props.item)"
                  v-if="props.item.paymentenabled"
                >
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="invoicedItem(props.item)" v-if="props.item.invoicedlabel === 'NO' && props.item.idpaymentstatus !== 'RECHAZADO' && props.item.idpaymentstatus !== 'CANCELADO'">
                  check_circle
                </v-icon>
                <v-icon small class="mr-2" @click="uploadInvoice(props.item)" v-if="props.item.invoicepath === null && props.item.invoiceuuid === null">
                  attach_file
                </v-icon>
                <v-icon small class="mr-2" @click="showInvoice(props.item)" v-if="props.item.invoicepath !== null && props.item.invoiceuuid !== null">
                  visibility
                </v-icon>
              </td>
            </template>
            <template slot="pageText" slot-scope="props">
              Filas {{ props.pageStart }} - {{ props.pageStop }} de
              {{ props.itemsLength }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              La búsqueda de "{{ search }}" no ha encontrado resultados.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-layout>

      <v-dialog v-model="isChangePaymentDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Confirmar pago</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    v-model="editedItem.amountpayed"
                    label="Importe pagado"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              @click.native="isChangePaymentDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" @click.native="confirmSave"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isAddInvoiceDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Aportar factura</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <upload-btn
                  @file-update="updatefile"
                  @click.native="setCurrentFile(3)"
                  title="Elegir archivo"
                  noTitleUpdate="true"
                ></upload-btn>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-label>{{ selectedFileName }}</v-label>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              @click.native="isAddInvoiceDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" @click.native="uploadInvoiceToServer"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isSetInvoiceDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Marcar como facturado</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <span class="">El pago se marcará como facturado </span>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              @click.native="isSetInvoiceDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" @click.native="setInvoiced"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isShowInvoiceDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Ver factura</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <iframe
                  :src="b64Doc"
                  frameborder="0"
                  width="100%"
                  height="550"
                  marginheight="0"
                  marginwidth="0"
                  id="pdfx"
                ></iframe>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              @click.native="isShowInvoiceDialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackresult"
        :color="snackcolor"
        :timeout="6000"
        top="true"
      >
        {{ snacktext }}
        <v-btn dark flat @click="snackresult = false">
          Cerrar
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
/*
amountpayed: 900
idpaymentstatus: 2
idrent: 488
machinename: "Ter"
owneralias: "Marta12"
paymentamount: 900
paymentdata: "959999/018687897529827156149505050702"
paymentdate: "Mon, 05 Aug 2019 00:00:00 GMT"
paymenttype: 1
rentfrom: "Tue, 20 Aug 2019 00:00:00 GMT"
rentto: "Wed, 28 Aug 2019 00:00:00 GMT"
transactiondate: "Fri, 19 Jul 2019 11:48:26 GMT"
useralias: "Marta12"
*/
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import PlainTableOrder from "@/components/Widgets/list/PlainTableOrder";

import UploadButton from "vuetify-upload-button";

export default {
  mixins: [validationMixin],
  data() {
    return {
      search: this.$store.state.paymentSearch,
      headers: [
        { text: "Usuario", value: "useralias" },
        { text: "ID alquiler", value: "idrent" },
        {
          text: "Importe (€)",
          align: "left",
          value: "paymentamount"
        },
        {
          text: "Pagado(€)",
          align: "left",
          value: "amountpayed"
        },
        { text: "Estado", value: "idpaymentstatus" },
        { text: "Equipo ", value: "machinename" },
        { text: "Tipo de pago", value: "paymenttype" },
        { text: "Fecha transac. ", value: "transactiondate" },
        { text: "Fecha pago ", value: "paymentdate" },
        { text: "Facturado ", value: "invoiced" },
        { text: "Acciones ", value: "", sortable: false }
      ],
      payments: [],
      isLoading: false,
      isChangePaymentDialog: false,
      isShowInvoiceDialog: false,
      isSetInvoiceDialog: false,
      isAddInvoiceDialog: false,
      selectedFileName: "xxx",
      selectedFileB64: "",
      b64Doc: "",
      myFiles: [],
      editedIndex: -1,
      editedItem: {},
      snackbar: false,
      page: {
        title: "Pagos",
        headline: "Listado de Pagos",
        description: `Pagos de alquileres.`
      },
      breadcrumbs: [
        {
          text: "Pagos",
          disabled: false
        }
      ],
      snackresult: false,
      snacktext: "",
      snackcolor: "success",

      json_fields: {
        Arrendatario: "useralias",
        "ID Alquiler": "idrent",
        "Importe (€)": {
          field: "paymentamount",
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0) {
              var newvalue = valstr.replace(".", ",");
              return newvalue;
            }
            return value;
          }
        },
        "Pagado (€)": {
          field: "amountpayed",
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0) {
              var newvalue = valstr.replace(".", ",");
              return newvalue;
            }
            return value;
          }
        },
        Estado: "idpaymentstatus",
        Equipo: "machinename",
        "Tipo de Pago": "paymenttypelabel",
        "Fecha transac.": "transactiondate",
        "Fecha pago": "paymentdate",
        Facturado: "invoicedlabel"
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  components: {
    PlainTableOrder,
    "upload-btn": UploadButton
  },
  watch: {
    search() {
      this.$store.commit('setPaymentSearch', this.search);
    }
  },
  mounted: function() {
    this.loadData();
    console.log("mounted: got here");
  },
  methods: {
    handleFilePondInit: function() {
      // FilePond instance methods are available on `this.$refs.pond`
    },
    loadData() {
      const self = this;
      let uuid = this.$store.getters.uuid;

      let url =
        process.env.VUE_APP_BASE_URI + "/gettotalpaymentsforuser/" + uuid;
      self.isLoading = true;
      self.$http.get(url).then(
        function(success) {
          var paymentstemp = success.data.paymentlist;
          self._.forEach(paymentstemp, function(expense, index) {
            var convdate = self
              .moment(expense.transactiondate)
              .format("DD-MM-YYYY")
              .split("T")[0];
            if( convdate == "Invalid date"){
              convdate = "";
            }
            paymentstemp[index].transactiondate = convdate
            var convdate2 = self
              .moment(expense.paymentdate)
              .format("DD-MM-YYYY")
              .split("T")[0];
            if( convdate2 == "Invalid date"){
              convdate2 = "";
            }              
            paymentstemp[index].paymentdate = convdate2;

            if (expense.paymenttype === 1) {
              paymentstemp[index].paymenttypelabel = "Tarjeta";
            }
            if (expense.paymenttype === 2) {
              paymentstemp[index].paymenttypelabel = "Adeudo ";
            }
            if (expense.paymenttype === 3) {
              paymentstemp[index].paymenttypelabel = "Transferencia";
            }

            if (expense.idpaymentstatus == 1) {
              paymentstemp[index].paymentenabled = true;
            } else {
              paymentstemp[index].paymentenabled = false;
            }
            if (expense.idpaymentstatus === 1) {
              paymentstemp[index].stateclass = "state1";
              paymentstemp[index].idpaymentstatus = "PENDIENTE";
            }
            if (expense.idpaymentstatus === 2) {
              paymentstemp[index].stateclass = "state2";
              paymentstemp[index].idpaymentstatus = "PAGADO";
            }
            if (expense.idpaymentstatus === 3) {
              paymentstemp[index].stateclass = "state3";
              paymentstemp[index].idpaymentstatus = "RECHAZADO";
            }
            if (expense.idpaymentstatus === 4) {
              paymentstemp[index].stateclass = "state4";
              paymentstemp[index].idpaymentstatus = "CANCELADO";
            }
            if (expense.idpaymentstatus === 5) {
              paymentstemp[index].stateclass = "state5";
              paymentstemp[index].idpaymentstatus = "PARCIAL";
            }
            if (expense.invoiced == 1) {
              expense.invoicedlabel = "SI";
              expense.canAttach = true;
            } else {
              expense.invoicedlabel = "NO";
              expense.canAttach = false;
            }
          });
          self.payments = paymentstemp;
          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de transportes";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
      /*     const _this=this;
      
        const { data } =  PaymentsRepository.listPayments().then(
                function(success) {
                    _this.$store.commit('', false)
                    //this.$router.push({path: '/myMachines'})
                    //his.posts = data;
                    _this.$router.push({
                        name: "dashboard/Dashboardv1"
                    });

                }).catch(function(error) {
                    _this.loginError = true
                }
            )
*/
    },

    editItem(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.amountpayed = this.editedItem.paymentamount;
      this.isChangePaymentDialog = true;
    },
    invoicedItem(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.isSetInvoiceDialog = true;
    },
    uploadInvoice(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.isAddInvoiceDialog = true;
    },
    showInvoice(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI +
        "/getdocumentbyuuidbase64/" +
        item.invoiceuuid;

      self.$http.get(url).then(
        function(success) {
          var imgsrc = "data:application/pdf;base64," + success.data;
          self.b64Doc = imgsrc;
        },
        function(error) {
          self.isSaveUser = false;

          self.snacktext =
            "Error. No se ha recuperado el documento de la factura";
          self.snackcolor = "red";
          self.snackresult = true;
        }
      );
      this.isShowInvoiceDialog = true;
    },
    gotoRentDetail: function(item) {
      this.$router.push({
        name: "pages/RentDetailPage",
        params: {
          currentuuid: item.rentuuid
        }
      });
    },

    confirmSave() {
      let item = this.editedItem;
      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI +
        "/newpaymentforpremium/" +
        item.rentuuid;
      //  item.amountpayed = item.paymentamount
      let numberAmount = parseFloat(item.amountpayed);
      let paydata = {
        idpayment: item.idpayment,
        idrent: item.idrent,
        paymentamount: numberAmount
      };
      self.$http.post(url, paydata).then(
        function(success) {
          self.isChangePaymentDialog = false;
          self.snacktext = "El estado del pago  se ha modificado";
          self.snackcolor = "success";
          self.snackresult = true;
          self.loadData();
          self.isChangePaymentDialog = false
        },
        function(error) {
          self.isSaveUser = false;
          self.snacktext = "Error. Los datos  no se han modificado";
          self.snackcolor = "red";
          self.snackresult = true;
        }
      );
    },

    setInvoiced() {
      let item = this.editedItem;
      if (true) {
        const self = this;
        let url = process.env.VUE_APP_BASE_URI + "/uploadinvoiceforpayment";
        //  item.amountpayed = item.paymentamount
        let paydata = {
          idpayment: item.idpayment,
          base64document: ""
        };
        self.$http.post(url, paydata).then(
          function(success) {
            self.isChangePaymentDialog = false;
            self.snacktext = "El pago se ha marcado como facturado";
            self.snackcolor = "success";
            self.snackresult = true;
            self.loadData();
            self.isSetInvoiceDialog = false
          },
          function(error) {
            self.isSaveUser = false;
            self.snacktext = "Error. Los datos  no se han modificado";
            self.snackcolor = "red";
            self.snackresult = true;
          }
        );
      }
    },

    updatefile(file) {
      const self = this;
      self.selectedFileName = file.name;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        const fb64 = reader.result;
        if (fb64 != null) {
          self.selectedFileB64 = fb64;
        }
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
        return null;
      };
    },

    uploadInvoiceToServer() {
      let item = this.editedItem;
      if (true) {
        const self = this;
        let url = process.env.VUE_APP_BASE_URI + "/uploadinvoiceforpayment";
        var base64result = self.selectedFileB64.split(",")[1];

        let paydata = {
          idpayment: item.idpayment,
          base64document: base64result
        };
        self.$http.post(url, paydata).then(
          function(success) {
            self.isChangePaymentDialog = false;
            self.snacktext =
              "El pago se ha marcado como facturado y se ha añadido factura";
            self.snackcolor = "success";
            self.snackresult = true;
            self.loadData();
            self.isAddInvoiceDialog = false
          },
          function(error) {
            self.isSaveUser = false;

            self.snacktext = "Error. Los datos no se han modificado";
            self.snackcolor = "red";
            self.snackresult = true;
          }
        );
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.section-def
  margin-bottom: 10px
.toobar-extension
  margin-left: 5px !important
.breadcrumb-section
  margin-left: -25px !important
.action-btn
  margin-left: 5px !important

.state1{
  background-color :blue;

  color : #ffffff;

}

.state2{
  background-color :green;
  color : #ffffff;

}

.state3{
  background-color: red;
  color: #ffffff;

}

.state4{
  background-color: #CC0000;
  color: #ffffff;

}

.state5{
  background-color :orange;
  color : #ffffff;

}

.thead {
  background: green;
}
.v-datatable thead th.column.sortable  {
  background-color: green;
}
</style>

<template>
  <section>
    <b-field class="file">
      <b-upload v-model="file" @input="checkExtension">
        <a class="button is-primary">
          <b-icon icon="upload"></b-icon>
          <span>{{ $t("uploadSelectFile") }}</span>
        </a>
      </b-upload>
      <button class="button is-primary mleft10" v-if="file" @click="uploadFile">
        {{ $t("uploadUploadFile") }}
      </button>
    </b-field>
    <span class="filename" v-if="file">
      {{ file.name }}
    </span>
  </section>
</template>

<script>
import { EventBus } from "../eventBus.js";
export default {
  name: "upload",
  props: ["imagetype"],
  data() {
    return {
      file: null,
      data: {}
    };
  },
  methods: {
    checkExtension() {
      if (!this.file.name.match(/jpg|jpeg|png/)) {
        this.file = null;
        this.$toast.open({
          duration: 5000,
          message: this.$t("uploadInfoUpload"),
          type: "is-info"
        });
      }
    },
    uploadFile() {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/uploadidimage";
      self.data.useruuid = self.$store.getters.uuid;
      self.data.imageside = self.imagetype;
      let reader = new FileReader();
      reader.onload = function() {
        self.data.base64image = reader.result.split(",")[1];
        self.$http.post(url, self.data).then(
          function() {
            self.file = null;
            EventBus.$emit("upload", {
              image: "data:image/jpeg;base64," + self.data.base64image,
              imagetype: self.imagetype
            });
            self.$toast.open({
              duration: 5000,
              message: self.$t("uploadSuccessUpload"),
              type: "is-success"
            });
          },
          function() {
            self.$toast.open({
              duration: 5000,
              message: self.$t("uploadErroUpload"),
              type: "is-danger"
            });
          }
        );
      };
      reader.onerror = function() {
        self.$toast.open({
          duration: 5000,
          message: self.$t("uploadErrorReadImage"),
          type: "is-danger"
        });
      };
      reader.readAsDataURL(self.file);
    }
  }
};
</script>

<style>
.mleft10 {
  margin-left: 11px;
}
.filename {
  font-size: 10px;
  font-weight: bold;
}
.button {
  border-radius: 4px !important;
}
</style>

<template>
  <v-container id="input-usage" grid-list-xl fluid>
    <section-definition
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    ></section-definition>

    <v-layout wrap>
      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.username"
          :rules="nameRules"
          :counter="50"
          label="Nombre"
          required
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.usersurname1"
          :rules="nameRules"
          :counter="50"
          label="Apellido 1"
          required
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.usersurname2"
          :rules="nameRules"
          :counter="50"
          label="Apellido 2"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useralias"
          :rules="nameRules"
          :counter="40"
          label="Alias"
          required
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.userphone"
          :rules="nameRules"
          :counter="12"
          label="Móvil"
          required
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useremail"
          :rules="nameRules"
          :counter="100"
          label="E-mail"
          required
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddress1"
          :rules="nameRules"
          :counter="100"
          label="Dirección"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddressnumber"
          :rules="nameRules"
          :counter="10"
          label="C.P."
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddress2"
          :rules="nameRules"
          :counter="50"
          label="Ciudad"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useraddressprovince"
          :rules="nameRules"
          :counter="50"
          label="Provincia"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.country"
          :rules="nameRules"
          :counter="50"
          label="Pais"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-text-field
          v-model="userData.useridentitydocument"
          :rules="nameRules"
          :counter="10"
          label="NIF"
          required
        ></v-text-field>
      </v-flex>


      <v-flex xs12 md12>
        <!--
    <v-dialog v-model="isChangePassword" persistent max-width="500px">
      <v-btn slot="activator" color="act" dark>Cambiar contraseña</v-btn>
      <v-card>
          
        <v-card-title>
          <span class="headline">Cambiar contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>


              <v-flex xs12>
                <v-text-field v-model="passw1" label="Contraseña" type="password" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="passw2" label="Repetir contraseña" type="password" required></v-text-field>
              </v-flex>
 
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1"  @click.native="isChangePassword = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" @click.native="changePassw">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
-->
        <v-dialog v-model="isSaveUser" persistent max-width="500px">
          <v-btn slot="activator" :disabled="isFormInvalid" color="act" dark>Crear Cliente</v-btn>
          <v-card>
            <v-card-title>
              <span class="headline">Alta de Cliente</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap> </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" @click.native="isSaveUser = false"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" @click.native="confirmSave"
                >Crear</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isActivateUser" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Activar Usuario</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <span
                      >Para activar al usuario creado se necesita el código
                      enviado a su teléfono móvil</span
                    >
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      v-model="activCode"
                      label="Código"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" @click.native="isActivateUser = false"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" @click.native="activateUser"
                >Activar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top="true"
    >
      {{ snacktext }}
      <v-btn dark flat @click="snackresult = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import upload from "../../components/upload.vue";
import password from "../../components/modals/password.vue";
import saveUserConfirm from "../../components/modals/saveUserConfirm.vue";
import { EventBus } from "../../eventBus.js";
import pdf from "vue-pdf";
import UploadButton from "vuetify-upload-button";

export default {
  name: "user",
  props: ["uuid"],
  components: {
    upload,
    password,
    saveUserConfirm,
    pdf,
    "upload-btn": UploadButton
  },
  data() {
    return {
      userData: {},
      useruuid: "",
      endPointImages: process.env.VUE_APP_BASE_URI + "/getimagebypathbase64/",
      endPointAvatar: String,
      endPointIdFront: String,
      endPointIdBack: String,
      endPointDriverLicenseFront: String,
      endPointDriverLicenseBack: String,
      avatarImg: false,
      idFrontImg: false,
      idBackImg: false,
      driverLicenseFrontImg: false,
      driverLicenseBackImg: false,
      isChangePassword: false,
      isActivateUser: false,
      activCode: "",
      isSaveUser: false,
      labels: {
        message: this.$t("modalConfirmSaveUserText"),
        success: this.$t("userSuccessEdit"),
        error: this.$t("userErrorEdit"),
        leftButton: this.$t("passwordExit"),
        rigthButton: this.$t("modalConfirm")
      },
      endPoint:
        process.env.VUE_APP_BASE_URI +
        "/modifyuser/" +
        this.$store.getters.uuid,
      imagetype: String,
      currentIdFile: null,
      currentSepaFile: null,
      sepadocs: [],
      pagecounts: [],
      passw1: "",
      passw2: "",
      snackresult: false,
      snacktext: "",
      snackcolor: "success",
      currentPage: 0,
      pageCount: 0,
      page: {
        title: "Alta de Cliente",
        headline: "Datos de usuario",
        description: `.`
      },
      breadcrumbs: [
        {
          text: "Nuevo cliente",
          disabled: false
        }
      ]
    };
  },
  computed: {
    isFormInvalid() {
      if ('useremail' in this.userData) {
        if( this.userData.useremail.length > 10 && this.userData.useremail.indexOf("@") > 0 ){
            if( this.userData.userphone.length > 7)
              return false

        }
      }

      return true;
    }
  },
  mounted() {
    EventBus.$on("upload", imageObject => {
      this.checkImageType(imageObject.imagetype, imageObject.image);
    });
    const uuid = this.$route.query.id;
    this.getUserData(uuid); //'d71ade4e-f2f5-4e2c-a34d-bd8872bd447d')
    this.getSepadocs(uuid);
  },
  methods: {
    getUserData(uuid) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getuserbyuuid/" + uuid; // self.$store.getters.uuid
      self.$http.get(url).then(
        function(success) {
          let data = {};
          data.username = success.data.userdata.name;
          data.usersurname1 = success.data.userdata.surname1;
          data.usersurname2 = success.data.userdata.surname2;
          data.userphone = success.data.userdata.phone;
          data.useremail = success.data.userdata.email;
          data.useralias = success.data.userdata.alias;
          data.useridentitydocument = success.data.userdata.identitydocument;
          data.useraddress1 = success.data.userdata.address1;
          data.useraddress2 = success.data.userdata.address2;
          data.useraddressnumber = success.data.userdata.addressnumber;
          data.useraddressprovince = success.data.userdata.province;
          data.usercountry = success.data.userdata.country;
          data.useruuid = uuid;
          if (success.data.userdata.avatar)
            self.getImages("avatar", self.endPointImages + "avatar/" + uuid);
          if (success.data.userdata.idFront)
            self.getImages("idfront", self.endPointImages + "idfront/" + uuid);
          if (success.data.userdata.idBack)
            self.getImages("idback", self.endPointImages + "idback/" + uuid);
          if (success.data.userdata.dlFront)
            self.getImages("dlfront", self.endPointImages + "dlfront/" + uuid);
          if (success.data.userdata.dlBack)
            self.getImages("dlback", self.endPointImages + "dlback/" + uuid);
          self.userData = data;
        },
        function(error) {
          self.$toast.open({
            duration: 5000,
            message: self.$t("userErrorGetUserData"),
            type: "is-danger"
          });
        }
      );
    },
    getImages(type, endPoint) {
      const self = this;
      self.$http(endPoint).then(
        function(response) {
          self.checkImageType(type, response.data);
        },
        function(error) {
          console.log(error);
        }
      );
    },
    checkImageType(imagetype, image) {
      if (imagetype.indexOf("avatar") !== -1) {
        this.endPointAvatar = image;
        this.avatarImg = true;
      } else if (imagetype.indexOf("idfront") !== -1) {
        this.endPointIdFront = image;
        this.idFrontImg = true;
      } else if (imagetype.indexOf("idback") !== -1) {
        this.endPointIdBack = image;
        this.idBackImg = true;
      } else if (imagetype.indexOf("dlfront") !== -1) {
        this.endPointDriverLicenseFront = image;
        this.driverLicenseFrontImg = true;
      } else if (imagetype.indexOf("dlback") !== -1) {
        this.endPointDriverLicenseBack = image;
        this.driverLicenseBackImg = true;
      }
    },
    editUser() {
      this.isSaveUser = true;
    },
    changePassw() {
      if (this.passw1.length > 5 && this.passw1 == this.passw2) {
        const self = this;
        let url =
          process.env.VUE_APP_BASE_URI +
          "/changepassword/" +
          this.userData.useruuid;
        var bodyuser = {
          newpassword: this.passw1
        };
        self.$http.post(url, bodyuser).then(
          function(success) {
            self.isChangePassword = false;
            self.snacktext = "La contraseña se ha modificado";
            self.snackcolor = "success";
            self.snackresult = true;
          },
          function(error) {
            self.isChangePassword = false;

            self.snacktext = "Error. La contraseña  no se ha modificado";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
      } else {
        //this.isChangePassword = false

        this.snacktext =
          "Las contraseñas  no coinciden o son demasiado cortas ";
        this.snackcolor = "red";
        this.snackresult = true;
      }
    },
    update(file) {
      this.getBase64(file);
    },
    setCurrentFile(fileid) {
      alert(fileid);
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        console.log(reader.result);
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    },
    downloadImage(imagepath, cont) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/downloadimagefrompath";
      var bodyuser = {
        imagepath: imagepath
      };
      self.$http
        .post(url, bodyuser, {
          responseType: "arraybuffer"
        })
        .then(
          function(success) {
            var b64 = new Buffer(success.data, "binary").toString("base64");
            var imgsrc = "data:image/jpeg;base64," + b64;
            self.sepadocs[cont].b64img = imgsrc;
          },
          function(error) {
            self.snacktext = "Error. La imagen  no se ha podido  recuperar";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
    },

    confirmSave() {
      // if (true) {
      const self = this;
      self.userData.userpassword = "huh9877289";
      let url = process.env.VUE_APP_BASE_URI + "/createuser";
      self.userData.parentuuid = self.$store.getters.uuid
      self.$http.post(url, self.userData).then(
        function(success) {
          self.isSaveUser = false;
          self.snacktext = "El usuario se ha creado correctamente";
          self.snackcolor = "success";
          self.snackresult = true;
          self.useruuid = success.data.useruuid;
          self.isActivateUser = true;
          self.isSaveUser = false;
          //  this.$router.push({ path: '/pages/machinedetail/:id', query: { id: useruuid }})
        },
        function(error) {
          self.isSaveUser = false;

          self.snacktext = "Error. No se ha podido crear el usuario";
          self.snackcolor = "red";
          self.snackresult = true;
        }
      );
      // }
    },
    activateUser() {
      if (this.activCode.length > 3) {
        const self = this;
        let url = process.env.VUE_APP_BASE_URI + "/activateusersms";
        var bodyuser = {
          activationcode: this.activCode,
          useremail: this.userData.useremail
        };
        self.$http.post(url, bodyuser).then(
          function(success) {
            self.isActivateUser = false;
            self.snacktext = "El usuario ha sido activado";
            self.snackcolor = "success";
            self.snackresult = true;
            self.$store.commit('setUserUuid', self.useruuid);
            self.$router.push({ path: "/pages/user" });
          },
          function(error) {
           // self.isActivateUser = false;

            self.snacktext = "Error. El código de activación no es correcto";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
      } else {
        //this.isChangePassword = false

        this.snacktext = "El código no tiene el formato correcto ";
        this.snackcolor = "red";
        this.snackresult = true;
      }
    }
  }
};
</script>

<style scoped>
.mtop31 {
  margin-top: 31px;
}
.mtop40 {
  margin-top: 40px;
}
.mtop-30 {
  margin-top: -30px;
}
.icon-size {
  font-size: 18pt;
}
.float-right {
  float: right;
}
</style>

import UserservicesRepository from "./userservicesRepository";
import MachinesRepository from "./machinesRepository";
import PaymentsRepository from "./paymentsRepository";

const repositories = {
  userservices: UserservicesRepository,
  machines: MachinesRepository,
  payments: PaymentsRepository
  // other repositories ...
};

export const RepositoryFactory = {
  get: name => repositories[name]
};

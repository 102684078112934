<template>
  <v-container fluid grid-list-xl class="">
    <v-toolbar flat  class="transparent  section-definition-toolbar">
      <v-avatar class="box-glow" :tile="tile">
        <v-icon dark v-html="icon" v-if="icon"></v-icon>
        <span v-else>{{ title | first2Char }}</span>
      </v-avatar>
      <v-toolbar-title class="primary--text">{{ title }}</v-toolbar-title>


    </v-toolbar>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
    tile: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="stylus" scoped>
.toobar-extension {
  margin-left: 5px !important;
}

.breadcrumb-section {
  margin-left: -25px !important;
}

.action-btn {
  margin-left: 5px !important;
}

.v-content{
  padding:0px !important;
}
</style>

import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { createI18n } from "./i18n/index";
import axios from "axios";
import IdleVue from "idle-vue";
import moment from "moment";
import VeeValidate from "vee-validate";
import firebase2 from "./firebase";
import { rtdbPlugin } from "vuefire";
import "./plugins/vuetify";
import "./plugins/vuse";
import * as VueGoogleMaps from "vue2-google-maps";
import JsonExcel from "vue-json-excel";
import VueFirestore from "vue-firestore";
import "@/utils/filters";
import vueCalendar from "vue2-simple-calendar";
import "./assets/vue2-simple-calendar.css";

// config object
const config  = {
  locale: "es",
  fullDayNames: true,
  fullMonthNames: true,
  languages: {
    es: {
      showMore: "Mostrar más",
      dayNameShort: ["Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"],
      dayNameLong: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
      monthNameShort: ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      monthNameLong: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    }
  }
};

Vue.use(vueCalendar, config);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCP7o1JdB9JWHaax2qnDe_vkrcGiyuZNHU",
    libraries: "places" // necessary for places input
  }
});

export const EventBus = new Vue();

export const firebase3 = firebase2;

Vue.prototype.$http = axios;
Vue.prototype.moment = moment;
Vue.prototype._ = _;
Vue.prototype.window = window;
Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(rtdbPlugin);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueFirestore);

const i18n = createI18n();
Vue.config.lang = "en";

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  idleTime: 1200000, // 20 Minutes 1200000
  store
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate: function() {
    // Adding some custom rules
    const _this = this;
    // Configuring the base URL
    // Add a request interceptor
    _this.$http.interceptors.request.use(
      function(config) {
        // Do something before request is sent

        if (!config.url.includes("login") && !config.url.includes("refresh")) {
          config.headers.Authorization = "Bearer " + _this.$store.state.token;
        } else if (config.url.includes("refresh")) {
          if (_this.$store.getters.refreshToken === "") {
            config.headers.Authorization =
              "Bearer " + sessionStorage.getItem("rt");
          } else {
            config.headers.Authorization =
              "Bearer " + _this.$store.getters.refreshToken;
          }
        }
        config.headers.Pragma = "no-cache";
        config.headers.CacheControl = "no-cache";
        config.headers["Cache-Control"] = "no-cache";
        config.headers.Expires = -1;
        if (config.url.indexOf("getimagebypathbase64") === -1)
          _this.$store.commit("isLoading", true);
        return config;
      },
      function(error) {
        _this.$store.commit("isLoading", false);
        return Promise.reject(error);
      }
    );
    // Add a response interceptor
    _this.$http.interceptors.response.use(
      function(response) {
        _this.$store.commit("isLoading", false);
        return response;
      },
      function(error) {
        if (error.response && error.response.status === 422) {
          if (_this.$store.getters.validuser.length > 0) {
            var payload = {};
            payload.userName = _this.$store.getters.validuser;
            payload.password = _this.$store.getters.validpassword;
            const { data } = UserRepository.login(payload)
              .then(function(success) {
                _this.$store.commit("setToken", success.data);

                //this.$router.push({path: "/myMachines"})
                //his.posts = data;
                _this.$router.push({
                  name: "pages/RentsPage"
                });
              })
              .catch(function(error) {
                _this.loginError = true;
                _this.$store.commit("setValiduser", "");
                _this.$store.commit("setValidpassword", "");
              });
          }
          _this.$router.push({ name: "pages/authentication/LoginPage" });
          return Promise.reject(error);
        }
        if (error.response && error.response.status === 401) {
          // We need to refresh the token.
          _this.$router.push({ name: "pages/authentication/LoginPage" });
          return Promise.reject(error);
          /*
          return _this.$http
            .get(process.env.ENDPOINT_PREFIX + "/refresh")
            .then(function() {
              _this.$store.commit("isLoading", false);
              return axios(error.config);
            })
            .catch(function(errorRefresh) {
              _this.$store.commit("isLoading", false);
              return Promise.reject(errorRefresh);
            });
 */
        }
        // else if (error.response && error.response.status === 403) {
        //   _this.$store.commit("isLoading", false)
        //   _this.$router.push({name: "login"})
        // }
        else {
          _this.$store.commit("isLoading", false);
          return Promise.reject(error);
        }
      }
    );
  }
}).$mount("#app");

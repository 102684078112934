<template>
  <v-container grid-list-md text-xs-center>
      <div class="title"  > Detalle del alquiler </div>
    <v-layout row wrap>
    <v-flex xs12 sm6>
        <v-card>
          <v-card-text>
            <div class="title">{{macdata.machinename}}</div>
            <div class="subheading">{{macdata.machinedescription}}</div>
            <v-divider></v-divider>
            <div class="text-sm-left text-xs-left">



              <div>
                Localización de alquiler:
                <span class="font-italic font-weight-thin red--text">{{currentrent.rentplace}}</span>
              </div>              

              <div>
                Fecha de entrega:
                <span class="font-weight-bold  red--text">{{currentrent.rentfrom}}</span>
              </div>
              <div>
                Fecha de recogida:
                <span class="font-weight-bold red--text">{{currentrent.rentto}}</span>
              </div>
              <!-- <li v-for="(ingredient, i) in item.recipe.ingredientLines" :key="i">{{ingredient}}</li> -->  
              <div>
                Precio:
                <span class="font-weight-bold red--text">{{currentrent.rentprice}} €</span>
              </div>
              <div>
                Fianza:
                <span class="font-weight-bold red--text">{{currentrent.rentpriceexcess}} €</span>
              </div>
              <div>
                Tipo de trabajo:
                <span class="font-weight-bold red--text">{{currentrent.rentconditions}} </span>
              </div>              
              <div>
                Nombre del cliente:
                <span class="font-italic font-weight-thin red--text">{{userData.username}} {{userData.usersurname1}}</span>
              </div>            

              <div>
                Teléfono del cliente:
                <span class="font-italic font-weight-thin red--text">{{userData.userphone}}</span>
              </div>            
              <div>
                Email del cliente:
                <span class="font-italic font-weight-thin red--text">{{userData.useremail}}</span>
              </div>     
           </div>
           
          </v-card-text>
          <v-card-title></v-card-title>
        </v-card>
        <v-card>
          <div class="custom-checkbox" v-for="(itemx, index) in attsType4">
            <v-checkbox
              class="text-sm-left text-xs-left custom-checkbox"
              style="font-size:12;"
              :label="itemx.labelx"
              :value="true"
              readonly="true"
              :input-value="itemx.valuebool"
            ></v-checkbox>
          </div>
          <div class="custom-machineatt"></div>
          <div
            class="text-sm-left text-xs-left custom-machineatt"
            v-for="(itemx, index) in attsType1"
          >
            <span class="text-sm-left text-xs-left custom-check">{{itemx.label}} : {{itemx.value}}</span>
          </div>

          <div
            class="text-sm-left text-xs-left custom-machineatt"
            v-for="(itemx, index) in attsType3"
          >
            <span class="text-sm-left text-xs-left custom-check">{{itemx.label}} : {{itemx.value}}</span>
          </div>
        </v-card>

      </v-flex>
      <v-flex xs12 sm6>
         <google-map-only-view />
        </v-flex>
        <v-flex xs12 sm12>
            <v-divider></v-divider>
        </v-flex>
        <v-flex xs12 sm4>
                        <div class="modal-card-body modal-content">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <p>Documento: {{ documentname1 }}</p>
                <p hidden>{{ documents[0].b64img }}</p>
                <iframe  :src="document1"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>
  
        </v-flex> 
                <v-flex xs12 sm4>
                       <div class="modal-card-body modal-content">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <p>Documento: {{ documentname2 }}</p>
                <p  hidden>{{ documents[0].b64img }}</p>
                <iframe  :src="document2"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>

        
                </v-flex>
                
                <v-flex xs12 sm4>
                       <div class="modal-card-body modal-content">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <p>Documento: {{ documentname3 }}</p>
                <p  hidden>{{ documents[0].b64img }}</p>
                <iframe  :src="document3"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>

        
                </v-flex>    


        <v-flex xs12 sm4>
                        <div class="modal-card-body modal-content">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <p>Documento: {{ documentname4 }}</p>
                <p hidden>{{ documents[0].b64img }}</p>
                <iframe  :src="document4"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>
        </v-flex>                 

        <v-flex xs12 sm4>
                        <div class="modal-card-body modal-content">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <p>Documento: {{ documentname5 }}</p>
                <p hidden>{{ documents[0].b64img }}</p>
                <iframe  :src="document5"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>
        </v-flex>                 

        <v-flex xs12 sm4>
                        <div class="modal-card-body modal-content">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <p>Documento: {{ documentname6 }}</p>
                <p hidden>{{ documents[0].b64img }}</p>
                <iframe  :src="document6"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>
        </v-flex>                 

        <v-flex xs12 sm4>
                        <div class="modal-card-body modal-content">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <p>Documento: {{ documentname7 }}</p>
                <p hidden>{{ documents[0].b64img }}</p>
                <iframe  :src="document7"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>
        </v-flex>                 



      <!--          
      <v-flex v-for="sepadoc in documents" :key="sepadoc" xs12 sm4 md4>
            <v-card>
                <v-btn @click="showDocument(sepadoc.documentuuid)">{{ sepadoc.documentname }}</v-btn>
            <div class="modal-card-body modal-content">
                <p>Documento: {{ documents[0].documentname }}</p>
                <p >{{ sepadoc.b64img }}</p>
                <iframe  :src="document1"  frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfx"></iframe>
      
            </div>
            <pdf
                :src="sepadoc.b64img"
                @num-pages="sepadoc.pageCount = $event"
                :page="sepadoc.currentPage"
                @page-loaded="sepadoc.currentPage = $event"
                @link-clicked="page = $event"
              ></pdf>
        -
              <v-card-actions class="white justify-center">

                <span>{{ sepadoc.documentname }}</span>



              </v-card-actions>
            </v-card>
          </v-flex>
          -->
 
    </v-layout>

         <v-dialog v-model="isShowDoc" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Documento: {{currentDocName}} </span>
            </v-card-title>
            <v-card-text>
        <div class="modal-card">
            <div class="modal-card-body modal-content" height="550">
                <!-- <iframe width="100%" height="600px" :src="url" frameborder="0"></iframe> -->
                <iframe :src="document1" frameborder="0" width="100%" height="550" marginheight="0" marginwidth="0" id="pdfy"></iframe>
                <div class="columns">
                    <div class="column">
                        <button class="button is-primary float-right mtop20" type="button" @click="$parent.close()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" @click.native="isShowDoc = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import GoogleMapOnlyView from "../../components/Maps/GoogleMapOnlyView";
import { EventBus } from "../../eventBus";
import pdf from "vue-pdf";

//EventBus.$on('newAddress', clickCount => {
//  console.log(`Oh, that's nice. It's gotten ${clickCount} clicks! :)`)
//});

export default {
  name: "MachineDetail",
  props: ["id"],

  data() {
    return {
      isShowDoc: false,
      images: [],
      uuid: "",
      rentdata: {},
      currentDocName: "",
      documents: [],
      document1: null,
      document2: null,
      document3: null,
      document4: null,
      document5: null,
      document6: null,
      document7: null,
      documentname1: null,
      documentname2: null,
      documentname3: null,
      documentname4: null,
      documentname5: null,
      documentname6: null,
      documentname7: null,
      machinePhotos: [],
      isLoading2: false,
      hasTransport: false,
      hasInsurance: false,
      attsType1: [],
      attsType2: [],
      attsType3: [],
      attsType4: [],
      search: "",
        rentuuid: "",
      currentrent: {},
      machineuuid: "",
      macdata: {},
      userData: {},
      ownerData: {},   
   currentPage: 0,
      pageCount: 0,
      date: null,
 
    };
  },
  components: {
    GoogleMapOnlyView, pdf
  },
  created: function() {
    this.$on("newAddress", section => {
      console.log(section);
    });
  },
  mounted: function() {
    //this.getop(this.$route.query.id);
    for( var i = 0; i < 7; i++){
        this.documents[i]={documentname:"", b64img:"" }
    }
    this.uuid = this.$store.state.rentUuid
    // this.uuid = this.$route.params.currentuuid;
  //   this.getRents(this.$route.query.id);
   this.getrent(this.uuid);
  },
  computed: {
    documentsAll() {
      return this.documents
    }
  },
  methods: {
    showDocument(docuuid) {
        this.document1= this.documents[0]
        document.getElementById('pdfy').src = this.documents[0].b64img;            

        this.isShowDoc = true

      },  
    nextPage(currentdoc) {
      currentdoc.currentPage++;
      if (currentdoc.currentPage > currentdoc.pageCount)
        currentdoc.currentPage = 1;
    },
    prevPage(currentdoc) {
      currentdoc.currentPage--;
      if (currentdoc.currentPage < 1) currentdoc.currentPage = 1;
    }, 
    onNewAddress(someValue) {
      console.log(someValue);
    },
    gotoDetailEdit: function() {
      this.$router.push({
        path: "/pages/machinedetailedit/:id",
        query: { id: this.uuid }
      });
    },
    downloadDoc(docuuid, cont) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getdocumentbyuuidbase64/" + docuuid;
  
      self.$http
        .get(url)
        .then(
          function(success) {
            var imgsrc = "data:application/pdf;base64," + success.data;
            self.documents[cont].b64img = imgsrc;
            var dochref = "data:application/octet-stream;base64," + success.data;

            //document.getElementById('pdf').src = self.documents[cont].b64img;            
            self.documents[cont].currentPage = 1;
            if( cont ==0)
            {
                self.document1=self.documents[cont].b64img
                self.documentname1 = self.documents[cont].documentname
            }
           if( cont ==1)
            {
                self.document2=self.documents[cont].b64img
                self.documentname2 = self.documents[cont].documentname
            }
           if( cont ==2)
            {
                self.document3=self.documents[cont].b64img
                self.documentname3 = self.documents[cont].documentname
            }           
            if( cont ==3)
            {
                self.document4=self.documents[cont].b64img
                self.documentname4 = self.documents[cont].documentname
            }          
            if( cont ==4)
            {
                self.document5=self.documents[cont].b64img
                self.documentname5 = self.documents[cont].documentname
            }       
            if( cont ==5)
            {
                self.document6=self.documents[cont].b64img
                self.documentname6 = self.documents[cont].documentname
            }    
            if( cont ==6)
            {
                self.document7=self.documents[cont].b64img
                self.documentname7 = self.documents[cont].documentname
            }                             
        },
          function(error) {
            self.snacktext = "Error. La imagen  no se ha podido  recuperar";
            self.snackcolor = "warning";
            self.snackresult = true;
          }
        );
    },

    getrent: function(opuuid) {
      const self = this;
      this.isLoading2 = true;


      const url = process.env.VUE_APP_BASE_URI + "/getrentbyuuid/" + opuuid;
      self.$http
        .get(url) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(success) {
            var renttemp = success.data;

                self.isLoading = false;

            if (renttemp.rentstatus < 10) {
              renttemp.rentfrom = self
                .moment(renttemp.rentfrom)
                .format("DD-MM-YYYY")
                .split("T")[0];
              renttemp.rentto = self
                .moment(renttemp.rentto)
                .format("DD-MM-YYYY")
                .split("T")[0];
              if (renttemp.rentstatus === 1) {
                renttemp.rentstatuslabel = "Solicitado";
              }
              if (renttemp.rentstatus === 2) {
                renttemp.rentstatuslabel = "Aprobado";
              }
              if (renttemp.rentstatus >= 3 && renttemp.rentstatus < 6) {
                renttemp.rentstatuslabel = "Firmado";
              }
              if (renttemp.rentstatus === 6) {
                renttemp.rentstatuslabel = "Entrega";
              }
              if (renttemp.rentstatus === 7) {
                renttemp.rentstatuslabel = "Recogida";
              }
              if (renttemp.rentstatus === 8) {
                renttemp.rentstatuslabel = "Liquidación";
              }
              if (renttemp.rentstatus === 9) {
                renttemp.rentstatuslabel = "Finalizado";
              }


              if (renttemp.rentstatus >= 6 && renttemp.rentstatus < 9) {
                if (renttemp.rentphase === 1) {
                  renttemp.rentphaselabel = "Salida";
                }
                if (renttemp.rentphase === 2) {
                  renttemp.rentphaselabel = "Entrega";
                }
                if (renttemp.rentphase === 3) {
                  renttemp.rentphaselabel = "Recogida";
                }
                if (renttemp.rentphase === 4) {
                  renttemp.rentphaselabel = "Devolución";
                }
              } else {
                renttemp.rentphaselabel = " ";
              }


            }
          self.currentrent = renttemp;
          if (self.currentrent.rentplace != null) {
              EventBus.$emit("setAdditionalAddress", self.currentrent.rentplace);
            }

          self.getUserData( renttemp.iduser, 1)
          self.getmachine(renttemp.machineuuid)
          }
        )
        .catch(
          function(error) {
            console.log(error);
            self.isLoading2 = false;
          }
        );

      const url2 =
        process.env.VUE_APP_BASE_URI + "/getdocumentlistbyrentuuid/" + opuuid;
      self.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
           // _this.documents = response.data.documents;
            for( var a = 0; a < response.data.documents.length; a++){
                self.documents[a] = response.data.documents[a]
                self.documents[a].order = 'pdfx'+a

                self.downloadDoc( self.documents[a].documentuuid, a);

            }
            //                  for (var a = 0; a < this.machinePhotos.length; a++) {
            //                    var newimg = {};
            //                    newimg.src =
            //                      "https://ploou-produccion.appspot.com/getimage/" +
            //                      this.machinePhotos[a].imageuuid;
            //                    this.images.push(newimg);
            //                  }
          }
        )
        .catch(
          function(error) {
            console.log(error);
            self.isLoading2 = false;
          }
        );
    },

    getUserData(userid, useractor) {
      const self = this;
      const uuid = self.currentuseruuid
      let url = process.env.VUE_APP_BASE_URI + "/getuserbyid" //+ uuid; // self.$store.getters.uuid
      const dataid={
        iduser: userid
      }
      self.$http.post(url, dataid).then(
        function(success) {
          let data = {};
          data.username = success.data.userdata.name;
          data.usersurname1 = success.data.userdata.surname1;
          data.usersurname2 = success.data.userdata.surname2;
          data.userphone = success.data.userdata.phone;
          data.useremail = success.data.userdata.email;
          data.useralias = success.data.userdata.alias;
          data.useridentitydocument = success.data.userdata.identitydocument;
          data.useraddress1 = success.data.userdata.address1;
          data.useraddress2 = success.data.userdata.address2;
          data.useraddressnumber = success.data.userdata.addressnumber;
          data.useraddressprovince = success.data.userdata.province;
          data.usercountry = success.data.userdata.country;
          data.type = success.data.userdata.type;
            if (data.type === 1) {
              data.typelabel = "Normal";
            } else if (data.type === 2) {
              data.typelabel = "Premium ";
            } else if (data.type === 3) {
              data.typelabel = "Transportista";
            } else if (data.type === 5) {
              data.typelabel = "Administrador";
            } else {
              data.typelabel = "---";
            }
          data.useruuid = uuid;

          if( useractor ==1){
           self.userData = data;

          }
          else{ 
            self.ownerData = data;
           
          }
       },
        function(error) {
            self.snacktext = "Error obteniendo datos del usuario  ";
            self.snackcolor = "warning";
            self.snackresult = true;
        }
      );
    },
   

    getmachine: function(opuuid) {
      const _this = this;
      this.isLoading2 = true;
      const url = process.env.VUE_APP_BASE_URI + "/getmachinebyuuid/" + opuuid;
      _this.$http
        .get(url) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log(response);
            _this.isLoading2 = false;
            _this.macdata = response.data.machinedata;
            if (_this.macdata.machineinsuranceavailable == 1)
              _this.hasInsurance = true;
            if (_this.macdata.machinetransportavailable == 1)
              _this.hasTransport = true;
            _this.macdata.machineattributes.forEach(element => {
              if (element.attributetype == 1) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                if (element.attributename == "Potencia") var aa = 0;
                else _this.attsType1.push(attnew);
              } else if (element.attributetype == 3) {
                var attnew = {};
                attnew.label = element.attributename;
                attnew.value = element.attributevaluenum;
                _this.attsType3.push(attnew);
              } else if (element.attributetype == 4) {
                var attnew = {};
                attnew.labelx = element.attributename;
                if (element.attributevalueyesno == 0) attnew.valuebool = false;
                else attnew.valuebool = true;
                _this.attsType4.push(attnew);
              }
            });
            if (_this.macdata.machinelocation != null) {
              EventBus.$emit("setMacAddress", _this.macdata.machinelocation);
            }

            _this.$http
              .get(
                process.env.VUE_APP_BASE_URI +
                  "/getphotosformachine/" +
                  response.data.machinedata.machineuuid
              )
              .then(
                function(response) {
                  console.log("" + response);
                  //  this.isLoading2 = false;
                  this.machinePhotos = response.data;
                  for (var a = 0; a < this.machinePhotos.length; a++) {
                    var newimg = {};
                    newimg.src =
                      process.env.VUE_APP_BASE_URI +
                      "/getimage/" +
                      this.machinePhotos[a].imageuuid;
                    this.images.push(newimg);
                  }
                }.bind(this)
              )
              .catch(
                function(error) {
                  console.log(error);
                  // this.isLoading2 = false;
                }.bind(this)
              );
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            this.isLoading2 = false;
          }.bind(this)
        );

      const url2 =
        process.env.VUE_APP_BASE_URI + "/getblockeddaysbyuuid/" + opuuid;
      _this.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
            this.blockeddays = response.data.blockeddays;
            //                  for (var a = 0; a < this.machinePhotos.length; a++) {
            //                    var newimg = {};
            //                    newimg.src =
            //                      "https://ploou-produccion.appspot.com/getimage/" +
            //                      this.machinePhotos[a].imageuuid;
            //                    this.images.push(newimg);
            //                  }
          }.bind(this)
        )
        .catch(
          function(error) {
            console.log(error);
            // this.isLoading2 = false;
          }.bind(this)
        );
    },
    getColor (status) {
        if (status > 7) return 'red'
        else if (status > 2) return 'orange'
        else return 'green'
      },    
  }
};
</script>
<style lang="stylus">
#example-custom-transition {
  .fade {
    &-enter-active, &-leave-active, &-leave-to {
      transition: 0.3s ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }
}

.custom-checkbox {
  height: 20px;
  font-size: 12;
  margin-left: 5px;
}

.custom-machineatt {
  height: 30px;
  font-size: 12;
  margin-left: 15px;
}

.v-label {
  font-size: 14px;
  font-color: black;
}
</style>
<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    name: 'bar',
    extends: Bar,
    mixins: [reactiveProp],
    props: ['chartData', 'options', 'serviceUrl'],
    data () {
      return {
      }
    }, 
    mounted () {
        this.getGraphicData()
    },
    methods: {
        getGraphicData () {
            const self = this
            let url = process.env.VUE_APP_BASE_URI + self.serviceUrl
            self.$http.get(url).then(
                function(response) {
                    let data = {}
                    self.options.title.text = response.data.data.title
                    data.datasets = []
                    self._.forEach(response.data.data.datasets, function(dataset, index) {
                        dataset.borderWidth = 2
                        let colors = self.getRandomRGBAColor()
                        dataset.backgroundColor = colors.bgColors
                        dataset.borderColor = colors.borderColors
                        data.datasets.push(dataset) 
                    })
                    self.renderChart(data, self.options)
                },
                function(error) {
                    console.log(error)
                }
            )
        },
        getRandomRGBAColor () {
            let bgColors = []
            let borderColors = []
            var o = Math.round, r = Math.random, s = 255;
            bgColors.push('rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',0.5' + ')');
            borderColors.push(bgColors[0].replace('0.2', '1'));
            return { bgColors: bgColors, borderColors: borderColors }
        }
    }
}
</script>

<style scoped>

</style>
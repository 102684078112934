<template>
  <div id="app">
    <v-container fluid>

<v-toolbar flat class="transparent section-definition-toolbar mb-3">
          <v-avatar class="box-glow" tile>
            <v-icon dark>message</v-icon>
          </v-avatar>
          <v-toolbar-title class="primary--text hidden-xs-only">{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
     <!--       <v-btn color="act" block class="bold" @click="gotoUserDetail()" v-if="showProfileButton"> 
              Ver Perfil de Usuario
            </v-btn> -->
       <!--   <v-text-field
            color="white"
            v-model="search"
            label="Buscar usuario"
            solo
            append-icon="search"
            class="max-width-300x"
          ></v-text-field> -->
          <!-- <v-list dense class="text-xs-right max-width-250x">
            <v-list-tile>
              <v-list-tile-avatar>
                <v-avatar
                  size="40px"
                  color="grey lighten-4"
                  class="ml-2"
                >
                  <img :src="authUser.avatar" alt="avatar">
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="authUser.name" class="subheading"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list> -->
        </v-toolbar>
        <v-navigation-drawer
          hide-overlay
          v-model="drawerinternal"
          :permanent="$vuetify.breakpoint.mdAndUp"
          enable-resize-watcher
          :mini-variant="$vuetify.breakpoint.smAndDown"
          :temporary="$vuetify.breakpoint.smAndDown"
          class="chat-nav-drawer"
          floating
        >
          <v-list two-line class="pt-0 inner-sidebar v-menu-trans-list-npd">
            <template v-for="(group, index) in chats2">
              <v-list-tile
                @click="switchGroup(group)" 
                :class="[( group['.key'].indexOf( currentGroup.uuid) > -1) ? 'box-glow' : '']"
              >
                <v-list-tile-avatar>
                  <img :src="group.avatar"  alt="avatar">
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ group.useralias }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ group.lastmessage }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action class="compact-action">
                  <v-btn color="primary" fab class="btn-round-xs" v-if="group.unread > 0">{{ group.unread }}</v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider  :key="index"></v-divider>
            </template>
          </v-list>
        </v-navigation-drawer>
        <v-layout row  class="chat-section">
          <v-flex xs12 pr-0 pt-0 v-bind:pl-0="$vuetify.breakpoint.smAndDown">
           <v-img
              :src="backgroundImg"
              :gradient="chatGradient.gradient"
              class="chat-messaging-section"
            >
              <v-card flat color="transparent" class="chatscroll-statesetter" id="chatscrollstatesetter" ref="chatscrollstatesetter">
                <v-card-text
                  class="transparent"
                  id="chatscroll-thread"
                >
                  <v-layout row wrap>
                    <v-flex xs12 v-for="(thread, index) in messages" :key="index">
                      <v-card flat class="overflow-hidden transparent">
                        <v-container fluid grid-list-xs>
                          <div class="chat-thread" :class="[currentGroup.uuid === thread.ReceiverUuid ? 'end' : 'start']">
                            <div class="user-avatar">
                              <v-avatar
                                size="40px"
                                color="grey lighten-4"
                              >
                                <img :src="currentAvatar" alt="avatar" v-if="currentGroup.uuid != thread.ReceiverUuid">
                                <img :src="ownerAvatar" alt="avatar" v-if="currentGroup.uuid === thread.ReceiverUuid">
                              </v-avatar>
                            </div>
                            <div class="chat-message pa-2 border-radius6">
                              <div class="body-2">{{ thread.SenderName }}<span class="font-italic caption">  {{thread.Time}}</span> </div>
                              <div v-html="thread.Message" class="chat-thread-message dont-break-out"></div>
                            </div>
                          </div>
                        </v-container>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-img>  

           <v-layout row wrap class="no-mrpd">
              <v-flex xs12 class="no-mrpd">
                <div class="pos-relative vuse-chat-message-container">
                  <chat-editable
                    @update="chatMessageEditor = $event"
                    class="chat-message-editor"
                    type="innerHTML"
                    @onEnter="sendMessage"
                    :placeholder="$t('labels.typeYourMessage')"
                  ></chat-editable>
                  <v-btn
                    color="secondary"
                    class="ma-0 send-message-btn pa-0"
                    @click="sendMessage"
                    :disabled="chatMessageEditor === null || chatMessageEditor === ''"
                  >
                    <v-icon color="white">fa-paper-plane</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>            

          </v-flex>
        </v-layout>
    </v-container>
    <!--
    <ul class="reptileList">
      <li v-for="chat in chats">
        {{ chat.useralias }} -
        <v-btn outline @click="showconversation(chat)">
          Ver
        </v-btn>
      </li>
    </ul>
    <div class="chat">
      <ul class="reptileList">
        <li v-for="message in messages">{{ message.Message }} -</li>
      </ul>
      -->
    </div>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { rgba as Gradients } from "@/data/gradients";
import ChatEditable from "@/components/Editable/ChatEditable";
import moment from "moment";

export default {
  name: "app",
  data() {
    return {
        chatMessageEditor: null,
      
      chats: [],
      messages: [],
      newReptile: "",
      currentGroup: {},
      title: this.$t("labels.chat"),
      gradientsList: Gradients,
      gradient: Gradients[9],
      chatGradient: Gradients[5],
      backgroundImg: "chatbackground.jpg",
      activeGroupId: 1,
      curreentAvatar: "",
      ownerAvatar: "",
      ownerUuid: "",
      currentChatroom: "",
      owneralias: "",
      showProfileButton: false

    };
  },
  firestore() {
    return {
      chats: db.collection("users/"+ this.$store.getters.uuid+"/userrooms")
    };
  },
  mounted: function() {
    console.log("nnn");
    this.ownerUuid = this.$store.getters.uuid;
    this.owneralias = this.$store.getters.useralias;
    this.ownerAvatar = process.env.VUE_APP_BASE_URI + "/getavatar/" + this.ownerUuid ;
   // this.chats = db.collection("users/"+ this.ownerUuid+"/userrooms")
  },
  components: {
    ChatEditable
  },  
  computed: {
    chats2() {
      var cs = []
      for( var i= 0; i< this.chats.length; i++ )
      {
        var hh = this.chats[i][".key"]
        hh = hh.replace(this.ownerUuid, "")
        hh = hh.replace("_", "")
        this.chats[i].uuid = hh;
        this.chats[i].avatar = process.env.VUE_APP_BASE_URI + "/getavatar/" + hh;
        cs.push(this.chats[i])

      }
      return cs;
    }
  },  
  methods: {
    showconversation: function(currentchat) {

      var hh = currentchat[".key"]
      this.currentChatroom = hh
      this.showProfileButton = true;
      hh = hh.replace(this.ownerUuid, "")
      hh = hh.replace("_", "")
      this.currentGroup = {}
      this.currentGroup.name = currentchat.useralias
      this.currentGroup.uuid =hh;
      this.currentAvatar = process.env.VUE_APP_BASE_URI + "/getavatar/" + hh
      this.$binding(
        "messages",
        db.collection("chats/" + currentchat[".key"] + "/messages").orderBy("Time")
      ).then(messages => {
        console.log(messages); // => __ob__: Observer
      });
    },
    switchGroup: function(currentchat) {
      var hh = currentchat[".key"]
      this.currentChatroom = hh
      this.showProfileButton = true;

      hh = hh.replace(this.ownerUuid, "")
      hh = hh.replace("_", "")
      this.currentGroup = {}
      this.currentGroup.name = currentchat.useralias
      this.currentGroup.uuid = hh;
      this.currentAvatar = process.env.VUE_APP_BASE_URI + "/getavatar/" + hh
      this.$binding(
        "messages",
        db.collection("chats/" + currentchat[".key"] + "/messages").orderBy("Time")
      ).then(messages => {
        console.log(messages); // => __ob__: Observer
      });
    }, 
    sendMessage() {

      var chatDet = {}

      chatDet.Message = this.chatMessageEditor;
      // var utcdate = moment.utc().format();
      var today = moment()

      chatDet.Time = today.format('DD/MM/YYYY HH:mm:ss');
      chatDet.SenderUuid = this.ownerUuid;
      chatDet.SenderName = this.owneralias;
      chatDet.MessageType = "Text";
      chatDet.ReceiverName = this.currentGroup.name;
      chatDet.ReceiverUuid = this.currentGroup.uuid;
        
      //  this.messages.push( chatDet );
      db.collection("chats")
        .doc(this.currentChatroom)
        .collection("messages")
        .add(chatDet);

      var chatRes = {}
      chatRes.unreads = 2;
      chatRes.lastmessage = this.chatMessageEditor;
      chatRes.useralias = this.$store.getters.useralias;
      chatRes.useruuid = this.ownerUuid;  // this.$store.getters.uuid;
      db.collection("users")
        .doc(this.currentGroup.uuid)
        .collection("userrooms")
        .doc(this.currentChatroom)
        .set(chatRes);      

      this.chatMessageEditor = null;
      this.$eventBus.$emit("resetChatEditor");
      this.$nextTick(() => {
        const container = document.querySelector("#chatscrollstatesetter");
        container.scrollTop = container.scrollHeight;
      });
    },
    gotoUserDetail( ) {

        this.$router.push({
          name: "pages/UserViewOnly",
          params: {
            currentuuid: this.currentGroup.uuid,
            currentuserid: 0
            }
       //   query: { id: useruuid }
        });
      
    },  
  
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.reptileList {
  list-style: none;
}
.toobar-extension {
  margin-left: 0 !important;
  min-width: 300px;
}

</style>

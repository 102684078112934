import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import navigation from "./modules/navigation";
import swatch from "./modules/swatch";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'ploouadmincentral',
  storage: localStorage
})

export const store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    isLogin : true,
    isLoading : false,
    sessionData : Object,
    token : "",
    validuser: "",
    validpassword:"",
    brandsbytype: [],
    attributesbytype: [],
    useralias: "",
    machineType: undefined,
    userUuid: undefined,
    userId: undefined,
    rentUuid: undefined,
    ticketUuid: undefined,
    rentListSearch: '',
    paymentSearch: '',
    userListSearch: '',
    machineListSearch: '',
    machineDetailListSearch: '',
    ticketListSearch: '',
    machinesRentsSearch: ''
  },
  getters: {
    username (state) {
      return state.sessionData.username;
    },
    lastname (state) {
      return state.sessionData.lastname;
    },
    userId (state) {
      return state.sessionData.userid;
    },
    email (state) {
      return state.sessionData.email;
    },
    uuid (state) {
      return state.sessionData.uuid;
    },
    status (state) {
      return state.sessionData.status;
    },
    role (state) {
      return state.sessionData.role;
    },
    validuser (state) {
      return state.validuser;
    },
    validpassword (state) {
      return state.validpassword;
    },
    brandsbytype (state) {
      return state.brandsbytype;
    },
    attributesbytype (state) {
      return state.attributesbytype;
    },
    useralias (state) {
      return state.useralias;
    },
  },
  mutations:{
    reset (state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    isLogin (state, value) {
      state.isLogin = value
    },
    isLoading (state, value) {
      state.isLoading = value
    },
    setToken (state, token) {
      state.token = token;
      let base64url = token.split('.')[1]
      let base64 = base64url.replace('-', '+').replace('_', '/')
      let claims = JSON.parse(window.atob(base64))
      let sessionData = claims.sub;
      state.sessionData = sessionData;
    },
    setValiduser (state, value) {
      state.validuser = value
    },
    setValidpassword (state, value) {
      state.validpassword = value
    },
    setUseralias (state, value) {
      state.useralias = value
    },
    setMachineType (state, id) {
      state.machineType = id
    },
    setUserUuid (state, userUuid) {
      state.userUuid = userUuid
    },
    setUserId (state, userId) {
      state.userId = userId
    },
    setRentUuid (state, rentUuid) {
      state.rentUuid = rentUuid
    },
    setTicketUuid (state, ticketUuid) {
      state.ticketUuid = ticketUuid
    },
    setRentListSearch (state, search) {
      state.rentListSearch = search
    },
    setPaymentSearch (state, search) {
      state.paymentSearch = search
    },
    setUserListSearch (state, search) {
      state.userListSearch = search
    },
    setMachineListSearch (state, search) {
      state.machineListSearch = search
    },
    setMachineDetailListSearch (state, search) {
      state.machineDetailListSearch = search
    },
    setTicketListSearch (state, search) {
      state.ticketListSearch = search
    },
    setMachinesRentsSearch (state, search) {
      state.machinesRentsSearch = search
    },
    setSearchsByDefault(state) {
      state.rentListSearch = ''
      state.paymentSearch = ''
      state.userListSearch = ''
      state.machineListSearch = ''
      state.machineDetailListSearch = ''
      state.ticketListSearch = ''
      state.machinesRentsSearch = ''
    }
  },
  actions,
  modules: {
    navigation,
    swatch
  }
});

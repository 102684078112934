<template>
  <div>
    <section-definition :title="page.title"></section-definition>

    <v-container grid-list-md text-xs-center>
      <v-layout row wrap >
        <v-flex xs12 sm7>
          <v-card>
            <v-card-text>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="macdata.machinename"
                  :rules="nameRules"
                  :counter="50"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-flex>
              <v-select
                v-model="selectedmachinetype"
                type="text"
                :items="machinetypes"
                item-text="typename"
                item-value="typeid"
                label="Tipo"
                v-on:change="changeType"
              ></v-select>
              <v-select
                v-model.number="macdata.machinebrandid"
                type="number"
                :items="brands"
                item-text="brandname"
                item-value="brandid"
                label="Marca"
                v-on:change="changeBrand"
              ></v-select>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="macdata.machinedescription"
                  :rules="nameRules"
                  :counter="100"
                  label="Descripción"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  v-model="macdata.machinemodel"
                  :rules="nameRules"
                  :counter="30"
                  label="Modelo"
                  required
                ></v-text-field>
              </v-flex>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-title></v-card-title>
          </v-card>
        </v-flex>
        <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
          <v-btn color="act" round  dark bold outline  class="bold btnfixed" @click="createMachine()">
            Crear Máquina
          </v-btn>
        </v-flex>
      <v-flex xs12 md12>
        <v-divider inset xs12 md12></v-divider>
      </v-flex>

      </v-layout>
      <v-layout rrow wrap>
        <v-flex xs12 sm6>
          <v-card>
            <v-card-text>
               <v-select
                v-model="selectedmachinetemplate"
                :items="templates"
                item-text="namemodel"
                item-value="machineuuid"
                label="Plantilla de máquina"
              ></v-select>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm2 xs6 class="text-sm-right text-xs-right"></v-flex>
          <v-flex sm6 md6 xs6 class="text-sm-center text-xs-center">
          <v-btn color="act" round  dark bold outline  class="bold btnfixed" @click="createMachineFromTemplate()">
            Crear Máquina de Plantilla
          </v-btn>
        </v-flex>
      </v-layout>
      <v-snackbar
        v-model="snackresult"
        :color="snackcolor"
        :timeout="6000"
        top="true"
      >
        {{ snacktext }}
        <v-btn dark flat @click="snackresult = false">Cerrar</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { EventBus } from "../../eventBus";

export default {
  name: "MachineCreate",
  props: ["id"],

  data() {
    return {
      uuid: "",
      macdata: {},
      isLoading2: false,
      hasTransport: false,
      machinedescription: "",
      machinemodel: "",
      selectedBrand: null,
      selectedmachinetype: null,
      machineid: 0,
      machineuuid: "",
      selectedmachinetemplate: null,
      date: null,
      brands: [],
      machinetypes: [],
      templates: [],
      attributes: null,
      userData: {},
      snacktext: "",
      snackcolor: "",
      snackresult: "",
      page: {
        title: "Crear Máquina",
        headline: "Lista de alquileres",
        description: "Alquileres no cancelados"
      }
    };
  },
  mounted: function() {
    //this.getop(this.$route.query.id);
    const _this = this;
    const url4 =
      process.env.VUE_APP_BASE_URI + "/getuserbyuuid/" + this.$store.getters.uuid; //+ _this.macdata.machinetypeid;

    _this.$http
      .get(url4) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
      .then(function(response) {
        console.log("" + response);
        _this.userData = response.data.userdata;

        const url3 =
          process.env.VUE_APP_BASE_URI +
          "/getmachinetypesbycompany/" +
          _this.userData.idcompany; //+ _this.macdata.machinetypeid;
        _this.$http
          .get(url3) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
          .then(function(response) {
            console.log("" + response);
            _this.machinetypes = response.data.types;
          })
          .catch(function(error) {
            console.log(error);
            // this.isLoading2 = false;
          });
      })
      .catch(function(error) {
        console.log(error);
        // this.isLoading2 = false;
      });
      this.loadPlantillas();
  },
  components: {},
  computed: {},
  methods: {
    loadBrands: function() {
      const _this = this;
      const url2 =
        process.env.VUE_APP_BASE_URI +
        "/getbrandsbytype/" +
        _this.selectedmachinetype;
      _this.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(function(response) {
          console.log("" + response);
          _this.brands = response.data.brands;
        })
        .catch(function(error) {
          console.log(error);
          // this.isLoading2 = false;
        });
    },
    getUser: function() {
      const _this = this;
      const url2 =
        process.env.VUE_APP_BASE_URI +
        "/getuserbyuuid/" +
        _this.$store.getters.uuid;
      _this.$http
        .get(url2) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(function(response) {
          console.log("" + response);
          _this.userData = response.data.userdata;
        })
        .catch(function(error) {
          console.log(error);
          // this.isLoading2 = false;
        });
    },
    createMachine: function() {
      const _this = this;
      this.isLoading2 = true;
      var dataToSend = {
        id: 0,
        machineid: 0,
        machinename: this.macdata.machinename,
        machinedescription: this.macdata.machinedescription,
        machinetype: this.selectedmachinetype,
        owneruuid: this.$store.getters.uuid,
        machinebrand: this.macdata.machinebrandid,
        machinestatus: 1,
        machinestatusid: 1,
        machineplace: "",
        machinerate: 0.0,
        machineprice: 0,
        machinepricehour: 0,
        machinemodel: this.macdata.machinemodel,
        machinepower: 0,
        machinediscount1: 0,
        machinediscount2: 0,
        machinediscount3: 0,
        machinepriceexcess: 0,
        autoaproval: 0,
        professionaluse: 0
      };

      const url = process.env.VUE_APP_BASE_URI + "/createmachine";
      _this.$http
        .post(url, dataToSend) //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        .then(function(response) {
          console.log(response);
          _this.isLoading2 = false;

          _this.snacktext = "El equipo se ha creado correctamente";
          _this.snackcolor = "success";
          _this.snackresult = true;
          _this.machineuuid =  response.data.machineuuid;
          _this.machineid = response.data.machineid;
          _this.loadAttributes(_this.selectedmachinetype);
          var machineuuid = response.data.machineuuid;
//          _this.$router.push({
//            path: "/pages/machinedetailedittabbbed/:id",
//            query: { id: machineuuid }
//          });
        })
        .catch(function(error) {
          console.log(error);
          _this.snacktext = "Error al crear el equipo ";
          _this.snackcolor = "error";
          _this.snackresult = true;
        });
        
    },
    changeBrand(b) {
      let obj = this.brands.find(obj => obj.brandid == b);
      this.macdata.machinebrandname = obj.brandname;
      // alert(this.macdata.machinebrand)
    },
    changeType(b) {
      this.loadBrands();
      // alert(this.macdata.machinebrand)
    },

   loadAttributes( idcategory) {
      const self = this;
      let url = process.env.VUE_APP_BASE_URI + "/getattributesbymachinetype/" + idcategory;
      self.$http.get(url).then(
        function(success) {
          self.attributeslist= success.data.attributes;
          var atts = {};
          atts.machineid = self.machineid;
          atts.machineuuid = self.machineuuid;
          atts.attributelist = self.attributeslist;;
          let url2 = process.env.VUE_APP_BASE_URI + "/setattributestomachine" ;

          self.$http.post(url2, atts).then(
              function(success) {
                console.log("Attributos creados");
                self.$router.push({
                path: "/pages/machinedetailedittabbbed/:id",
                query: { id: self.machineuuid }
          });

              })
          .catch(function(error) {
            console.log(error);
            self.isLoading2 = false;
            self.snacktext = "Error al crear el equipo ";
            self.snackcolor = "error";
            self.snackresult = true;
          });

        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al obtener lista de usuarios";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    loadPlantillas: function() {
      const _this = this;
      const url2 = process.env.VUE_APP_BASE_URI + "/getmachinetemplatesforpremium/" + this.$store.getters.uuid;
      _this.$http.get(url2).then(function(response) { //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        // console.log("" + response);
        _this.templates = response.data.machines;
        for( var i = 0; i < _this.templates.length; i++){
          var xx = _this.templates[i].machinename + " - " +_this.templates[i].machinemodel;
          _this.templates[i].namemodel = xx;
        }
      })
      .catch(function(error) {
        console.log(error);
        // this.isLoading2 = false;
      });
    },    
    createMachineFromTemplate() {
      const _this = this;
      // console.log(this.machineuuid)
      const url2 = process.env.VUE_APP_BASE_URI + "/duplicatemachineforpremium/" + this.selectedmachinetemplate + "/" +this.$store.getters.uuid;
      _this.$http.get(url2).then(
        function(response) { //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
          console.log("" + response);
          _this.snacktext = "El equipo se ha creado correctamente";
          _this.snackcolor = "success";
          _this.snackresult = true;
          _this.machineuuid =  response.data.machine.machineuuid;
          _this.machineid = response.data.machine.machineid;
    //      _this.loadAttributes(_this.machine.selectedmachinetype);
          var machineuuid = response.data.machine.machineuuid;
          _this.$router.push({
            path: "/pages/machinedetailedittabbbed/:id",
            query: { id: machineuuid }
        })
      })
      .catch(function(error) {
        console.log(error);
        _this.snacktext = "Error al crear el equipo ";
        _this.snackcolor = "error";
        _this.snackresult = true;
      });
    }


  }
};
</script>
<style lang="stylus">
#example-custom-transition {
  .fade {
    &-enter-active, &-leave-active, &-leave-to {
      transition: 0.3s ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }
}

.custom-checkbox {
  height: 20px;
  font-size: 12;
  margin-left: 5px;
}

.custom-machineatt {
  height: 60px;
  font-size: 12;
  margin-left: 15px;
}

.v-label {
  font-size: 14px;
  font-color: black;
}
</style>

<template>
  <div>
    <section-definition
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    ></section-definition>

    <v-card class="ml-3 mr-3 mb-5">
      <v-card-title>
        <download-excel :data="demosList" :fields="json_fields"
            name="demos.xls"
            worksheet="Demos">
            <v-btn round color="primary" dark bold outline >Descargar Excel</v-btn>
        </download-excel>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar.."
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="demosList"
        :search="search"
        :loading="isLoading"
        rows-per-page-text="Filas por página"
        :rows-per-page-items="[15, 30, 50, { text: 'todas', value: -1 }]"
        class="elevation-1"
      >
        <v-progress-linear
          slot="progress"
          color="blue"
          indeterminate
        ></v-progress-linear>

        <template slot="items" slot-scope="props">
          <td>{{ props.item.iddemo }}</td>
          <td>{{ props.item.username }}</td>
          <td>{{ props.item.serialnumber }}</td>
          <td>{{ props.item.demofrom }}</td>
          <td>{{ props.item.demoto }}</td>
          <td>{{ props.item.hours }}</td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.surname }}</td>
          <td>{{ props.item.phonenumber }}</td>
          <td>{{ props.item.profilenumber }}</td>
          <td>{{ props.item.place }}</td>
          <td>{{ props.item.purchaseinterest }}</td>
          <td>{{ props.item.worktype }}</td>
          <td>{{ props.item.signedContractText }}
            <v-icon
              v-if="props.item.existcontract"
              class="ml-2"
              @click="downloadSignedContract(props.item.iddemo, props.item.demouuid)"
            >
              mdi-file-download
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
var FileSaver = require("file-saver");

export default {
  name: "demosList",
  data() {
    return {
      page: {
        title: "Demos",
        headline: "Listado de demos",
        description: ""
      },
      breadcrumbs: [
        {
          text: "Listado de demos",
          disabled: false
        }
      ],
      headers: [
        { text: "Id demo", value: "iddemo" },
        { text: "Nombre comercial", value: "username" },
        { text: "N. Serie", value: "serialnumber" },
        { text: "Fecha inicio", value: "demofrom" },
        { text: "Fecha fin ", value: "demoto" },
        { text: "Horas tractor", value: "hours" },
        { text: "Nombre", value: "name" },
        { text: "Apellidos", value: "surname" },
        { text: "Teléfono", value: "phonenumber" },
        { text: "Nº Perfil", value: "profilenumber" },
        { text: "Lugar", value: "place" },
        { text: "Interés de compra", value: "purchaseinterest" },
        { text: "Tipo de trabajo", value: "worktype" },
        { text: "Contrato firmado", value: "signedContractText" }
      ],
      isLoading: false,
      snacktext: "",
      snackcolor: "",
      snackresult: false,
      demosList: [],
      search: "",
      json_fields: {
        "Id demo": "iddemo",
        'Nombre comercial': "username",
        "Nº de serie": "serialnumber",
        "Fecha inicio": "demofrom",
        "Fechaa fin": "demoto",
        "Horas tractor": "hours",
        Nombre: "name",
        Apellidos: "surname",
        Teléfono: "phonenumber",
        "Nº Perfil": "profilenumber",
        "Lugar ": "machinelocation",
        "Interés de compra": "purchaseinterest",
        "Tipo de trabajo": "worktype",
        "Contrato firmado": "signedContractText"
      }
    };
  },
  mounted() {
    this.getDemosForPremium();
  },
  methods: {
    getDemosForPremium() {
      const $this = this;
      $this.isLoading = true;
      $this.$http
        .get(`${process.env.VUE_APP_BASE_URI}${"/getdemosforpremium"}`)
        .then(
          response => {
            response.data.demos.forEach(function(demo) {
              demo.surname = demo.surname1 + " " + demo.surname2;
              demo.demofrom = $this.moment(demo.demofrom).format("DD-MM-YYYY");
              demo.demoto = $this.moment(demo.demoto).format("DD-MM-YYYY");
              if (demo.signedcontract === 1) {
                demo.signedContractText = "No firmado";
              } else if (demo.signedcontract === 2) {
                demo.signedContractText = "Firmado";
              }
            });
            $this.demosList = response.data.demos;
            $this.isLoading = false;
          },
          () => {
            $this.isLoading = false;
            $this.snacktext = "Error al obtener el listado de demos";
            $this.snackcolor = "error";
            $this.snackresult = true;
          }
        );
    },
    downloadSignedContract(demoId, demoUuid) {
      this.$http
        .get(
          `${
            process.env.VUE_APP_BASE_URI
          }${"/getdemodocument_base64/"}${demoUuid}`
        )
        .then(
          response => {
            const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
              const byteCharacters = atob(b64Data);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
              ) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              const blob = new Blob(byteArrays, { type: contentType });
              return blob;
            };

            const contentType = "data:application/pdf;base64";
            const b64Data = response.data;

            const blob = b64toBlob(b64Data, contentType);

            var file = new Blob([blob], { type: "application/zip" });
            FileSaver.saveAs(file, `demo-${demoId}.pdf`);
          },
          () => {
            this.snacktext = "No se ha podido descargar el documento";
            this.snackcolor = "error";
            this.snackresult = true;
          }
        );
    }
  }
};
</script>

<style scoped>
</style>
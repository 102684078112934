<template>
  <v-container
    id="input-usage"
    grid-list-xl
    fluid
  >

        <section-definition :title="page.title" :breadcrumbs="breadcrumbs"></section-definition>

    <v-layout wrap>


       <v-flex xs12>
            <v-select
                :items="problemslist"
                v-model="selectedtype"
                label="Seleccionar tipo de problema"
                item-text="useralias"
                item-value="iduser"
                ></v-select>
            </v-flex>

        <v-flex
          xs12
          md12
        >
          <v-text-field
            v-model="ticketname"
            :rules="nameRules"
            :counter="100"
            label="Asunto"
            required
          ></v-text-field>
        </v-flex>




        <v-flex
          xs12
          md12
        >
          <v-textarea
            v-model="ticketdescription"
            :rules="nameRules"
            :counter="500"
            label="Descripción"
            required
          ></v-textarea>
        </v-flex> 
<!--
      <v-flex xs12 md4>
        <v-field :label="Imagen"></v-field>
        <img height="200px" :src="endPointDriverLicenseBack" v-if="driverLicenseBackImg" />
        <span class="mdi mdi-file-image icon-size" v-else></span>
        <upload-btn
          @file-update="update"
          @click.native="setCurrentFile(4)"
          title="Elegir archivo"
          noTitleUpdate="true"
        ></upload-btn>
      </v-flex>

-->

 <!--
            <v-flex xs12 sm8 md4>
              <img :src="imageUrl" height="150" v-if="imageUrl">
              <v-text-field
                label="Adjuntar Imagen"
                @click="pickFile"
                v-model="imageName"
                prepend-icon="attach_file"
              ></v-text-field>
              <input
                type="file"
                style="display: none"
                ref="image"
                accept="image/*"
                @change="onFilePicked"
              >

              <v-btn color="primary" @click="upload">Adjuntar</v-btn>
            <upload-firebase></upload-firebase> 
            </v-flex>
            -->

 

   <v-dialog v-model="isSaveTicket" persistent max-width="500px">
      <v-btn slot="activator" color="act" dark>Registrar y enviar a Ploou</v-btn>
      <v-card>
        <v-card-title>
          <span class="headline">Registrar incidencia</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

 
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1"  @click.native="isSaveTicket = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" @click.native="confirmSave">Registrar incidencia</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-flex>

   
    </v-layout>

   <v-snackbar
      v-model="snackresult"
      :color="snackcolor"
      :timeout="6000"
      top=true
    >
      {{ snacktext }}
      <v-btn
        dark
        flat
        @click="snackresult = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
   </v-container>     


</template>

<script>
import { EventBus } from '../../eventBus.js'
import { db } from "../../firebase";
import { firebase2 } from "../../firebase";
import UploadFirebase from '../../components/UploadFirebase.vue'
import moment from "moment"
import upload from "../../components/upload.vue";
import UploadButton from "vuetify-upload-button";

export default {
    name: 'user',
    components: {
        UploadFirebase
    },
    data () {
        return {
            userData: {},
            endPointImages: process.env.VUE_APP_BASE_URI + '/getimagebypathbase64/',
            endPointAvatar: String,
            endPointIdFront: String,
            avatarImg: false,
            idFrontImg: false,
            idBackImg: false,
            driverLicenseFrontImg: false,
            driverLicenseBackImg: false,
            isChangePassword: false,
            isSaveTicket: false,
            labels: { message: this.$t('modalConfirmSaveUserText'), success: this.$t('userSuccessEdit'), error: this.$t('userErrorEdit') ,
                      leftButton: this.$t('passwordExit'), rigthButton: this.$t('modalConfirm') },
            endPoint: process.env.VUE_APP_BASE_URI + '/modifyuser/' + this.$store.getters.uuid,
            imagetype: String,
            problemslist: ["Consulta","Problemas con el alquiler", "Error en la app", "Solicitud de nueva funcionalidad"],
            pagecounts: [],
            selectedtype: "",
            ticketname: null,
            ticketdescription: null,
            snackresult: false,
            snacktext: "",
            snackcolor: "success",
            page: {
            title: "Registrar incidencia",
            headline: "",
            description: `.`
            },
            breadcrumbs: [
            ] 
        }
    },
    mounted () {


    },
    methods: {
      
        editUser () {
            this.isSaveUser = true
        },
 
        downloadImage (imagepath, cont) {

                 const self = this
               let url = process.env.VUE_APP_BASE_URI + '/downloadimagefrompath' 
                var bodyuser ={
                "imagepath": imagepath
                }
                self.$http.post(url, bodyuser,{
                responseType: 'arraybuffer'
                }).then(
                    function(success) {
                        var b64 = new Buffer(success.data, 'binary').toString('base64')
                        var imgsrc = "data:image/jpeg;base64," + b64;
                        self.sepadocs[cont].b64img = imgsrc
                        
                    },
                    function(error) {
                        self.snacktext="Error. La imagen  no se ha podido  recuperar";
                        self.snackcolor="warning";
                        self.snackresult =true;
                    }
                )

 
        },

        confirmSave () {
          const today = moment()
            var userData2={
                id: today.format("YYYYMMDDmmss"),
                tickettype:this.selectedtype,
                ticketname: this.ticketname,
                ticketdescription: this.ticketdescription,
                tickestatus: 1,
                statuslabel: "PENDIENTE",
                statusclass: "state1",
                ticketcreationdate:  today.format('DD/MM/YYYY HH:mm:ss'), 
                creatoruuid: this.$store.getters.uuid,
                creatoralias: this.$store.getters.useralias
            }
                 const self = this
                    db
                    .collection("usertickets")
                    .doc(this.$store.getters.uuid).collection("tickets")
                    .add(userData2)
                    .then(function(docRef) {
                      console.log("Document successfully written!" + docRef.id) ;
                      db.doc("tickets/" + docRef.id).set(userData2)
                        self.snacktext="La incidencia ha sido registrada";
                        self.snackcolor="success";
                        self.snackresult =true;       
                        self.isSaveTicket = false           
                    });        
                

            
        },
    pickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          //console.log("imageUrl");
          this.imageFile = files[0]; // this is an image file that can be sent to server...
          //this.getImages();
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    upload: function() {
              this.$binding(
        "messages",

        db.collection("users/adminuser/userrooms")
      ).then(messages => {
        console.log(messages); // => __ob__: Observer
      });
      console.log("mmmm")
      var storageRef = firebase2.storage().ref();
      var mountainsRef = storageRef.child(`chatimages/${this.imageName}`);
      mountainsRef.put(this.imageFile).then(snapshot => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          this.imageUrl = downloadURL;
          const bucketName = "image-upload-62ed7.appspot.com";
          const filePath = this.imageName;
          db.collection("images").add({
            downloadURL,
            downloadUrl:
              `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/images` +
              "%2F" +
              `${encodeURIComponent(filePath)}?alt=media`,
            timestamp: Date.now()
          });
          this.getImages();
        });
      });
      //this.getImages();
    }

    }
}
</script>

<style scoped>
.mtop31 {
    margin-top: 31px;
}
.mtop40 {
    margin-top: 40px;
}
.mtop-30 {
    margin-top: -30px;
}
.icon-size {
    font-size: 18pt;
}
.float-right {
    float: right
}
</style>

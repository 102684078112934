import AppSidebar from "@/layouts/App/NavDrawer";
import AppToolbar from "@/layouts/App/Toolbar";
import AppFooter from "@/layouts/App/Footer";
// Pages
//import Dashboardv1 from "@/views/Dashboard/Dashboardv1";
import LoginPage from "@/views/Pages/Authentication/LoginPage";
import BlankPage from "@/views/BlankPage";
import PaymentsPage from "@/views/Pages/PaymentsPage";
import TransportsPage from "@/views/Pages/TransportsPage";
import User from "@/views/Pages/User";
import UserViewOnly from "@/views/Pages/UserOnlyView";
import UserProfile from "@/views/Pages/UserProfile";
import Userslist from "@/views/Pages/UserslistPage";
import RentslistPage from "@/views/Pages/ListrentsPage";
import CobrarAdeudo from "@/views/Pages/CobrarAdeudoPage";
import Calendar from "@/views/Pages/Calendar";
import MachinesList from "@/views/Pages/MachinesList";
import MachineDetailPage from "@/views/Pages/MachineDetailPage";
import DemosList from "@/views/Pages/DemosList";
import MachineDetailEditPage from "@/views/Pages/MachineDetailEditPage";
import MachineDetailEditTabbedPage from "@/views/Pages/MachineDetailEditTabbedPage";
import NewCustomer from "@/views/Pages/NewCustomerPage";
import CreateAutoRent from "@/views/Pages/CreateAutoRentPage";
import CreateAutoRent2 from "@/views/Pages/CreateAutoRent2";
import TransportDetailPage from "@/views/Pages/TransportDetailPage";
import PremiumListPage from "@/views/Pages/PremiumListPage";
import PremiumDetailPage from "@/views/Pages/PremiumDetailPage";
import RentDetailPage from "@/views/Pages/RentDetailPage";
import DashboardPage from "@/views/Pages/Dashboard2Page";
import CreateMachinePage from "@/views/Pages/CreateMachinePage";
import ListChatsPage from "@/views/Pages/ListChatsPage";
import Chat from "@/views/Pages/Chat";
import CreateTicketPage from "@/views/Pages/CreateTicketPage";
import TicketsListPage from "@/views/Pages/TicketsListPage";
import UploadFirestoragePage from "@/views/Pages/UploadFirestoragePage";
import TicketManagePage from "@/views/Pages/TicketManagePage";
import MachinesRentsPage from "@/views/Pages/MachinesRentsPage";

export const routes = [
  { path: "/", redirect: "/pages/authentication/login" },
  {
    path: "/blank-page",
    name: "BlankPage",
    components: {
      default: BlankPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: "/dashboard",
    name: "pages/DashboardPage",
    components: {
      default: DashboardPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: "/pages/authentication/login",
    components: { default: LoginPage },
    name: "pages/authentication/LoginPage",
    meta: { layout: "auth" }
  },
  {
    path: "/payments-page",
    name: "pages/PaymentsPage",
    components: {
      default: PaymentsPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: "/transports-page",
    name: "pages/TransportsPage",
    components: {
      default: TransportsPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: "/rents-page",
    name: "pages/RentsPage",
    components: {
      default: RentslistPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: "/dashboard/dashboardv1",
    name: "dashboard/Dashboardv1",
    components: {
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    }
  },
  {
    path: "/pages/profile",
    components: {
      default: UserProfile,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/ProfileView"
  },
  {
    path: "/pages/user",
    components: {
      default: User,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/User"
  },
  {
    path: "/pages/userviewonly/:id",
    components: {
      default: UserViewOnly,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/UserViewOnly"
  },
  {
    path: "/pages/userslist",
    components: {
      default: Userslist,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/Userslist"
  },
  {
    path: "/pages/createautorent",
    components: {
      default: CreateAutoRent,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/CreateAutoRent"
  },
  {
    path: "/pages/createautorent2",
    components: {
      default: CreateAutoRent2,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/CreateAutoRent2"
  },
  {
    path: "/pages/cobraradeudo",
    components: {
      default: CobrarAdeudo,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/CobrarAdeudo"
  },
  {
    path: "/pages/calendar",
    components: {
      default: Calendar,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/Calendar"
  },
  {
    path: "/pages/machineslist",
    components: {
      default: MachinesList,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/MachinesList"
  },
  {
    path: "/pages/machinedetail/:id",
    components: {
      default: MachineDetailPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/MachinesDetailPage"
  },
  {
    path: "/pages/demoslist",
    components: {
      default: DemosList,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/DemosList"
  },
  {
    path: "/pages/transportdetail/:id",
    components: {
      default: TransportDetailPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/TransportDetailPage"
  },
  {
    path: "/pages/machinedetailedit/:id",
    components: {
      default: MachineDetailEditPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/MachinesDetailEditPage"
  },
  {
    path: "/pages/machinedetailedittabbbed/:id",
    components: {
      default: MachineDetailEditTabbedPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/MachineDetailEditTabbedPage"
  },
  {
    path: "/pages/rentdetailpage",
    components: {
      default: RentDetailPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/RentDetailPage"
  },
  {
    path: "/pages/newcustomer",
    components: {
      default: NewCustomer,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/NewCustomerPage"
  },
  {
    path: "/pages/premiumlist",
    components: {
      default: PremiumListPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/PremiumList"
  },
  {
    path: "/pages/premiumdetail",
    components: {
      default: PremiumDetailPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/PremiumDetail"
  },
  {
    path: "/pages/createmachine",
    components: {
      default: CreateMachinePage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    props: true,
    name: "pages/CreateMachinePage"
  },
  {
    path: "/pages/listchats",
    components: {
      default: ListChatsPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/ListChatsPage"
  },
  {
    path: "/pages/chats",
    components: {
      default: Chat,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/Chat"
  },
  {
    path: "/pages/createticket",
    components: {
      default: CreateTicketPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/CreateTicketPage"
  },
  {
    path: "/pages/ticketslist",
    components: {
      default: TicketsListPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/TicketsListPage"
  },
  {
    path: "/pages/uploadfire",
    components: {
      default: UploadFirestoragePage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/UploadFirestoragePage"
  },
  {
    path: "/pages/ticketmanage/",
    components: {
      default: TicketManagePage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/TicketManagePage"
  },
  {
    path: "/pages/machinesrents",
    components: {
      default: MachinesRentsPage,
      sidebar: AppSidebar,
      header: AppToolbar,
      footer: AppFooter
    },
    name: "pages/MachinesRentsPage"
  }
];

<template>
  <div>
    <section-definition
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    ></section-definition>

    <v-container fluid grid-list-xl page>
      <v-layout row wrap>

        <v-flex  xs6 md3>
        <download-excel :data="rents" :fields="json_fields"
            name="alquileres.xls"
            worksheet="Alquileres">
            <v-btn color="act" class="text--white green bold btnfixed" round  dark bold outline  >Descargar Excel</v-btn>
            
          </download-excel>
        </v-flex>
        <v-flex xs6 md3>

        <download-excel :data="rents" :fields="json_fields"
            name="alquileres.csv"
            type="csv"
            worksheet="Alquileres">
            <v-btn color="act" class="text--white green bold btnfixed" round  dark bold outline  >Descargar CSV</v-btn>
            
          </download-excel>
          </v-flex>

        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar.."
              single-line
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="rents"
            :search="search"
            :loading="isLoading"
            class="elevation-1 fixed-header my-fixed-table"
            rows-per-page-text="Filas por página"
            :rows-per-page-items="[15, 30, 50, { text: 'todas', value: -1 }]"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>

            <template slot="items" slot-scope="props">
              <td>{{ props.item.owneralias }}</td>
              <td><v-btn outline class="primary" small :elevation="hover ? 24 : 6"
                @click="if(props.item.rentstatus >= 3 ) gotoUserDetail(props.item)">{{ props.item.useralias }}</v-btn></td>
              <td>{{ props.item.idrent }}</td>
              <td class="text-xs-right" ><v-btn outline class="primary" small 
                :elevation="hover ? 24 : 6" @click="gotoMachineDetail(props.item)" >{{ props.item.machinename }}</v-btn></td>
              <td class="text-xs-right">{{ props.item.rentcreationdate | utcToFormatDate }}</td>
              <td class="text-xs-right">{{ props.item.machineserialnumber }}</td>
              <td class="text-xs-right">{{ props.item.rentprice }}</td>
              <td class="text-xs-right">{{ props.item.rentfinalprice }}</td>
              <td class="text-xs-right">{{ props.item.rentpriceexcess }}</td>
              <td :class="props.item.stateclass">
                <template v-slot:props.item.rentstatuslabel="{ item }">
                  <v-chip :color="getColor(props.item.rentstatus)" dark>{{
                    props.item.rentstatuslabel
                  }}</v-chip>
                </template>
              </td>
              <td :class="props.item.stateclass">
                {{ props.item.rentphaselabel }}
              </td>
              <td class="text-xs-right">{{ props.item.rentplace }}</td>
              <td class="text-xs-right">
                {{ props.item.transportNeededlabel }}
              </td>
              <td class="text-xs-right">{{ props.item.transportPrice }}</td>
              <td class="text-xs-right">{{ props.item.rentfrom }}</td>
              <td class="text-xs-center" v-if="props.item.rentextensions.length > 0">
                <v-btn outline class="primary" small 
                :elevation="hover ? 24 : 6" @click="goToExtensionDetail(props.item)">{{ props.item.rentenddatewithextensions }}</v-btn>
              </td>
              <td class="text-xs-center" v-else>{{ props.item.rentenddatewithextensions }}</td>
              <td class="text-xs-right">{{ props.item.rentextensions | extensions }}</td>
              <td class="text-xs-center">{{ props.item.renttotaldays }}</td>
              <td class="justify-center layout px-0">
           <!--     <v-icon
                  small
                  color="green"
                  class="mr-2"
                  @click="viewChat(props.item)"
                  title="Ver chat"
                >
                  chat
                </v-icon> -->
<!--
                <v-icon
                  small
                  color="red"
                  class="mr-2"
                  @click="cancelRent(props.item)"
                  title="Eliminar alquiler"
                >
                  delete
                </v-icon>
-->
                <v-icon
                  small
                  color="blue"
                  class="mr-2"
                  @click="viewPayments(props.item)"
                  title="Calendario de pagos"
                >
                  fas fa-credit-card
                </v-icon>
                <v-icon
                  small
                  color="blue"
                  class="mr-2"
                  @click="gotoDetail(props.item)"
                  title="Detalle"
                >
                  fas fa-info-circle
                </v-icon>
              </td>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              La búsqueda de "{{ search }}" no ha encontrado resultados.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-layout>

      <v-dialog v-model="isShowChatDialog" max-width="500px">
        <v-card>
          <v-toolbar color="pink" dark>
            <v-toolbar-side-icon></v-toolbar-side-icon>

            <v-toolbar-title
              >Chat de alquiler {{ currentrentid }}</v-toolbar-title
            >

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-list two-line>
            <template v-for="(item, key) in currentChat">
              <v-list-tile
                :key="item.senderName"
                avatar
                ripple
                @click="toggle(index)"
              >
                <v-list-tile-content>
                  <v-list-tile-title
                    >{{ item.senderName }} -
                    {{ item.formattedTime }}</v-list-tile-title
                  >
                  <v-list-tile-sub-title class="text--primary">{{
                    item.headline
                  }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>{{
                    item.messageText
                  }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </template>
          </v-list>
        </v-card>

        <v-card>
          <!--
          <v-card-title>
            <span class="headline">Chat de alquiler {{currentRent}}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                    <ul>
                <li v-for="(value, key2) in currentChat">{{ value.senderName }}: {{ value.messageText }} </li>
                </ul>

              </v-layout>
            </v-container>
          </v-card-text>
        -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" @click.native="isShowChatDialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isCancelRentDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Eliminar alquiler</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap> </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              @click.native="isCancelRentDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" @click.native="confirmCancelrent"
              >Eliminar alquiler</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Calendario de pagos -->
      <v-dialog v-model="isShowPaymentsDialog" max-width="500px">
        <v-card class="elevation-4 border-radius6">
          <v-toolbar card dense color="transparent">
            <v-toolbar-title><h4>Calendario de pagos</h4></v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
              <v-icon>more_vert</v-icon>
            </v-btn> -->
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table
              style="max-height: 300px"
              :headers="headerspayments"
              :items="payments"
              hide-actions
              class="scroll-y elevation-0 table-striped border-radius6 plain-order-table"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.idpayment }}</td>
                <td>{{ props.item.paymentamount }}</td>
                <td>{{ props.item.paymentdate }}</td>
                <td>{{ props.item.paymentstatuslabel }}</td>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Extensiones -->
      <v-dialog v-model="isShowExtensionDetail" max-width="600px">
        <v-card class="elevation-4 border-radius6">
          <v-toolbar card dense color="transparent">
            <v-toolbar-title><h4>Extensiones</h4></v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
              <v-icon>more_vert</v-icon>
            </v-btn> -->
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table
              style="max-height: 300px"
              :headers="extensionHeader"
              :items="extensionDetail"
              hide-actions
              class="scroll-y elevation-0 table-striped border-radius6 plain-order-table"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.idrentextension }}</td>
                <td>{{ props.item.rentextensionprice }}</td>
                <td>{{ props.item.days }}</td>
                <td>{{ props.item.datefrom | splitDate }}</td>
                <td>{{ props.item.dateto | splitDate }}</td>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackresult"
        :color="snackcolor"
        :timeout="6000"
        top="true"
      >
        {{ snacktext }}
        <v-btn dark flat @click="snackresult = false">
          Cerrar
        </v-btn>
      </v-snackbar>
    </v-container>

    <!--   
  <v-flex xs12 sm6 md6 lg4 v-for="(item, idx) in chats" :key="idx">
    <ul>
  <li v-for="(value, key2) in item">{{ value.senderName }}: {{ value.messageText }} </li>
</ul>
    {{idx}}
  </v-flex>
-->
  </div>
</template>

<script>
/*
amountpayed: 900
idpaymentstatus: 2
idrent: 488
machinename: "Ter"
owneralias: "Marta12"
paymentamount: 900
paymentdata: "959999/018687897529827156149505050702"
paymentdate: "Mon, 05 Aug 2019 00:00:00 GMT"
paymenttype: 1
rentfrom: "Tue, 20 Aug 2019 00:00:00 GMT"
rentto: "Wed, 28 Aug 2019 00:00:00 GMT"
transactiondate: "Fri, 19 Jul 2019 11:48:26 GMT"
useralias: "Marta12"
*/
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import PlainTableOrder from "@/components/Widgets/list/PlainTableOrder";
import { db } from "../../firebase";
import { debug } from "util";
import moment from "moment";

export default {
  mixins: [validationMixin],
  data() {
    return {
      search: this.$store.state.rentListSearch,
      headers: [
        { text: "Propietario", value: "owneralias", width:"5%" },
        { text: "Arrendatario", value: "useralias", width:"5%" },
        { text: "ID alquiler", value: "idrent", width:"5%" },
        { text: "Equipo", value: "machinename", width:"5%" },
        { text: "Fecha creación ", value: "rentcreationdate" , width:"5%" },
        { text: "Nº Bastidor", value: "machineserialnumber", width:"5%" },
        { text: "Precio (€)", value: "rentprice" , width:"5%"},
        { text: "Precio final(€)", value: "rentfinalprice", width:"5%" },
        { text: "Fianza (€)", value: "rentpriceexcess" , width:"5%" },
        { text: "Estado", value: "rentstatuslabel", width:"5%" },
        { text: "Fase", value: "rentphase" , width:"5%"},
        { text: "Lugar de entrega", value: "rentplace", width:"15%" },
        { text: "Transporte", value: "transportNeeded" , width:"3%" },
        { text: "Precio transporte (€)", value: "transportPrice", width:"3%" },
        { text: "Fecha inicio", value: "rentfromcalc", width:"5%" },
        { text: "Fecha fin", value: "rentenddatewithextensions", width: "5%" },
        { text: "Extensiones", value: 'rentextensions', width: '5%', align: 'center' },
        { text: "Días de alquiler", value: 'renttotaldays', width: '3%', align: 'center'},
        { text: "Acciones", value: '' , width:"5%"}
      ],
      headerspayments: [
        { text: "ID", value: "idpayment" },
        { text: "Importe (€)", value: "paymentamount" },
        { text: "Fecha", value: "paymentdate" },
        { text: "Estado", value: "paymentstatus" }
      ],
      extensionHeader: [
        { text: "ID", value: "idrentextension" },
        { text: "Importe (€)", value: "rentextensionprice" },
        { text: "Días", value: "days" },
        { text: "Desde", value: "datefrom" },
        { text: "Hasta", value: "dateto" }
      ],
      rents: [],
      payments: [],
      currentrentid: null,
      currentchat: null,
      chats: [],
      chatmessages: [],
      isLoading: false,
      isShowChatDialog: false,
      isCancelRentDialog: false,
      isShowPaymentsDialog: false,
      snackbar: false,
      snackresult: false,
      snacktext: "",
      snackcolor: "success",
      page: {
        title: "Alquileres",
        headline: "Lista de alquileres",
        description: "Alquileres no cancelados"
      },
      breadcrumbs: [
        {
          text: "Admin",
          disabled: false
        },
        {
          text: "Alquileres",
          disabled: false
        }
      ],
      extensionDetail: [],
      isShowExtensionDetail: false,
      json_fields: {
        Propietario: "owneralias",
        Arrendatario: "useralias",
        Equipo: "machinename",
        "Fecha creación": {
          field: 'rentcreationdate',
          callback: value => {
            var stillUtc = moment.utc(value).toDate()
            return moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss')
          }
        },
        "Número de bastidor": 'machineserialnumber',
        "Lugar del alquiler": "rentplace",
        "ID alquiler": "idrent",
        "Precio(€)": {
          field: 'rentprice',
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0){
              var newvalue = valstr.replace(".", ",")
              return newvalue;
            }
            return value;
          }
        },
        "Precio final (€)": {
          field: 'rentfinalprice',
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0){
              var newvalue = valstr.replace(".", ",")
              return newvalue;
            }
            return value;
          }
        },
        "Fianza (€)": {
          field: 'rentpriceexcess',
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0){
              var newvalue = valstr.replace(".", ",")
              return newvalue;
            }
            return value;
          }
        },
        "Estado": "rentstatuslabel",
        "Fase": "rentphase",
        "Transporte": {
          field: 'transportNeeded',
          callback: value => {
            if (value == 1) return "SI";
            return "NO";
          }
        },
        "Precio transporte (€)": {
          field: 'transportPrice',
          callback: value => {
            var valstr = value.toString();
            if (valstr.indexOf(".") > 0){
              var newvalue = valstr.replace(".", ",")
              return newvalue;
            }
            return value;
          }
        },
        "Fecha inicio": "rentfrom",
        "Fecha fin": {
          field: 'rentenddatewithextensions',
          callback: value => {
            var stillUtc = moment.utc(value).toDate()
            return moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss')
          }
        },
        "Extensiones":  {
          field: 'rentextensions',
          callback: value => {
            if (value.length > 0) {
              return 'SI'
            } 
            return 'NO'
          }
        },
        "Días de alquiler": 'renttotaldays'
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  watch: {
    search() {
      this.$store.commit('setRentListSearch', this.search);
    }
  },
  firebase: {
//    chats: db.ref("/chat_rents/")
  },
  components: {
    PlainTableOrder
  },
  mounted: function() {
    if (this.$store.getters.uuid == null) {
      this.$router.push({name: "pages/authentication/LoginPage"});
    }
    this.loadRents();
    console.log("mounted: got here");
  },
  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI + "/getrentsforuserasowner/" + uuid;
      self.$http.get(url).then(
        function(success) {
          var transporterstemp = success.data.transporters;
          var id1 = transporterstemp[0].iduser;
          self.isLoading = false;
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos del calendario de pagos ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },

    getColor(status) {
      if (status > 7) return "red";
      else if (status > 2) return "orange";
      else return "green";
    },

    viewChat(item) {
      const self = this;
      this.currentrentid = item.idrent;
      db.ref("/chat_rents/" + item.rentuuid)
        .once("value")
        .then(function(snapshot) {
          self.currentChat = snapshot.val();
        });
      this.isShowChatDialog = true;
    },

    cancelRent(item) {
      this.currentrentid = item.rentuuid;

      this.isCancelRentDialog = true;
    },

    confirmCancelrent() {
      debug;
      if (true) {
        const self = this;
        let url =
          process.env.VUE_APP_BASE_URI + "/cancelrent/" + this.currentrentid;

        self.$http.get(url, self.userData).then(
          function(success) {
            self.isCancelRentDialog = false;
            self.snacktext = "El alquiler ha sido eliminado";
            self.snackcolor = "success";
            self.snackresult = true;
          },
          function(error) {
            self.isCancelRentDialog = false;

            self.snacktext = "Error. No se ha podido elminar el alquiler";
            self.snackcolor = "red";
            self.snackresult = true;
          }
        );
      }
    },

    viewPayments(item) {
      let uuid = item.rentuuid;
      const self = this;
      const url2 = process.env.VUE_APP_BASE_URI + "/getpaymentcalendar/" + uuid;
      self.$http
        .get(url2)
        .then(
          function(response) {
            console.log("" + response);
            //  this.isLoading2 = false;
            self.payments = response.data.paymentlist;
            for (var i = 0; i < self.payments.length; i++) {
              if (self.payments[i].idpaymentstatus == 1)
                self.payments[i].paymentstatuslabel = "PENDIENTE";
              else if (self.payments[i].idpaymentstatus == 2)
                self.payments[i].paymentstatuslabel = "PAGADO";
              else if (self.payments[i].idpaymentstatus == 3)
                self.payments[i].paymentstatuslabel = "RECHAZADO";
              else if (self.payments[i].idpaymentstatus == 5)
                self.payments[i].paymentstatuslabel = "PARCIAL";
              else self.payments[i].paymentstatuslabel = "--";
            }
            self.isShowPaymentsDialog = true;
          }.bind(this)
        )
        .catch(
          function(error) {
            this.snacktext = "Error al cargar datos del calendario de pagos ";
            this.snackcolor = "error";
            this.snackresult = true;
          }.bind(this)
        );
    },


    gotoUserDetail( currentItem) {
      this.userData = currentItem;
      this.$router.push({
        name: "pages/UserViewOnly",
        params: {
          currentuuid: currentItem.useruuid,
          currentuserid: currentItem.iduser
          }
      //   query: { id: useruuid }
      });
    },
    gotoMachineDetail: function(item){
      this.$router.push({ path: '/pages/machinedetail/:id', query: { id: item.machineuuid }})
      // this.$router.push({ path: '/machinedetail'})
    },
    gotoDetail: function(item) {
      this.$store.commit('setRentUuid', item.rentuuid)
      this.$router.push({ name: "pages/RentDetailPage" })
    },
    goToExtensionDetail(item) {
      this.extensionDetail = item.rentextensions
      this.isShowExtensionDetail = true
    },
    loadRents() {
      const self = this;
      let uuid = this.$store.getters.uuid;
      let url = process.env.VUE_APP_BASE_URI + "/getrentsforuserasowner/" + uuid;
      self.$http.get(url).then(
        function(success) {
          var paymentstemp = success.data.rents;
          var rentsfinal = [];
          self._.forEach(paymentstemp, function(expense, index) {
            success.data.rents[index].rentenddatewithextensions = expense.rentenddatewithextensions.split('-')[2] + '-' + 
                                                                  expense.rentenddatewithextensions.split('-')[1] + '-' + 
                                                                  expense.rentenddatewithextensions.split('-')[0]
            if (expense.rentstatus < 10) {
              paymentstemp[index].rentfromcalc = self.moment(expense.rentfrom);
              paymentstemp[index].renttocalc = self.moment(expense.rentto);

              paymentstemp[index].rentfrom = self
                .moment(expense.rentfrom)
                .format("DD-MM-YYYY")
                .split("T")[0];
              paymentstemp[index].rentto = self
                .moment(expense.rentto)
                .format("DD-MM-YYYY")
                .split("T")[0];

              if (expense.rentstatus === 1) {
                paymentstemp[index].rentstatuslabel = "Solicitado";
              }
              if (expense.rentstatus === 2) {
                paymentstemp[index].rentstatuslabel = "Aprobado";
              }
              if (expense.rentstatus >= 3 && expense.rentstatus < 6) {
                paymentstemp[index].rentstatuslabel = "Firmado";
              }
              if (expense.rentstatus === 6) {
                paymentstemp[index].rentstatuslabel = "Entrega";
              }
              if (expense.rentstatus === 7) {
                paymentstemp[index].rentstatuslabel = "Recogida";
              }
              if (expense.rentstatus === 8) {
                paymentstemp[index].rentstatuslabel = "Liquidación";
              }
              if (expense.rentstatus === 9) {
                paymentstemp[index].rentstatuslabel = "Finalizado";
              }

              if (expense.transportNeeded === 1) {
                paymentstemp[index].transportNeededlabel = "SI";
              } else {
                paymentstemp[index].transportNeededlabel = "NO";
              }

              if (expense.rentstatus >= 6 && expense.rentstatus < 9) {
                if (expense.rentphase === 1) {
                  paymentstemp[index].rentphaselabel = "Salida";
                }
                if (expense.rentphase === 2) {
                  paymentstemp[index].rentphaselabel = "Entrega";
                }
                if (expense.rentphase === 3) {
                  paymentstemp[index].rentphaselabel = "Recogida";
                }
                if (expense.rentphase === 4) {
                  paymentstemp[index].rentphaselabel = "Devolución";
                }
              } else {
                paymentstemp[index].rentphaselabel = " ";
              }

              if (expense.idpaymentstatus === 1) {
                paymentstemp[index].stateclass = "state1";
                paymentstemp[index].idpaymentstatus = "PENDIENTE";
              }
              if (expense.idpaymentstatus === 2) {
                paymentstemp[index].stateclass = "state2";
                paymentstemp[index].idpaymentstatus = "PAGADO";
              }
              if (expense.idpaymentstatus === 3) {
                paymentstemp[index].stateclass = "state3";
                paymentstemp[index].idpaymentstatus = "RECHAZADO";
              }
              rentsfinal.push(paymentstemp[index]);
            }
          });
          self.rents = rentsfinal;
        },
        function(error) {
          self.snacktext = "Error al cargar datos de alquileres  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
      /*     const _this=this;
      
        const { data } =  PaymentsRepository.listPayments().then(
                function(success) {
                    _this.$store.commit('', false)
                    //this.$router.push({path: '/myMachines'})
                    //his.posts = data;
                    _this.$router.push({
                        name: "dashboard/Dashboardv1"
                    });

                }).catch(function(error) {
                    _this.loginError = true
                }
            )
*/
    }
  }
};
</script>

<style lang="stylus" scoped>
.section-def
  margin-bottom: 50px
.toobar-extension
  margin-left: 5px !important
.breadcrumb-section
  margin-left: -25px !important
.action-btn
  margin-left: 5px !important

.state1{
  background-color :salmon;
  text-color : #ffffff;

}

.state2{
  background-color :green;
  text-color : #ffffff;

}

.state3{
  background-color :red;
  text-color : #ffffff;

}

  .my-fixed-table{
    table-layout : fixed;
  }
</style>
